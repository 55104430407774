import React, {useEffect} from 'react';
import {RxCross2} from 'react-icons/rx';
import {track} from '../../../../api/analytics';
import AIFileUploader from '../../../../components/inputs/ai-file-uploader';
import {TemplateSelectorWizard} from '../../inputs/template-selector';

const WIZARD_STEPS = {
  TEMPLATE: 1,
  CONTEXT: 2,
};

const WizardHeader = ({currentStep, onClose}) => {
  const steps = [
    {number: 1, label: 'Select Template'},
    {number: 2, label: 'Add Context'},
  ];

  return (
    <div className="ai-assistant-wizard-header">
      <div className="ai-wizard-progress">
        {steps.map(({number, label}) => (
          <div
            key={number}
            className={`wizard-step ${currentStep === number ? 'active' : ''} 
                       ${currentStep > number ? 'completed' : ''}`}>
            <div className="wizard-step-number">{number}</div>
            <div className="wizard-step-label">{label}</div>
          </div>
        ))}
      </div>
      <RxCross2 className="clickable" onClick={onClose} />
    </div>
  );
};

const WizardNavigation = ({currentStep, onBack, onNext, isNextDisabled}) => {
  const isLastStep = currentStep === WIZARD_STEPS.CONTEXT;

  return (
    <div className="ai-wizard-step-footer">
      <button
        className="ai-assistant-button ai-assistant-button-secondary"
        onClick={onBack}>
        {!isLastStep ? 'Chat Instead' : 'Back'}
      </button>

      <button
        className="ai-assistant-button ai-assistant-button-primary"
        onClick={onNext}
        disabled={isNextDisabled}>
        {isLastStep ? 'Generate Report' : 'Next Step'}
      </button>
    </div>
  );
};

const TemplateStep = ({threadTitle, selectedTemplate, onTemplateSelect}) => {
  return (
    <div className="ai-wizard-step">
      <div className="ai-wizard-step-header">
        <h3>Close Workspace: {threadTitle}</h3>
        <p>
          Select a template to summarize this workspace's outcomes and learnings
        </p>
      </div>

      <TemplateSelectorWizard
        toComplete={true}
        value={selectedTemplate}
        setValue={onTemplateSelect}
      />
    </div>
  );
};

const ContextStep = ({
  selectedThreads,
  onThreadSelect,
  files,
  content,
  onFilesChange,
  onContentChange,
}) => {
  return (
    <div className="ai-wizard-step">
      <div className="ai-wizard-step-header">
        <h3>Additional Context</h3>
        <p>
          Enhance your workspace report by adding reference materials and extra
          context
        </p>
      </div>
      <div className="ai-wizard-step-content">
        <div className="ai-wizard-section">
          <h4 className="ai-assistant-section-header">Supporting Files</h4>
          <p className="text-secondary text-14 padding-bottom8">
            Add relevant documents, reports, or resources
          </p>
          <AIFileUploader files={files} setFiles={onFilesChange} />
        </div>
        <div className="ai-wizard-section">
          <h4 className="ai-assistant-section-header">Additional Notes</h4>
          <p className="text-secondary text-14 padding-bottom8">
            Share any extra information to consider in the report
          </p>
          <div className="ai-assistant-input-section">
            <textarea
              value={content}
              onChange={e => onContentChange(e.target.value)}
              placeholder="Add any additional context or specific points to highlight..."
              className="w-full min-h-32 p-4 rounded-lg border"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// Main WizardView component with updated completion-focused flow
const WizardView = ({
  config,
  thread_data,
  currentStep,
  onTemplateSelect,
  onThreadSelect,
  onFilesChange,
  onContentChange,
  onStepComplete,
  onBack,
  onClose,
  currentTemplate,
}) => {
  const {files, selectedThreads, wizard_content} = config;
  const {thread} = thread_data || {};

  useEffect(() => {
    track('wizard_launched', {
      to_complete_thread: thread ? true : false,
      workspace_id: thread?.id,
      workspace_name: thread?.title,
      workspace_type: thread?.type,
    });
  }, []);

  // Helper to determine if next step is allowed
  const isNextDisabled = () => {
    switch (currentStep) {
      case WIZARD_STEPS.TEMPLATE:
        return !currentTemplate;
      default:
        return false;
    }
  };

  // Render appropriate step content
  const renderStep = () => {
    switch (currentStep) {
      case WIZARD_STEPS.TEMPLATE:
        return (
          <TemplateStep
            threadTitle={thread?.title}
            selectedTemplate={currentTemplate}
            onTemplateSelect={onTemplateSelect}
          />
        );
      case WIZARD_STEPS.CONTEXT:
        return (
          <ContextStep
            selectedThreads={selectedThreads}
            onThreadSelect={onThreadSelect}
            files={files}
            content={wizard_content}
            onFilesChange={onFilesChange}
            onContentChange={onContentChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="ai-assistant-wizard">
      <WizardHeader currentStep={currentStep} onClose={onClose} />
      <div className="ai-assistant-wizard-content">
        {renderStep()}
        <WizardNavigation
          currentStep={currentStep}
          onBack={onBack}
          onNext={onStepComplete}
          isNextDisabled={isNextDisabled()}
        />
      </div>
    </div>
  );
};

export default WizardView;
