import constants from '../../../components/constants';

const threadContentNotification = props => {
  const {content, profile, thread} = props || {};
  const {first_name, last_name} = profile || {};
  const {title, type: t_type} = thread;
  const {type, content_html} = content;

  const pretty = `${first_name || ''} ${last_name || ''}`;

  const pretty_type = () => {
    switch (type) {
      case 'comment':
        return 'Post';
      case 'milestone':
        return 'Milestone';
      case 'question':
        return 'Question';
      case 'decision_point':
        return 'Decision Point';
      case 'poll':
        return 'Poll';
      case 'meeting':
        return 'Meeting';
      case 'task':
        return 'Task';
      default:
        return 'Post';
    }
  };

  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet">
    <style>
      body { font-family: 'Inter', sans-serif; color: #333; line-height: 1.6; }
      .container { max-width: 600px; margin: 0 auto; padding: 20px; }
      .button { background-color: #4743c5; color: white !important; padding: 12px; text-decoration: none; border-radius: 4px; font-weight: 600; display: inline-block; }
      .footer a { color: #666; text-decoration: none; margin: 0 8px; }
    </style>
  </head>
  <body>
    <div class="container">
      <div style="text-align: center; margin-bottom: 24px;">
        <img src="${
          constants.publicmind_logo
        }" alt="PublicMind Logo" height="48" width="48" />
        <h2 style="font-weight: 600; margin-top: 12px;">PUBLICMIND</h2>
      </div>
      
      ${
        t_type !== 'direct'
          ? `<h3 style="text-align: center; margin-bottom: 24px;">
          New ${pretty_type()} from ${pretty} in ${title}
        </h3>`
          : ''
      }
      
      <div style="background-color: #f4f4f4; padding: 24px; border-radius: 8px; margin-bottom: 24px;">
        ${content_html}
      </div>
      
      <div style="text-align: center; margin-bottom: 24px;">
        <a href="${
          t_type === 'direct'
            ? `${constants.root_url}/threads?dms_active=true&dm_id=${content.thread_id}`
            : `${constants.root_url}/threads/${content.thread_id}`
        }" class="button">View ${t_type === 'direct' ? 'DM' : 'Workspace'}</a>
      </div>
      
      <div style="text-align: center; color: #666; font-size: 14px;">
        <p>Sent from PublicMind</p>
        <div class="footer">
          <a href=${constants.copyright_policy}">Copyright Policy</a>
          <a href="${constants.privacy_policy}">Privacy Policy</a>
          <a href="${constants.terms_of_service}">Terms of Service</a>
        </div>
      </div>
    </div>
  </body>
  </html>
  `;
};

export default threadContentNotification;
