import React, {useMemo, useState} from 'react';
import {RiArrowDownSLine} from 'react-icons/ri';
import {RxCross2} from 'react-icons/rx';
import AIWorkspace from '../../components/ai-workspace';
import {SaveToThreadSelector} from '../../inputs/thread-selector';

const WorkspaceHeader = ({
  title,
  hasContent,
  toComplete,
  onSave,
  isStreaming,
  isSaving,
  onClose,
  setShowThreadSelector,
  isEmpty,
  handleReload,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="ai-assistant-work-area-header">
      <h3>{title}</h3>
      <div className="flex-row align-center">
        {hasContent && !isEmpty ? (
          <div className="ai-assistant-save-dropdown">
            {toComplete ? (
              <button
                className="ai-assistant-button ai-assistant-button-primary"
                onClick={onSave}
                disabled={isSaving || isStreaming}>
                Close and Save
              </button>
            ) : (
              <>
                <button
                  className="ai-assistant-button ai-assistant-button-primary"
                  onClick={() => setShowDropdown(!showDropdown)}
                  disabled={isSaving}>
                  Finish
                  <RiArrowDownSLine />
                </button>
                {showDropdown && (
                  <div className="ai-assistant-dropdown-menu">
                    <button onClick={onSave}>Save to New Workspace</button>
                    <button onClick={setShowThreadSelector}>
                      Save to Existing Workspace
                    </button>
                    <button onClick={handleReload}>Reload and Discard</button>

                    <button onClick={onClose}>Close and Discard</button>
                  </div>
                )}
              </>
            )}
          </div>
        ) : null}
        {isEmpty && <RxCross2 className="clickable" onClick={onClose} />}
      </div>
    </div>
  );
};

const WorkspaceView = ({
  editor,
  handleReload,
  currentConfig,
  isStreaming,
  streamingContent,
  thread_data,
  onClose,
  onSave,
  onSaveToExisting,
  isLoading,
  isSaving,
  activeSection,
  setActiveSection,
}) => {
  const {thread, toComplete} = thread_data || {};
  const [showThreadSelector, setShowThreadSelector] = useState(false);
  const isEmpty = useMemo(() => {
    return (
      editor.document?.length === 1 &&
      editor.document?.[0].content?.length === 0
    );
  }, [editor.document]);
  return (
    <div className={`ai-assistant-work-area `}>
      <WorkspaceHeader
        title={
          thread?.logo || thread?.title
            ? 'New Document based on: ' + thread?.logo + ' ' + thread?.title
            : 'New Document'
        }
        onSave={onSave}
        setShowThreadSelector={setShowThreadSelector}
        hasContent={editor?.document?.length > 0}
        isLoading={isLoading}
        isSaving={isSaving}
        toComplete={toComplete}
        isStreaming={isStreaming}
        onClose={onClose}
        isEmpty={isEmpty}
        handleReload={handleReload}
      />

      <AIWorkspace
        editor={editor}
        currentConfig={currentConfig}
        streamingContent={streamingContent}
        isStreaming={isStreaming}
        isLoading={isLoading}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />

      {showThreadSelector && (
        <SaveToThreadSelector
          onSelect={thread => {
            setShowThreadSelector(false);
            onSaveToExisting(thread.id);
          }}
          onClose={() => setShowThreadSelector(false)}
        />
      )}
    </div>
  );
};

export default WorkspaceView;
