import React, {useEffect, useState} from 'react';
import {track} from '../../api/analytics';
import tags from '../../assets/lists/tags.json';
import {useThreads} from '../../hooks/use-threads';
import constants from '../constants';
import {ArraySearchableDropDown} from '../inputs/drop-down';
import {Textfield} from '../inputs/textfields';
import './styles.css';

export const ThreadFilter = ({setLoading}) => {
  const {
    state: {thread_filter},
    searchThreads,
  } = useThreads();

  const [editFilter, setFilter] = useState(thread_filter);

  const search = async (filter, options) => {
    setLoading(true);
    const {success, error, data} = await searchThreads(filter, options);
    setLoading(false);
    track('search', {
      source: 'threads',
      filter,
      options,
    });
    return {success, error, data};
  };

  useEffect(() => {
    if (thread_filter) {
      setFilter(thread_filter);
    }
  }, [thread_filter]);

  return (
    <div className="filter-container pretty-form-group">
      <h4>Search Criteria</h4>
      <Textfield
        value={editFilter.title}
        type="text"
        onChange={e => setFilter({...editFilter, title: e.target.value})}
        header="Title"
      />
      <Textfield
        value={editFilter.content}
        type="text"
        onChange={e => setFilter({...editFilter, content: e.target.value})}
        header="Descriptions"
      />
      <ArraySearchableDropDown
        header="Topics"
        setValues={val => {
          setFilter({...editFilter, tags: val});
        }}
        values={editFilter.tags}
        items={tags}
        mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
      />

      <div className="flex-row justify-center padding-top8">
        <button
          type="submit"
          className="basic-button"
          onClick={async () => {
            setLoading(true);
            const options = {limit: constants.org_search_limit};
            await search(editFilter, options);
            setLoading(false);
          }}>
          Search
        </button>
      </div>
    </div>
  );
};
