import Anthropic from '@anthropic-ai/sdk';
import JSZip from 'jszip';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import {getDocument} from 'pdfjs-dist/webpack';
import * as XLSX from 'xlsx';
import constants from '../components/constants';
import {countTokens} from '../utils/utils';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const client = new Anthropic({
  apiKey: process.env.REACT_APP_ANTHROPIC_KEY,
  dangerouslyAllowBrowser: true,
});

const fireworksAPI = 'fw_3ZU5UQas2VFDjaTgM2ospi4n';

const SUPPORTED_FILE_TYPES = {
  // Images
  'image/jpeg': {type: 'image', maxSize: 20971520},
  'image/png': {type: 'image', maxSize: 20971520},
  'image/gif': {type: 'image', maxSize: 20971520},
  'image/webp': {type: 'image', maxSize: 20971520},
  'application/pdf': {type: 'document', maxSize: 104857600},
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    type: 'document',
    maxSize: 104857600,
  },
  'text/plain': {type: 'document', maxSize: 104857600},
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    type: 'document',
    maxSize: 104857600,
  },
  'application/vnd.ms-excel': {type: 'document', maxSize: 104857600},
  // Add PowerPoint types
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    type: 'document',
    maxSize: 104857600,
  },
  'application/vnd.ms-powerpoint': {
    type: 'document',
    maxSize: 104857600,
  },
};

const createAIMessage = async options => {
  try {
    const message = await client.messages.create({
      max_tokens: 4096,
      model: 'claude-3-opus-20240229',
      ...options,
    });

    return message;
  } catch (err) {
    throw err;
  }
};

export const LLAMA_SUPPORTED_FILES = {
  'application/pdf': {type: 'document', maxSize: 104857600}, // 100MB
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    type: 'document',
    maxSize: 104857600,
  },
  'text/plain': {type: 'document', maxSize: 104857600},
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    type: 'document',
    maxSize: 104857600,
  },
  'application/vnd.ms-excel': {type: 'document', maxSize: 104857600},
};

const handleFilesForLlama = async files => {
  const processedContent = [];
  const unsupportedFiles = [];

  for (const file of files) {
    try {
      const fileConfig = LLAMA_SUPPORTED_FILES[file.type];
      if (!fileConfig) {
        unsupportedFiles.push(file.name);
        continue;
      }

      // Check file size
      if (file.size > fileConfig.maxSize) {
        unsupportedFiles.push(
          `${file.name} (exceeds ${
            fileConfig.maxSize / (1024 * 1024)
          }MB limit)`,
        );
        continue;
      }

      let text;
      switch (file.type) {
        case 'application/pdf':
          text = await extractTextFromPDF(file);
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          text = await extractTextFromDOCX(file);
          break;
        case 'text/plain':
          text = await extractTextFromTXT(file);
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
          text = await extractTextFromExcel(file);
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.ms-powerpoint':
          text = await extractTextFromPPTX(file);
          break;
        default:
          unsupportedFiles.push(file.name);
          continue;
      }

      processedContent.push({
        name: file.name,
        type: file.type,
        content: text,
      });
    } catch (error) {
      console.error(`Error processing file ${file.name}:`, error);
      unsupportedFiles.push(`${file.name} (processing error)`);
    }
  }

  return {processedContent, unsupportedFiles};
};

export const isFileValidForClaude = file => {
  // Check if file object is provided
  if (!file) {
    return {
      isValid: false,
      message: 'File is missing',
    };
  }

  // Check if file type is supported
  const fileConfig = SUPPORTED_FILE_TYPES[file.type];
  if (!fileConfig) {
    // Create a user-friendly message based on supported types
    return {
      isValid: false,
      message: 'Invalid type',
    };
  }

  // Check file size
  if (file.size > fileConfig.maxSize) {
    return {
      isValid: false,
      message: 'File too large. Max size 10MB',
    };
  }

  // All validation checks passed
  return {
    isValid: true,
    message: '', // No message needed for valid files
  };
};

const validateMessage = message => {
  if (!message.content || !Array.isArray(message.content)) {
    throw new Error('Message content must be an array');
  }

  message.content.forEach((content, idx) => {
    if (!content.type || !content.text || typeof content.text !== 'string') {
      throw new Error(`Invalid content at index ${idx}`);
    }
  });
};

const StreamAIMessageClaude = async ({user_id, action, ...apiOptions}) => {
  try {
    apiOptions.messages.forEach((message, idx) => {
      try {
        validateMessage(message);
      } catch (err) {
        console.error(`Invalid message at index ${idx}:`, message);
        throw err;
      }
    });
    // Calculate input tokens before making the request
    let inputTokens = 0;
    apiOptions.messages.forEach(message => {
      message.content?.forEach(data => {
        if (data.type === 'text') {
          inputTokens += countTokens(data.text);
        }
      });
    });

    // If there's a system message, include its tokens
    if (apiOptions.system) {
      inputTokens += countTokens(apiOptions.system);
    }

    const stream = await client.messages.create({
      max_tokens: constants.max_tokens_in_response,
      model: 'claude-3-5-sonnet-20241022',
      stream: true,
      ...apiOptions,
    });

    let fullResponse = '';
    let outputTokens = 0; // Track output tokens

    for await (const messageStreamEvent of stream) {
      if (
        messageStreamEvent.type === 'content_block_start' ||
        messageStreamEvent.type === 'content_block_delta'
      ) {
        const text = messageStreamEvent.delta?.text || '';
        fullResponse += text;

        if (apiOptions.onUpdate) {
          apiOptions.onUpdate(text);
        }
      }

      if (messageStreamEvent.type === 'message_delta') {
        if (messageStreamEvent.usage) {
          console.log('Token usage:', messageStreamEvent.usage);
          // Record final output token usage
          if (user_id && messageStreamEvent.usage.output_tokens) {
            outputTokens = messageStreamEvent.usage.output_tokens;
          }
        }
      }
    }

    const inputUsage = {
      user_id,
      tokens_used: inputTokens,
      model_used: 'claude-3-5-sonnet-20241022',
      token_type: 'input',
      action,
    };

    const outputUsage = {
      user_id,
      tokens_used: countTokens(fullResponse),
      model_used: 'claude-3-5-sonnet-20241022',
      token_type: 'output',
      action,
    };
    if (apiOptions.onComplete) {
      apiOptions.onComplete({inputUsage, outputUsage, fullResponse});
    }

    return fullResponse;
  } catch (err) {
    console.error('Streaming error:', err);
    throw err;
  }
};

const StreamAIMessageLlama = async ({user_id, action, ...apiOptions}) => {
  try {
    // Calculate input tokens
    let inputTokens = 0;

    // Convert and count tokens for messages
    const formattedMessages = apiOptions.messages.map(msg => {
      const content = msg.content
        .map(c => (c.type === 'text' ? c.text : ''))
        .join(' ');

      inputTokens += countTokens(content);

      return {
        role: msg.role,
        content: content,
      };
    });

    // Count system message tokens if present
    if (apiOptions.system) {
      inputTokens += countTokens(apiOptions.system);
    }

    const response = await fetch(
      'https://api.fireworks.ai/inference/v1/chat/completions',
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${fireworksAPI}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: apiOptions?.model
            ? apiOptions.model
            : 'accounts/fireworks/models/llama-v3p1-8b-instruct',
          messages: [
            ...(apiOptions.system
              ? [{role: 'system', content: apiOptions.system}]
              : []),
            ...formattedMessages,
          ],
          temperature: 0.7,
          max_tokens: constants.max_tokens_in_response,
          stream: true,
          top_p: 0.9,
        }),
      },
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let buffer = '';
    let fullResponse = '';

    while (true) {
      const {done, value} = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, {stream: true});
      const lines = buffer.split('\n');
      buffer = lines.pop() || '';

      for (const line of lines) {
        if (line.trim() && line.startsWith('data: ')) {
          try {
            const jsonData = JSON.parse(line.slice(6));
            if (jsonData.choices?.[0]?.delta?.content) {
              const text = jsonData.choices[0].delta.content;
              fullResponse += text;

              if (apiOptions.onUpdate) {
                apiOptions.onUpdate(text);
              }
            }
          } catch (e) {
            console.warn('Error parsing SSE message:', e);
          }
        }
      }
    }

    // Process remaining buffer
    if (buffer.trim() && buffer.startsWith('data: ')) {
      try {
        const jsonData = JSON.parse(buffer.slice(6));
        if (jsonData.choices?.[0]?.delta?.content) {
          const text = jsonData.choices[0].delta.content;
          fullResponse += text;
          if (apiOptions.onUpdate) {
            apiOptions.onUpdate(text);
          }
        }
      } catch (e) {
        console.warn('Error parsing final SSE message:', e);
      }
    }

    const inputUsage = {
      user_id,
      tokens_used: inputTokens,
      model_used: 'llama-v3p1-8b-instruct',
      token_type: 'input',
      action,
    };

    const outputUsage = {
      user_id,
      tokens_used: countTokens(fullResponse),
      model_used: 'llama-v3p1-8b-instruct',
      token_type: 'output',
      action,
    };

    if (apiOptions.onComplete) {
      apiOptions.onComplete({inputUsage, outputUsage, fullResponse});
    }

    return fullResponse;
  } catch (error) {
    console.error('Streaming error:', error);
    throw new Error(`Fireworks AI streaming failed: ${error.message}`);
  }
};

const handleFilesForClaude = async files => {
  if (!files) return [];
  const processedContent = [];

  for (const file of files) {
    try {
      const fileConfig = SUPPORTED_FILE_TYPES[file.type];
      if (!fileConfig) {
        console.warn(`Unsupported file type: ${file.type}`);
        continue;
      }

      // CHECK FILE SIZES
      const valid = validateFileSize(file, fileConfig.maxSize);
      if (!valid) {
        return;
      }

      if (fileConfig.type === 'image') {
        const base64Data = await fileToBase64(file);
        processedContent.push({
          type: 'image',
          source: {
            type: 'base64',
            media_type: file.type,
            data: base64Data,
          },
        });
      } else {
        let text;
        switch (file.type) {
          case 'application/pdf':
            text = await extractTextFromPDF(file);
            break;
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            text = await extractTextFromDOCX(file);
            break;
          case 'text/plain':
            text = await extractTextFromTXT(file);
            break;
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.ms-excel':
            text = await extractTextFromExcel(file);
            break;
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          case 'application/vnd.ms-powerpoint':
            text = await extractTextFromPPTX(file);
            break;
          default:
            break;
        }

        processedContent.push({
          type: 'text',
          text,
          name: file?.name || '',
        });
      }
    } catch (error) {
      console.error(`Error processing file ${file.name}:`, error);
    }
  }

  return processedContent;
};

const handleFileForClaude = async file => {
  if (!file) return null;
  let processedContent = {};

  try {
    const fileConfig = SUPPORTED_FILE_TYPES[file.type];
    if (!fileConfig) {
      console.warn(`Unsupported file type: ${file.type}`);
    }

    // CHECK FILE SIZES
    const valid = validateFileSize(file, fileConfig.maxSize);
    if (!valid) {
      return null;
    }

    if (fileConfig.type === 'image') {
      const base64Data = await fileToBase64(file);
      processedContent = {
        type: 'image',
        source: {
          type: 'base64',
          media_type: file.type,
          data: base64Data,
        },
      };
    } else {
      let text;
      switch (file.type) {
        case 'application/pdf':
          text = await extractTextFromPDF(file);
          break;
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          text = await extractTextFromDOCX(file);
          break;
        case 'text/plain':
          text = await extractTextFromTXT(file);
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel':
          text = await extractTextFromExcel(file);
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.ms-powerpoint':
          text = await extractTextFromPPTX(file);
          break;
        default:
          break;
      }

      processedContent = {
        type: 'text',
        text,
        name: file?.name || '',
      };
    }
  } catch (error) {
    console.error(`Error processing file ${file.name}:`, error);
  }

  return processedContent;
};

const extractTextFromTXT = async file => file.text();

const extractTextFromPDF = async file => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await getDocument({data: arrayBuffer}).promise;
    let text = '';

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const content = await page.getTextContent();
      text += content.items.map(item => item.str).join(' ') + '\n';
    }
    return text;
  } catch (error) {
    throw new Error(`PDF extraction failed: ${error.message}`);
  }
};

const extractTextFromDOCX = async file => {
  const arrayBuffer = await file.arrayBuffer();
  const result = await mammoth.extractRawText({arrayBuffer});
  return result.value;
};

const extractTextFromExcel = async file => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const workbook = XLSX.read(arrayBuffer, {type: 'array'});
    return workbook.SheetNames.map(sheetName => {
      const worksheet = workbook.Sheets[sheetName];
      // Use sheet_to_csv instead of sheet_to_string
      return `Sheet: ${sheetName}\n${XLSX.utils.sheet_to_csv(worksheet)}\n`;
    }).join('\n');
  } catch (error) {
    console.error('Excel extraction error:', error);
    throw new Error(`Failed to extract text from Excel: ${error.message}`);
  }
};

const validateFileSize = (file, maxSize) => {
  if (file.size > maxSize) {
    return false;
  }
  return true;
};

// Convert file to base64
const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Remove the data URL prefix (e.g., "data:image/jpeg;base64,")
      const base64String = reader.result.split(',')[1];
      resolve(base64String);
    };
    reader.onerror = error => reject(error);
  });
};

const extractTextFromPPTX = async file => {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const zip = new JSZip();
    const content = await zip.loadAsync(arrayBuffer);

    let text = '';
    const slidePromises = [];

    // Find all slide files in the PowerPoint
    for (const [path, file] of Object.entries(content.files)) {
      if (path.startsWith('ppt/slides/slide') && path.endsWith('.xml')) {
        const promise = file.async('string').then(content => {
          // Extract text content from XML
          const textMatches = content.match(/<a:t>([^<]*)<\/a:t>/g) || [];
          const slideText = textMatches
            .map(match => match.replace(/<[^>]*>/g, ''))
            .join(' ');
          return slideText;
        });
        slidePromises.push(promise);
      }
    }

    // Combine text from all slides
    const slideTexts = await Promise.all(slidePromises);
    text = slideTexts.join('\n\n');

    return text;
  } catch (error) {
    console.error('PowerPoint extraction error:', error);
    throw new Error(`Failed to extract text from PowerPoint: ${error.message}`);
  }
};

export {
  createAIMessage,
  handleFileForClaude,
  handleFilesForClaude,
  handleFilesForLlama,
  StreamAIMessageClaude,
  StreamAIMessageLlama,
};
