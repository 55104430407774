/**
 * Document templates for different types of content generation.
 * Each template defines a specific output format with placeholders
 * for dynamic content.
 */

// Project Report Templates
/**
 * Maps template identifiers to their actual templates.
 * This maintains compatibility with existing code while providing
 * a cleaner interface for template selection.
 */
import {AiOutlineFile, AiOutlineFileText} from 'react-icons/ai';
import {FaConnectdevelop} from 'react-icons/fa';
import {MdOutlineSummarize} from 'react-icons/md';
import {
  RiBarChartBoxLine,
  RiBookOpenLine,
  RiCheckboxCircleLine,
  RiDashboardLine,
  RiFileTextLine,
  RiLightbulbLine,
  RiNumber1,
  RiNumber3,
  RiNumber5,
  RiNumber8,
  RiShieldLine,
} from 'react-icons/ri';
import {TbMail, TbMailBolt} from 'react-icons/tb';
import {
  PublicMindLogoDarkNoText,
  PublicMindLogoLightNoText,
} from '../components/images/logos';

export const PROJECT_REPORTS = {
  PUBLICMIND_WEEKLY_REPORT: `# Week [Date] Workspace Report

## Core Metrics
Active Projects: [#] ([+/-])
User Growth: [#] new
Critical Issues: [brief description if any]

## Project Movement
✨ New
- [Project Name] - [One key detail]

🟢 Progressing
- [Project Name] - [One key detail]

🔴 At Risk
- [Project Name] - [One key detail]

✅ Complete 
- [Project Name] - [Key outcome]

## Week's Major Developments
[3 most important changes/learnings that affect our strategy]

## Next Week's Critical Path
[What absolutely must happen for us to win]

## Key Decisions Made
[List of important decisions from the week]

Report By: [Name]`,
  RWA_REPORT: `# Regional Workforce Advisory Monthly Activity Report

## Report Information
Name: [Full Name]
Region: [Region Name]
Month: [Month Year]

## Event Activity this Month

### Event Details
For each event, provide the following information. Numerical accuracy is critical:

Event [Number] Description: [Event Name]
Location: [City]
Attendee Count:
- Students: [Number]
- Educators: [Number]
- Parents: [Number]
- Business Partners/Community Representatives: [Number]

[Repeat structure for each event]

## Impact & Activity

### Overview
[Provide narrative summary of ongoing business and educational institution engagement, including:
- Business referrals and resources provided
- Educational institution support and resources
- Relationship building activities
- Regular meeting participation]

### Monthly Focus
[Detail primary activities and focus areas for the month in a narrative, including:
- Stakeholder meetings
- Event coordination
- Training sessions
- Special initiatives]

### Activity Log
[List of specific activities with dates in chronological order]

## Upcoming Activities
[List future scheduled activities chronologically with dates and descriptions:
Month Day - Event Description Location]

---
Template Notes:
1. All dates should be in format: Month Day
2. Activities should be listed chronologically
3. Attendee counts must be provided as accurate numerical values
4. Location names should be specific cities/venues
5. Each event requires complete attendee breakdown across all four categories`,

  EXECUTIVE_BRIEF: `Generate a high-level strategic summary:

# [Project Title] - Executive Brief

## Core Outcomes
- [Primary outcome achieved]
- [Secondary impacts]
- [Collaboration highlights]

## Innovation Elements
- [Novel approaches]
- [Process improvements]
- [Future applications]

## Value Creation
- [Resource optimization]
- [Stakeholder benefits]
- [Impact indicators]

## Path Forward
- [Strategic recommendations]
- [Growth opportunities]
- [Next phase considerations]

Note: Emphasize strategic value and future potential.`,

  CONCISE_BRIEF: `Generate a focused project summary:

# [Project Title] - Project Brief

## Key Achievements
- [Primary outcomes]
- [Notable impacts]
- [Collaboration wins]

## Core Metrics
- [Performance indicators]
- [Resource utilization]
- [Impact measures]

## Key Learnings
- [Success factors]
- [Challenge resolutions]
- [Future insights]

Note: Focus on essential outcomes and insights.`,

  FULL_REPORT: `Create a comprehensive project knowledge base:

# [Project Title] - Implementation Guide

## Executive Overview
[One paragraph project summary]

## Framework
### Structure
- [Organizational model]
- [Coordination approach]
- [Implementation methods]

### Collaboration Model
- [Leadership structure]
- [Resource management]
- [Engagement strategy]

## Impact Assessment
### Performance Indicators
- [Primary metrics]
- [Success measures]
- [Growth indicators]

### Collaboration Outcomes
- [Cross-sector efficiency]
- [Resource effectiveness]
- [Stakeholder engagement]

## Knowledge Base
### Success Patterns
- [Proven approaches]
- [Effective tools]
- [Risk management]

Note: Focus on reusable insights and methodologies.`,
  METRICS_REPORT: `Generate a performance-focused analysis:

# [Project Title] - Performance Analysis

## Key Metrics
### Impact Measures
- [Primary outcomes]
- [Growth indicators]
- [Resource efficiency]

### Collaboration Metrics
- [Team effectiveness]
- [Cross-sector coordination]
- [Timeline performance]

### Stakeholder Impact
- [Organization benefits]
- [Community outcomes]
- [Partner value]

## Performance Context
- [Historical comparison]
- [Peer benchmarks]
- [Industry standards]

Note: Emphasize measurable outcomes and context.`,
  LESSONS_LEARNED: `Create a learning-focused summary:

# [Project Title] - Knowledge Summary

## Success Elements
### Process Insights
- [Effective methods]
- [Resource usage]
- [Engagement strategies]

### Innovation Areas
- [Novel approaches]
- [Collaboration models]
- [Implementation tools]

## Challenge Resolution
### Key Issues
- [Primary challenges]
- [Impact areas]
- [Solution approaches]

### Future Improvements
- [Process refinements]
- [Resource alignment]
- [Partnership enhancement]

Note: Focus on actionable learning for future initiatives.`,

  KNOWLEDGE_BASE: `
    ## [Project Title] - Institutional Knowledge Capture

### Project Context
- [Historical background]
- [Key personnel changes]
- [Critical decisions]

### Process Documentation
- [Standard procedures]
- [Communication protocols]
- [Resource allocation methods]

### Future Guidance
- [Known challenges]
- [Success patterns]
- [Improvement opportunities]
  `,
};

// Planning Templates
export const PLANNING_TEMPLATES = {
  COMBAT_PLANNING: `# Combat Planning Meeting 
## Priority & Execute (10 min)
### Strategic Focus
- Current Priority: [#1 mission-critical objective]
- Success Metric: [specific measure]
- Owner: [clear individual owner]

### Intelligence Update (SITREP)
- Market Changes: [key updates]
- Competitive Intel: [threats/opportunities] 
- Project Health: [red/yellow/green status]

## Team Battle Rhythm (15 min)
### [Team Member Name]
- Primary Mission: [clear objective]
- Last Week Status: [completed/blocked/in progress]
- This Week Plan:
 - Critical Task 1 [timeline]
 - Critical Task 2 [timeline]
- Support Needed: [specific requests]

[Repeat for each team member]

## Course of Action Review (15 min)
### Active Projects
- Project Name: [title]
- Commander's Intent: [end goal]
- Current Status: [health]
- Next Milestone: [specific target]
- Blockers: [issues]
- Go/No-Go Decision: [by date]

[Repeat for key projects]

## Combat Decisions
### Immediate Actions
- [Specific task]
- Owner: [name]
- Deadline: [date]

### Contingency Plans  
- Risk: [potential issue]
- Mitigation: [specific plan]
- Trigger Point: [when to execute]

## Next Mission Planning
- Next Priority: [objective]
- Key Players: [names]
- Timeline: [dates]
- Resources Needed: [specific items]

Note: Focus on clear ownership, simple execution plans, and measurable outcomes.`,

  PUBLICMIND_WEEKLY_PLANNING: `# Weekly Plan [Date]
What must happen this week for us to win?

## Mission Focus
[One sentence on the critical priority for the week]

## Engineering
Must Complete:
[3-5 specific deliverables with clear success criteria]

If Time Permits:
[1-2 stretch goals that would accelerate progress]

## Product/Projects
Must Complete:
[3-5 specific deliverables with clear success criteria]

If Time Permits:
[1-2 stretch goals that would accelerate progress]

## Growth/Sales/Design
Must Complete:
[3-5 specific deliverables with clear success criteria]

If Time Permits:
[1-2 stretch goals that would accelerate progress]

## Team Dependencies
[Brief list of critical handoffs or shared work]

## Key Risks
[1-2 sentence description of main risks to watch]`,
  PROJECT_VALIDATION: `
  
  ## [Project Title] - Validation Framework

### Problem Definition
- [Current pain points]
- [Stakeholder impacts]
- [Resource inefficiencies]

### Solution Validation
- [Proposed approach]
- [Success metrics]
- [Resource requirements]

### Stakeholder Analysis
- [Key decision makers]
- [Required approvals]
- [Communication strategy]
  `,
  QUICK_START: `Generate a rapid initialization framework:

# [Project Title] - Quick Start

## Project Scope
- [Core objectives]
- [Key participants]
- [Initial needs]

## Initial Timeline
- [Month 1 priorities]
- [Month 2 milestones]
- [Month 3 targets]

## Success Requirements
- [Essential approvals]
- [Critical partnerships]
- [Resource needs]

Note: Focus on rapid startup and early momentum.`,

  FULL_PLAN: `Create a comprehensive project plan:

# [Project Title] - Strategic Plan

## Framework
- [Core requirements]
- [Organizational alignment]
- [Implementation considerations]

## Resources
### Team Structure
- [Leadership roles]
- [Required capabilities]
- [Development needs]

### Resource Plan
- [Budget framework]
- [Funding approach]
- [Timeline requirements]

## Implementation
### Phase 1: Launch
- [Initial steps]
- [Early goals]
- [Success markers]

### Phase 2: Development
- [Core milestones]
- [Resource deployment]
- [Risk management]

### Phase 3: Optimization
- [Enhancement goals]
- [Growth plans]
- [Sustainability approach]

Note: Emphasize thorough planning and accountability.`,

  ACTION_PLAN: `Generate an action-focused framework:

# [Project Title] - Action Plan

## Immediate Steps
- [Priority action 1]
- [Priority action 2]
- [Priority action 3]

## Near-Term Goals
- [Key milestone 1]
- [Key milestone 2]
- [Key milestone 3]

## Success Measures
- [Key indicator 1]
- [Key indicator 2]
- [Key indicator 3]

Note: Focus on concrete actions and ownership.`,

  RESOURCE_PLAN: `Create a resource allocation framework:

# [Project Title] - Resource Framework

## Team Resources
- [Organization structure]
- [Role requirements]
- [Capability needs]

## Financial Resources
- [Primary funding]
- [Additional resources]
- [Contingency planning]

## Technical Resources
- [Platform needs]
- [Tool requirements]
- [Integration requirements]

Note: Emphasize efficient resource use and allocation.`,
  TIMELINE_PLAN: `Generate a project timeline:

# [Project Title] - Timeline

## Launch Phase
- [Month 1 activities]
- [Month 2 activities]
- [Month 3 activities]

## Development Phase
- [Core milestones]
- [Resource timing]
- [Key deliverables]

## Optimization Phase
- [Enhancement targets]
- [Growth objectives]
- [Success measures]

Note: Focus on realistic timing and dependencies.`,
};

// Progress Tracking Templates
export const PROGRESS_TEMPLATES = {
  MILESTONE_TRACKING: `Create a milestone tracking update:

# [Project Title] - Milestone Status

## Progress Update
- [Completed items]
- [Current activities]
- [Next priorities]

## Resource Status
- [Budget update]
- [Team status]
- [Resource needs]

## Risk Update
- [Active challenges]
- [Response actions]
- [Required support]

Note: Focus on progress and resource management.`,

  PROGRESS_REPORT: `Generate a comprehensive status update:

# [Project Title] - Progress Report

## Achievement Update
- [Recent wins]
- [Current metrics]
- [Schedule status]

## Resource Review
- [Budget status]
- [Team updates]
- [Tool effectiveness]

## Forward Plan
- [Next priorities]
- [Resource needs]
- [Risk management]

Note: Emphasize current status and next steps.`,

  STAKEHOLDER_BRIEF: `Create a stakeholder update:

# [Project Title] - Stakeholder Brief

## Overview
- [Key progress]
- [Important metrics]
- [Strategic updates]

## Partnership Update
- [Collaboration progress]
- [Resource status]
- [Value delivery]

## Next Steps
- [Required decisions]
- [Support needs]
- [Forward plan]

Note: Focus on value creation and decision support.`,

  RISK_ASSESSMENT: `Generate a risk assessment:

# [Project Title] - Risk Review

## Current Risks
- [Active issues]
- [Potential impacts]
- [Response status]

## Resource Risks
- [Budget status]
- [Team capacity]
- [Timeline factors]

## Response Plan
- [Priority actions]
- [Resource needs]
- [Support requirements]

Note: Emphasize proactive risk management.`,
};

// Presentation Templates
export const PRESENTATION_TEMPLATES = {
  SINGLE_SLIDE: `# [Project Title] - Single Slide Summary

## Key Points
- [Primary point]
- [Secondary point]
- [Supporting detail]

Note: Focus on most critical information only.`,

  THREE_SLIDES: `# [Project Title] - 3 Slide Overview

## Slide 1: Executive Summary
- [Core message]
- [Key context]

## Slide 2: Key Details
- [Critical points]
- [Supporting data]

## Slide 3: Next Steps
- [Action items]
- [Timeline]`,

  FIVE_SLIDES: `# [Project Title] - 5 Slide Presentation

## Slide 1: Title & Overview
- [Project title]
- [Brief context]

## Slide 2: Background
- [Project context]
- [Key objectives]

## Slide 3: Current Status
- [Progress update]
- [Key metrics]

## Slide 4: Key Findings
- [Critical insights]
- [Supporting data]

## Slide 5: Path Forward
- [Next steps]
- [Timeline]`,

  EIGHT_SLIDES: `# [Project Title] - 8 Slide Presentation

## Slide 1: Title
- [Project overview]

## Slide 2: Context
- [Background]
- [Objectives]

## Slide 3: Approach
- [Methodology]
- [Strategy]

## Slide 4: Progress
- [Current status]
- [Achievements]

## Slide 5: Metrics
- [Key data]
- [Performance indicators]

## Slide 6: Challenges
- [Issues]
- [Solutions]

## Slide 7: Insights
- [Key findings]
- [Recommendations]

## Slide 8: Next Steps
- [Action items]
- [Timeline]`,
};

// Email and Summary Templates
export const COMMUNICATION_TEMPLATES = {
  SHORT_EMAIL: `Subject: [Project] - Quick Update
Dear [Name],

[One-sentence context]. Key updates:
- [Primary update]
- [Secondary update]
- [Next steps]

Best regards,
[Name]`,

  LONG_EMAIL: `Subject: [Project] - Detailed Update
Dear [Name],

[Context paragraph]

Current Status:
- [Progress details]
- [Key metrics]
- [Challenges]

Next Steps:
- [Action items]
- [Timeline]
- [Support needed]

Best regards,
[Name]`,

  SHORT_SUMMARY: `# [Project] Quick Summary
- [Core message]
- [Key points]
- [Next steps]`,

  LONG_SUMMARY: `# [Project] Detailed Summary

## Overview
[Context paragraph]

## Key Points
- [Primary points]
- [Supporting details]

## Next Steps
- [Action items]
- [Timeline]`,

  PAGE_SUMMARY: `# [Project] One-Page Summary

## Context
[Background paragraph]

## Current Status
- [Progress]
- [Metrics]

## Key Findings
- [Primary insights]
- [Supporting data]

## Path Forward
- [Next steps]
- [Timeline]`,

  BULLET_SUMMARY: `# [Project] Bullet Summary

## Key Points
- [Primary point]
- [Secondary point]
- [Supporting detail]

## Action Items
- [Priority action]
- [Next steps]
- [Timeline]`,
};

export const templateMapping = {
  // Completed Project Templates
  knowledge_preservation: {
    template: PROJECT_REPORTS.KNOWLEDGE_BASE,
    label: 'Institutional Knowledge',
    description:
      'Capture project history, processes, and key decisions to preserve organizational knowledge and ensure continuity.',
    icon: <FaConnectdevelop size={24} />,
  },
  executive: {
    template: PROJECT_REPORTS.EXECUTIVE_BRIEF,
    label: 'Executive Brief',
    description:
      'Strategic overview highlighting outcomes, innovations, value creation, and future opportunities.',
    icon: <RiDashboardLine size={24} />,
  },
  concise: {
    template: PROJECT_REPORTS.CONCISE_BRIEF,
    label: 'Concise Summary',
    description:
      'Quick overview of key achievements, metrics, and learnings for efficient communication.',
    icon: <RiFileTextLine size={24} />,
  },
  full: {
    template: PROJECT_REPORTS.FULL_REPORT,
    label: 'Implementation Guide',
    description:
      'Comprehensive documentation of project framework, impact, and success patterns for future reference.',
    icon: <RiBookOpenLine size={24} />,
  },
  metrics: {
    template: PROJECT_REPORTS.METRICS_REPORT,
    label: 'Performance Analysis',
    description:
      'Detailed analysis of impact measures, collaboration metrics, and stakeholder outcomes with benchmarking.',
    icon: <RiBarChartBoxLine size={24} />,
  },
  lessons: {
    template: PROJECT_REPORTS.LESSONS_LEARNED,
    label: 'Knowledge Summary',
    description:
      'Analysis of success elements, challenge resolutions, and future improvement opportunities.',
    icon: <RiLightbulbLine size={24} />,
  },
  publicmind_weekly_report: {
    template: PROJECT_REPORTS.PUBLICMIND_WEEKLY_REPORT,
    label: 'PublicMind Weekly Report',
    description: `Structured report for PublicMind team's weekly work.`,
    icon: <PublicMindLogoLightNoText />,
  },
  // Draft Project Templates
  quick_plan: {
    template: PROJECT_REPORTS.QUICK_START,
    label: 'Quick Start Guide',
    description:
      'Initial 3-month framework covering scope, timeline, and critical success requirements.',
    icon: <RiFileTextLine size={24} />,
  },
  project_validation: {
    template: PROJECT_REPORTS.PROJECT_VALIDATION,
    label: 'Project Assessment',
    description:
      'Analyze project viability through problem definition, solution validation, and stakeholder mapping.',
    icon: <RiLightbulbLine size={24} />,
  },
  full_plan: {
    template: PROJECT_REPORTS.FULL_PLAN,
    label: 'Strategic Plan',
    description:
      'Three-phase project plan covering framework, resources, and implementation strategy.',
    icon: <RiBookOpenLine size={24} />,
  },
  action_plan: {
    template: PROJECT_REPORTS.ACTION_PLAN,
    label: 'Action Framework',
    description:
      'Prioritized immediate steps, near-term goals, and concrete success measures.',
    icon: <RiCheckboxCircleLine size={24} />,
  },
  resource_plan: {
    template: PROJECT_REPORTS.RESOURCE_PLAN,
    label: 'Resource Framework',
    description:
      'Structured planning for team, financial, and technical resource allocation.',
    icon: <RiBarChartBoxLine size={24} />,
  },
  timeline_plan: {
    template: PROJECT_REPORTS.TIMELINE_PLAN,
    label: 'Project Timeline',
    description:
      'Phased timeline covering launch, development, and optimization activities.',
    icon: <RiDashboardLine size={24} />,
  },
  publicmind_weekly_planning: {
    template: PLANNING_TEMPLATES.PUBLICMIND_WEEKLY_PLANNING,
    label: 'PublicMind Weekly Plan',
    description: `Structured plan for PublicMind team's weekly work.`,
    icon: <PublicMindLogoDarkNoText />,
  },
  combat_planning: {
    template: PLANNING_TEMPLATES.COMBAT_PLANNING,
    label: 'Combat Planning',
    description:
      'Military-style planning framework focused on extreme ownership and mission success.',
    icon: <RiShieldLine size={24} />,
  },

  // Live Project Templates
  milestone_track: {
    template: PROJECT_REPORTS.MILESTONE_TRACKING,
    label: 'Milestone Status',
    description:
      'Current progress, resource status, and risk updates for active projects.',
    icon: <RiCheckboxCircleLine size={24} />,
  },
  progress_report: {
    template: PROJECT_REPORTS.PROGRESS_REPORT,
    label: 'Status Report',
    description:
      'Comprehensive update on achievements, resources, and forward planning.',
    icon: <RiBarChartBoxLine size={24} />,
  },
  stakeholder_brief: {
    template: PROJECT_REPORTS.STAKEHOLDER_BRIEF,
    label: 'Stakeholder Update',
    description:
      'Strategic overview of progress, partnerships, and next steps for key stakeholders.',
    icon: <RiFileTextLine size={24} />,
  },
  risk_monitor: {
    template: PROGRESS_TEMPLATES.RISK_ASSESSMENT,
    label: 'Risk Assessment',
    description:
      'Active monitoring of current risks, resource challenges, and response planning.',
    icon: <RiDashboardLine size={24} />,
  },

  // Email Templates
  short_email_summary: {
    template: COMMUNICATION_TEMPLATES.SHORT_EMAIL,
    label: 'Short Summary Email',
    description: 'Concise overview of key points in bullet point format.',
    icon: <TbMail size={24} />,
  },
  long_email_summary: {
    template: COMMUNICATION_TEMPLATES.LONG_EMAIL,
    label: 'Long Summary Email',
    description:
      'Detailed summary in paragraph form with thorough explanation.',
    icon: <TbMailBolt size={24} />,
  },

  // Summary Templates
  short_summary: {
    template: COMMUNICATION_TEMPLATES.SHORT_SUMMARY,
    label: 'Short Summary',
    description:
      'High-level overview in bullet point format focused on main takeaways.',
    icon: <MdOutlineSummarize size={24} />,
  },
  long_summary: {
    template: COMMUNICATION_TEMPLATES.LONG_SUMMARY,
    label: 'Long Summary',
    description:
      'Comprehensive summary in paragraph form with deeper analysis and context.',
    icon: <AiOutlineFileText size={24} />,
  },
  page_summary: {
    template: COMMUNICATION_TEMPLATES.PAGE_SUMMARY,
    label: '1 Page Summary',
    description:
      'Single page summary document with balanced coverage of critical details.',
    icon: <AiOutlineFile size={24} />,
  },
  bullet_summary: {
    template: COMMUNICATION_TEMPLATES.BULLET_SUMMARY,
    label: 'Bullet Point Summary',
    description:
      'Summary presented as a structured list, easy to scan and reference key points.',
    icon: <MdOutlineSummarize size={24} />,
  },

  // Presentation Templates
  slide_presentation_1: {
    template: PRESENTATION_TEMPLATES.SINGLE_SLIDE,
    label: '1 Slide Presentation',
    description: 'Single slide with core information, maximizes visual impact.',
    icon: <RiNumber1 size={24} />,
  },
  slide_presentation_3: {
    template: PRESENTATION_TEMPLATES.THREE_SLIDES,
    label: '3 Slide Presentation',
    description: 'Brief 3-slide deck covering essential points.',
    icon: <RiNumber3 size={24} />,
  },
  slide_presentation_5: {
    template: PRESENTATION_TEMPLATES.FIVE_SLIDES,
    label: '5 Slide Presentation',
    description:
      'Standard 5-slide presentation format allowing for more detailed coverage.',
    icon: <RiNumber5 size={24} />,
  },
  slide_presentation_8: {
    template: PRESENTATION_TEMPLATES.EIGHT_SLIDES,
    label: '8 Slide Presentation',
    description:
      'Extended 8-slide presentation with comprehensive coverage and examples.',
    icon: <RiNumber8 size={24} />,
  },
};

// Helper function to get a template by its type and identifier
export const getTemplate = (type, identifier) => {
  switch (type) {
    case 'project_report':
      return PROJECT_REPORTS[identifier];
    case 'planning':
      return PLANNING_TEMPLATES[identifier];
    case 'progress':
      return PROGRESS_TEMPLATES[identifier];
    case 'presentation':
      return PRESENTATION_TEMPLATES[identifier];
    case 'communication':
      return COMMUNICATION_TEMPLATES[identifier];
    default:
      return templateMapping[identifier] || null;
  }
};
