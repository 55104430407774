// aiInteractionSystem.js

/**
 * Comprehensive system for managing AI interactions, context building, and response handling.
 * This system consolidates all AI-related functionality into a cohesive, maintainable structure.
 */

import {
  handleFileForClaude,
  StreamAIMessageClaude,
  StreamAIMessageLlama,
} from '../api/ai';
import generateAIDataforCurrentThread, {
  extractTextFromBlocks,
} from '../pages/threads/data/thread-data';
import {getSystemPrompt} from '../prompts/system-prompts';

/**
 * Defines the core interaction types available in the system.
 * Each type specifies only four essential properties:
 *
 * 1. systemPromptCategory: Determines which category of system prompt to use
 * 2. systemPromptType: Specifies the variant of prompt within that category
 * 3. requiresTemplate: Whether this interaction requires an output template
 * 4. useComplexStreaming: Whether to use Claude (complex) or Llama (simple) streaming
 *
 */
const AI_INTERACTION_TYPES = {
  DOCUMENT_GENERATION: {
    systemPromptCategory: 'document',
    systemPromptType: 'new',
    requiresTemplate: true,
    useComplexStreaming: true,
    model: 'claude-3-5-sonnet-20241022',
    action: 'document_generation',
  },

  DOCUMENT_EDITING: {
    systemPromptCategory: 'document',
    systemPromptType: 'existing',
    requiresTemplate: true,
    useComplexStreaming: true,
    model: 'claude-3-5-sonnet-20241022',
    action: 'document_generation',
  },

  WORKSPACE_INTEL: {
    systemPromptCategory: 'workspaceIntel',
    systemPromptType: 'brief',
    requiresTemplate: false,
    useComplexStreaming: false,
    model: 'llama-v3p1-8b-instruct',
    action: 'homepage_summary',
  },

  WORKSPACE_ASSISTANT: {
    systemPromptCategory: 'assistantBehavior',
    systemPromptType: 'chat',
    requiresTemplate: false,
    useComplexStreaming: false,
    model: 'llama-v3p1-8b-instruct',
    action: 'workspace_assistant',
  },
};

/**
 * ResponseHandler manages the streaming of AI responses and maintains response state.
 * It provides a clean interface for handling content updates, completion, and errors
 * while ensuring proper state management throughout the response lifecycle.
 */
class ResponseHandler {
  constructor(callbacks = {}) {
    // Initialize internal state
    this.content = '';
    this.status = 'idle'; // idle, streaming, completed, error
    this.startTime = null;

    // Set up callbacks with defaults
    this.callbacks = {
      onUpdate: callbacks.onUpdate || (content => {}),
      onComplete: callbacks.onComplete || (finalContent => {}),
      onError: callbacks.onError || console.error,
      onStateChange: callbacks.onStateChange || (state => {}),
    };
  }

  /**
   * Handles new content chunks during streaming.
   * Maintains running content and notifies listeners of updates.
   */
  handleUpdate = newContent => {
    // Validate state
    if (this.status === 'completed' || this.status === 'error') {
      throw new Error(`Cannot update content in ${this.status} state`);
    }

    // Initialize streaming if this is the first update
    if (this.status === 'idle') {
      this.startTime = Date.now();
      this.transitionState('streaming');
    }

    // Update content and notify
    try {
      this.content += newContent;
      this.callbacks.onUpdate(newContent, {
        chunk: newContent,
        fullContent: this.content,
        streamDuration: Date.now() - this.startTime,
        status: this.status,
      });
    } catch (error) {
      this.handleError({
        type: 'UpdateError',
        message: 'Failed to process content update',
        originalError: error,
      });
    }
  };

  /**
   * Handles successful completion of the response.
   * Finalizes content and cleans up state.
   */
  handleComplete = () => {
    try {
      // Calculate final statistics
      const duration = this.startTime ? Date.now() - this.startTime : 0;
      const finalContent = this.content;
      const stats = {
        duration,
        totalLength: finalContent.length,
        averageChunkSize: finalContent.length / (duration / 1000),
      };

      // Notify completion with full context
      this.callbacks.onComplete({
        content: finalContent,
        stats,
        status: 'completed',
      });

      // Reset state
      this.transitionState('completed');
      this.content = '';
      this.startTime = null;
    } catch (error) {
      this.handleError({
        type: 'CompletionError',
        message: 'Failed to handle completion',
        originalError: error,
      });
    }
  };

  /**
   * Handles errors that occur during response processing.
   * Provides detailed error context and ensures proper cleanup.
   */
  handleError = error => {
    // Build enhanced error context
    const enhancedError = {
      type: error.type || 'GeneralError',
      message: error.message || 'An error occurred during response processing',
      originalError: error.originalError || error,
      phase: 'AI Content Generation',
      state: {
        status: this.status,
        contentLength: this.content.length,
        streamDuration: this.startTime ? Date.now() - this.startTime : 0,
      },
      timestamp: new Date().toISOString(),
    };

    // Notify error with full context
    this.callbacks.onError(enhancedError);

    // Transition to error state and cleanup
    this.transitionState('error');
    this.content = '';
    this.startTime = null;

    // Notify completion handlers of the error
    this.callbacks.onComplete(null, enhancedError);
  };

  /**
   * Manages state transitions and notifies listeners of state changes.
   * Helps maintain consistent state throughout the response lifecycle.
   */
  transitionState(newState) {
    const oldState = this.status;
    this.status = newState;

    // Notify state change if a listener is provided
    this.callbacks.onStateChange({
      from: oldState,
      to: newState,
      timestamp: Date.now(),
    });
  }

  /**
   * Returns current state of the handler.
   * Useful for external components to check status.
   */
  getState() {
    return {
      status: this.status,
      contentLength: this.content.length,
      streamDuration: this.startTime ? Date.now() - this.startTime : 0,
    };
  }
}

/**
 * Unified context builder that processes user info, threads, and files into
 * a coherent context for AI analysis. This simplified approach replaces the
 * previous system of multiple processors and interaction-specific handling.
 */
class ContextBuilder {
  /**
   * Builds a complete context combining user info, threads, and files.
   * All data processing is handled uniformly regardless of interaction type.
   */
  async buildContext({
    user,
    threads = [],
    threadUpdates = [],
    files = [],
    additionalConfig = {},
  }) {
    try {
      // Process all data sources in parallel for efficiency
      const [userContext, threadsContext, threadUpdatesContext, filesContext] =
        await Promise.all([
          this.processUserContext(user),
          this.processThreads(threads, additionalConfig),
          this.processThreadUpdates(threadUpdates, additionalConfig),
          this.processFiles(files),
        ]);

      // Combine contexts with clear section separation
      const context = [
        userContext,
        threadsContext,
        threadUpdatesContext,
        filesContext,
        this.processAdditionalContext(additionalConfig),
      ]
        .filter(Boolean)
        .join('\n\n');

      return {
        context,
        metadata: {
          threadCount: threads.length,
          threadUpdatesCount: threadUpdates.length,
          fileCount: files.length,
          hasUserContext: !!userContext,
        },
      };
    } catch (error) {
      console.error('Error building context:', error);
      throw new Error('Failed to build context: ' + error.message);
    }
  }

  /**
   * Processes user information into a standardized format
   */
  processUserContext(user) {
    if (!user?.name && !user?.position && !user?.bio) return '';

    return `Current User Information:
Name: ${user?.name ? user.name : user?.first_name + ' ' + user?.last_name}
Role: ${user.position || 'Not provided'}
Background: ${user.bio || 'Not provided'}
Access Level: ${user.accessLevel || 'Standard'}`;
  }

  /**
   * Processes entire thread data, handling both individual posts and thread metadata
   */
  async processThreads(threads, config) {
    if (!threads?.length) return '';

    const {profiles, baseThreads, baseContent, rootUrl} = config;

    try {
      // Process threads in parallel for efficiency
      const processedThreads = await Promise.all(
        threads.map(async thread => {
          try {
            // Get full thread context including content and metadata
            const {threadData, promptData} =
              await generateAIDataforCurrentThread(
                thread,
                baseThreads,
                baseContent,
                profiles,
              );

            if (!promptData) {
              throw new Error(
                `Failed to generate data for workspace ${thread.id}`,
              );
            }

            // Build comprehensive thread context
            return `${promptData}
            ---`;
          } catch (error) {
            console.error(
              'Error processing individual workspace:',
              thread.id,
              error,
            );
            // Return fallback minimal thread context rather than failing completely
            return `
WORKSPACE: "${thread.title || 'Untitled'}"
STATUS: ${thread.status || 'unknown'}
TYPE: ${thread.type || 'unknown'}
SECURITY: ${thread.security_level || 'unknown'}
OWNER: ${this.formatUserName(thread.owner_id, profiles)}
URL: ${rootUrl}/threads/${thread.id}
ERROR: Failed to process full workspace content
---`;
          }
        }),
      );

      // Filter out any null results and join with clear separation
      return processedThreads.filter(Boolean).join('\n\n').trim();
    } catch (error) {
      console.error('Error in workspace processing:', error);
      throw new Error('Failed to process workspace data: ' + error.message);
    }
  }
  /**
   * Processes thread updates data, handling both individual posts and thread metadata
   */
  async processThreadUpdates(threadUpdates, config) {
    if (!threadUpdates?.length) return '';

    const {profiles, baseThreads, rootUrl} = config;

    const processedThreads = threadUpdates
      .map(content => {
        try {
          // Get base thread data
          const thread = baseThreads?.[content.thread_id];

          // Extract and clean content
          const extractedText = this.extractThreadContent(content.content);
          const author = this.formatUserName(content.owner_id, profiles);
          const timestamp = this.formatTimestamp(content.created);
          const sourceLink = `${rootUrl}/threads/${content.thread_id}/${content.id}`;

          return `WORKSPACE: "${thread?.title || 'Untitled'}"
STATUS: ${thread?.status || 'unknown'}
UPDATE: "${extractedText}"
AUTHOR: "${author}"
TIMESTAMP: ${timestamp}
SOURCE: ${sourceLink}
---`;
        } catch (error) {
          console.error('Error processing thread:', error);
          return null;
        }
      })
      .filter(Boolean);

    return processedThreads.join('\n\n');
  }

  /**
   * Processes files into a consistent format, handling different file types
   */
  async processFiles(files) {
    if (!files?.length) return '';

    try {
      const processedFiles = await Promise.all(
        files.map(async file => {
          // Handle file content based on type
          const obj = await this.getFileContent(file);
          return `FILE: ${obj.name}
CONTENT: ${obj?.text}
---`;
        }),
      );

      return processedFiles.join('\n\n');
    } catch (error) {
      console.error('Error processing files:', error);
      return 'ERROR PROCESSING FILES';
    }
  }

  /**
   * Processes any additional context provided in the config
   */
  processAdditionalContext(config) {
    const relevantKeys = ['currentTime', 'environment', 'customContext'];
    const contextParts = [];

    for (const key of relevantKeys) {
      if (config[key]) {
        contextParts.push(`${key.toUpperCase()}: ${config[key]}`);
      }
    }

    return contextParts.length ? contextParts.join('\n') : '';
  }

  /**
   * Helper method to safely extract text content from thread posts
   */
  extractThreadContent(content) {
    if (!content) return '';

    try {
      const parsed = JSON.parse(content);
      return parsed
        .map(block => {
          if (block.content?.[0]?.text) {
            return block.content[0].text;
          }
          return '';
        })
        .join(' ')
        .trim();
    } catch (error) {
      // Fallback to treating content as plain text
      return content.toString();
    }
  }

  /**
   * Helper method to format user names consistently
   */
  formatUserName(userId, profiles) {
    const profile = profiles?.[userId];
    if (!profile) return userId;
    return `${profile.first_name} ${profile.last_name}`.trim() || userId;
  }

  /**
   * Helper method to format timestamps consistently
   */
  formatTimestamp(timestamp) {
    if (!timestamp) return 'unknown';
    return new Date(timestamp * 1000).toLocaleString();
  }

  /**
   * Helper method to get file content with type-specific handling
   */
  async getFileContent(file) {
    // Add specific file type handling here
    let fileObject = await handleFileForClaude(file);
    return fileObject || 'Content not available';
  }
}

/**
 * Updated AIInteractionManager that uses the simplified context building
 */
class AIInteractionManager {
  constructor(userId = null, recordUsage) {
    this.contextBuilder = new ContextBuilder();
    this.userId = userId;
    this.responseHandler = null;
    this.isCancelled = false;
    this.cachedContext = null;
    this.lastContextHash = null;
    this.recordUsage = recordUsage;
  }

  /**
   * Creates a hash of the input parameters to detect changes
   */
  getContextHash(params) {
    const relevantData = {
      userId: params.user?.id,
      threadIds: params.threads?.map(t => `${t.id}-${t.updated}`),
      threadUpdateIds: params.threadUpdates?.map(t => `${t.id}-${t.updated}`),
      fileNames: params.files?.map(f => `${f.name}-${f.lastModified}`),
      configKeys: Object.keys(params.additionalConfig || {}).sort(),
    };
    return JSON.stringify(relevantData);
  }

  /**
   * Builds or retrieves cached context based on input parameters
   */
  async getContext({
    user,
    threads,
    threadUpdates,
    files,
    additionalConfig,
    outputTemplate,
  }) {
    const contextHash = this.getContextHash({
      user,
      threads,
      threadUpdates,
      files,
      additionalConfig,
    });

    // Return cached context if nothing has changed
    if (
      contextHash === this.lastContextHash &&
      (!outputTemplate || outputTemplate === this.lastTemplate)
    ) {
      return this.cachedContext;
    }

    // Build new context
    const {context} = await this.contextBuilder.buildContext({
      user,
      threads,
      threadUpdates,
      files,
      additionalConfig,
    });

    // Add template if required
    let fullContext = context;
    if (outputTemplate) {
      const template = outputTemplate?.template;
      if (!template) {
        throw new Error('Template not found');
      }
      fullContext += '\n\nOutput Template:\n' + template;
      this.lastTemplate = outputTemplate;
    }

    // Cache the new context
    this.cachedContext = fullContext;
    this.lastContextHash = contextHash;

    return fullContext;
  }

  /**
   * Main method for generating AI content with optimized context handling
   */
  async generateContent({
    interactionType,
    user,
    threads,
    threadUpdates,
    files,
    outputTemplate,
    chatHistory,
    additionalConfig = {},
    callbacks = {},
  }) {
    this.isCancelled = false;

    try {
      // Input validation
      if (!interactionType) {
        throw new Error('interactionType is required');
      }

      const interactionConfig = interactionType;
      if (!interactionConfig) {
        throw new Error(`Unknown interaction type: ${interactionType}`);
      }

      // Set up response handling
      this.responseHandler = new ResponseHandler(callbacks);

      // Get system prompt
      const systemPrompt = getSystemPrompt(
        interactionConfig.systemPromptCategory,
        interactionConfig.systemPromptType,
      );

      if (!systemPrompt) {
        throw new Error('Failed to get system prompt');
      }

      // Get context (cached or rebuilt if needed)
      const context = await this.getContext({
        user,
        threads,
        threadUpdates,
        files,
        additionalConfig,
        outputTemplate: interactionConfig.requiresTemplate
          ? outputTemplate
          : null,
      });

      let messages = [];
      if (!chatHistory) {
        messages = [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: `Data and Context:\n${context}`,
              },
            ],
          },
        ];
        // Specific to floating chat where data and context disapears since its never added to chatHistory
      } else if (chatHistory?.[0].role === 'assistant') {
        messages = [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: `Data and Context:\n${context}`,
              },
            ],
          },
          ...chatHistory,
        ];
      } else {
        messages = chatHistory;
      }

      // Select streaming function based on configuration
      const streamFn = interactionConfig.useComplexStreaming
        ? StreamAIMessageClaude
        : StreamAIMessageLlama;

      // Generate content
      await streamFn({
        messages,
        user_id: this.userId,
        action: interactionType?.action || 'default',
        system: systemPrompt,
        onUpdate: content => {
          if (this.isCancelled) {
            throw new Error('Operation cancelled');
          }
          this.responseHandler.handleUpdate(content);
        },
        onComplete: response => {
          let {inputUsage, outputUsage, fullResponse} = response;
          this.recordUsage(inputUsage);
          this.recordUsage(outputUsage);
          this.responseHandler.handleComplete();
        },
      });
    } catch (error) {
      this.responseHandler?.handleError(error);
      throw error;
    } finally {
      this.isCancelled = false;
      this.responseHandler = null;
    }
  }
  /**
   * Main method for generating AI Document
   */
  async generateInitialDocument({
    user,
    currentConfig,
    currentTemplate,
    additionalConfig = {},
    callbacks = {},
  }) {
    return this.generateContent({
      interactionType: AI_INTERACTION_TYPES.DOCUMENT_GENERATION,
      user,
      threads: currentConfig?.selectedThreads,
      files: currentConfig?.files,
      chatHistory: currentConfig?.chatHistory,
      message: currentConfig?.wizard_content
        ? `Extra Information from User: ${currentConfig?.wizard_content}`
        : null,
      outputTemplate: currentTemplate,
      action: AI_INTERACTION_TYPES.DOCUMENT_GENERATION.action,
      additionalConfig,
      callbacks: {
        onUpdate: content => callbacks.onUpdate?.(content),
        onComplete: () => callbacks.onComplete?.(),
        onError: error => {
          console.error('Error generating initial document:', error);
          callbacks.onError?.(error);
        },
      },
    });
  }
  /**
   * Main method for generating AI Document
   */
  async generateDocumentSectionContent({
    chatHistory,
    editor,
    activeSection,
    currentTemplate,
    additionalConfig = {},
    callbacks = {},
  }) {
    const currentSectionContent = this.getCurrentSectionContent(
      editor,
      activeSection,
    );
    const currentSectionText = extractTextFromBlocks(
      currentSectionContent,
    )?.text;
    const fullDocumentText = extractTextFromBlocks(editor?.document)?.text;
    const documentTemplate = currentTemplate?.template;

    // Create the context message with all necessary information
    const contextMessage = {
      role: 'user',
      content: [
        {
          type: 'text',
          text: `Current Section:\n${
            currentSectionText || 'No current section.'
          }\n\nEntire Document:\n${
            fullDocumentText || 'No current document.'
          }\n\nEntire Document Template:\n${
            documentTemplate || 'No template. Refer to Entire Document.'
          }`,
        },
      ],
    };

    // Create complete messages array with context
    const messages = [...chatHistory, contextMessage];

    // Get system prompt
    const systemPrompt = getSystemPrompt(
      AI_INTERACTION_TYPES.DOCUMENT_EDITING.systemPromptCategory,
      AI_INTERACTION_TYPES.DOCUMENT_EDITING.systemPromptType,
    );

    // Generate content
    await StreamAIMessageClaude({
      messages,
      action: AI_INTERACTION_TYPES.DOCUMENT_EDITING.action,
      user_id: this.userId,
      system: systemPrompt,
      onUpdate: content => {
        if (this.isCancelled) {
          throw new Error('Operation cancelled');
        }
        callbacks.onUpdate?.(content);
      },
      onComplete: response => {
        // Handle completion
        callbacks.onComplete?.(response);
      },
    });
  }

  getCurrentSectionContent(editor, activeSection) {
    if (!editor) return [];

    let currentSectionContent = [];
    let sectionIndex = 0;

    editor.document.forEach(block => {
      if (sectionIndex === activeSection) {
        currentSectionContent.push(block);
      }
      if (block.type === 'divider') {
        sectionIndex++;
      }
    });

    return currentSectionContent;
  }

  /**
   * Cancels the current operation and cleans up
   */
  cancel() {
    this.isCancelled = true;
    if (this.responseHandler) {
      this.responseHandler.handleComplete();
    }
  }

  /**
   * Clears the context cache
   */
  clearCache() {
    this.cachedContext = null;
    this.lastContextHash = null;
    this.lastTemplate = null;
  }
}

export {AI_INTERACTION_TYPES, AIInteractionManager};
