import {
  default as React,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FaCalendarAlt,
  FaChartLine,
  FaCity,
  FaExchangeAlt,
  FaGraduationCap,
  FaHandsHelping,
  FaLightbulb,
} from 'react-icons/fa';
import {GoRss} from 'react-icons/go';
import {useNavigate} from 'react-router-dom';
import {track} from '../../../api/analytics';
import constants from '../../../components/constants';
import {ProfileImage} from '../../../components/inputs/uploader';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {useAuth} from '../../../hooks/use-auth';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import {ThreadFeedItem} from '../components/thread-feed-item';
import './ChronologicalContent.css';
import './ThreadFeed.css';

const ThreadFeed = ({setCurrent, setIndex, setContent, disabled}) => {
  const navigate = useNavigate();
  const {
    state: {
      thread_search,
      threads,
      thread_token,
      thread_filter,
      threads_search_loaded,
    },
    searchThreads,
  } = useThreads();

  const {
    state: {profiles, id: currentUserId},
  } = useAuth();
  const currentUser = profiles?.[currentUserId];

  const [loading, setLoading] = useState(false);
  const loaderRef = useRef(null);

  const search = async (filter, options) => {
    if (loading) return {success: false};
    setLoading(true);
    const {success, error, data} = await searchThreads(filter, options);
    setLoading(false);
    track('discover_feed_search', {source: 'threads', filter, options});
    return {success, error, data};
  };

  const handleCreateThread = () => {
    navigate('/threads/create');
    track('create_thread_click', {source: 'thread_feed'});
  };

  const handleUpgradeLimit = () => {
    window.location.href =
      "mailto:help@publicmind.ai?subject=Thread%20Limit%20Upgrade&body=Hello,%20I'd%20like%20to%20upgrade%20my%20account%20to%20create%20more%20threads.";
    track('upgrade_limit_click', {source: 'thread_feed'});
  };

  // Intersection Observer callback
  const handleObserver = useCallback(
    entries => {
      const target = entries[0];
      if (target.isIntersecting && thread_token && !loading) {
        const options = {
          limit: constants.org_search_limit,
          nextToken: thread_token,
        };
        search(thread_filter, options);
      }
    },
    [thread_token, loading, thread_filter],
  );

  // Set up the Intersection Observer
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [handleObserver]);

  // Initial load
  useEffect(() => {
    if (!threads_search_loaded) {
      search(thread_filter, {
        limit: constants.org_search_limit,
        nextToken: thread_token,
      });
    }
  }, [threads_search_loaded]);

  return (
    <div className="mentions-container">
      <div className="since-last-time-header">
        <h3 className="since-last-time-title">Discover Workspaces</h3>
        <p className="text-secondary">
          Growing list of all public and private workspaces on PublicMind
        </p>
      </div>

      {thread_search.length ? (
        <div className="mentions-list">
          <CreateThreadCard
            disabled={disabled}
            onCreateThread={handleCreateThread}
            onUpgradeLimit={handleUpgradeLimit}
            currentUser={currentUser}
          />
          {thread_search.map(id => {
            const item = threads?.[id] ?? {};
            return (
              <ThreadFeedItem
                key={id}
                item={item}
                setCurrent={setCurrent}
                setContent={setContent}
                setIndex={setIndex}
              />
            );
          })}
          {thread_token && (
            <div ref={loaderRef} className="flex justify-center padding-16">
              {loading && <SpinningIndicator />}
            </div>
          )}
        </div>
      ) : loading ? (
        <SpinningIndicator />
      ) : (
        <div className="mentions-empty">
          <GoRss className="mentions-empty-icon" />
          <p>No threads yet</p>
          <p>When threads are created, you'll see them here</p>
        </div>
      )}
    </div>
  );
};

const useCases = [
  {
    title: 'Statewide Information Exchange',
    description:
      'Enable secure information sharing between state agencies, educational institutions, and workforce partners.',
    tags: [
      'Government Agencies',
      'K12 Districts',
      'Technical Schools',
      'Workforce Development',
      'Public-Private Partnerships',
    ],
    icon: <FaExchangeAlt className="padding-left8" />,
  },
  {
    title: 'Economic Development Initiatives',
    description:
      'Connect economic development organizations, businesses, and educational institutions to drive regional growth.',
    tags: [
      'Economic Development',
      'Business Expansion',
      'Economic Development Agency',
      'Community Service Projects',
      'Industry Advisory Boards',
    ],
    icon: <FaCity className="padding-left8" />,
  },
  {
    title: 'Workforce Event Management',
    description:
      'Coordinate career fairs, training programs and workforce initiatives between agencies, schools and employers.',
    tags: [
      'Career Fairs and Networking Events',
      'Workforce Development',
      'Professional Development',
      'Job Placement',
      'Skills Training Partnerships',
    ],
    icon: <FaCalendarAlt className="padding-left8" />,
  },
  {
    title: 'Education Partnership Hub',
    description:
      'Foster collaboration between K-12, technical colleges, and universities to create student success pathways.',
    tags: [
      'K12 Districts',
      'Technical Schools',
      'Four Year Universities',
      'Educational Development',
      'Research Partnerships',
    ],
    icon: <FaGraduationCap className="padding-left8" />,
  },
  {
    title: 'Regional Innovation Network',
    description:
      'Build connections between research institutions, industry, and government to drive innovation and economic growth.',
    tags: [
      'Research Partnerships',
      'Technology Transfer',
      'Innovation Labs',
      'Joint R&D Initiatives',
      'Public-Private Partnerships',
    ],
    icon: <FaLightbulb className="padding-left8" />,
  },
  {
    title: 'Community Development Coordination',
    description:
      'Streamline collaboration between government agencies, nonprofits, and community organizations.',
    tags: [
      'Community Engagement',
      'Non-Profit Organizations',
      'Government Agencies',
      'Social Responsibility Initiatives',
      'Community Service Projects',
    ],
    icon: <FaHandsHelping className="padding-left8" />,
  },
  {
    title: 'Grant Management Platform',
    description:
      'Centralize grant coordination, track outcomes, and maintain partner communications.',
    tags: [
      'Public-Private Partnerships',
      'Economic Development',
      'Research Partnerships',
      'International Collaboration Programs',
      'Non-Profit Organizations',
    ],
    icon: <FaChartLine className="padding-left8" />,
  },
];
export const CreateThreadCard = ({
  disabled,
  onCreateThread,
  onUpgradeLimit,
  currentUser,
}) => {
  const {prettyName} = useStringFormatter();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsChanging(true);
      setTimeout(() => {
        setCurrentIndex(prev => (prev + 1) % useCases.length);
        setIsChanging(false);
      }, 750);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const currentCase = useCases[currentIndex];

  return (
    <div className="padding-h16 ">
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; transform: translateY(10px); }
            to { opacity: 1; transform: translateY(0); }
          }
            
          .animated-content {
            animation: ${isChanging ? 'fadeOut' : 'fadeIn'} 0.75s ease forwards;
          }

          .rotating-tag {
            transition: all 0.3s ease;
            transform-origin: center;
          }

          .rotating-tag:hover {
            transform: scale(1.05);
            background: linear-gradient(45deg, var(--primary-button), var(--secondary-button));
            color: white;
          }
        `}
      </style>

      <div
        className="feed-item"
        style={{
          border: '2px solid transparent',
          backgroundClip: 'padding-box, border-box',
          borderImage:
            'linear-gradient(45deg, var(--primary-button), var(--secondary-button), var(--red)) 1',
          transition: 'all 0.3s ease',
          padding: '24px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        }}>
        <div className="flex-row align-end padding-bottom16">
          {disabled ? (
            <h2>
              Oh no! You've used up the{' '}
              <span className="use-cases-highlight">free tier!</span>
            </h2>
          ) : (
            <h2>
              What will you{' '}
              <span className="use-cases-highlight">accomplish</span> on
              PublicMind?
            </h2>
          )}
          <p className="text-secondary padding-left8">
            {disabled
              ? 'Getting more is easy, request an upgrade below'
              : 'A common communication channel for projects'}
          </p>
        </div>
        <div className="feed-item">
          <div className="feed-item__header ">
            <div className="feed-item__owner-info">
              <ProfileImage
                data={currentUser?.profile_image}
                style={{
                  height: '36px',
                  width: '36px',
                  border: '2px solid var(--primary-button)',
                }}
              />
              <div className="feed-item__owner-details animated-content">
                <p
                  className="feed-item__owner-name "
                  style={{fontSize: '18px', fontWeight: '600'}}>
                  <span className="flex-row align-center">
                    {currentCase.title} {currentCase.icon}
                  </span>
                </p>
                <p className="feed-item__owner-position">
                  {prettyName(currentUser) || 'Creator'}, Example
                  Workspace
                </p>
              </div>
            </div>

            <div className="feed-item__actions">
              {!disabled ? (
                <div className="button-gradient-wrapper">
                  <button
                    className="create-thread-button"
                    onClick={onCreateThread}>
                    Create Workspace
                  </button>
                </div>
              ) : (
                <div className="button-gradient-wrapper">
                  <button
                    className="create-thread-button"
                    onClick={onUpgradeLimit}>
                    Request Upgrade
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="feed-item__title-group animated-content">
            <p className="feed-item__content">{currentCase.description}</p>
          </div>

          <div className="feed-item__tags animated-content">
            {currentCase.tags.map((tag, index) => (
              <span key={tag} className="feed-item__tag rotating-tag">
                {tag}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreadFeed;
