import JSZip from 'jszip';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {MdOutlineWorkspaces} from 'react-icons/md';
import {
  RiAddLine,
  RiCheckLine,
  RiCloseLine,
  RiFileTextLine,
  RiGroupLine,
  RiLayoutGridLine,
  RiLinkM,
  RiUploadLine,
} from 'react-icons/ri';
import {RxArrowUp, RxCheck, RxFile, RxInfoCircled} from 'react-icons/rx';
import {isFileValidForClaude} from '../../../../api/ai';
import constants from '../../../../components/constants';
import {TooltipNoShadow} from '../../../../components/tooltip/tooltip';
import {
  calculateOperationCost,
  USAGE_LIMITS,
} from '../../../../contexts/ai-context';
import {useAI} from '../../../../hooks/use-ai';
import {useAuth} from '../../../../hooks/use-auth';
import {useThreads} from '../../../../hooks/use-threads';
import {AI_INTERACTION_TYPES} from '../../../../manager/promptSystem';
import {countTokens} from '../../../../utils/utils';
import {TEMPLATE_OPTIONS} from '../assistant-modal';
import './ChatControlPanel.css';
import './MiniWorkspaceGuide.css';

const getSelectorTitle = selector => {
  switch (selector) {
    case 'thread':
      return 'Select Workspaces';
    case 'file':
      return 'Upload File';
    case 'template':
      return 'Select Template';
    default:
      return '';
  }
};

export const ChatControlPanel = ({
  onSendMessage,
  currentConfig,
  onThreadSelect,
  setFiles,
  showWizard,
  onLaunchWizard,
  onTemplateSelect,
  currentTemplate,
  isStreaming,
  isLoading,
  thread_data,
}) => {
  const {files, selectedThreads, generated, tokens} = currentConfig || {};
  const {thread} = thread_data || {};
  const [message, setMessage] = useState('');
  const [isOverLimit, setIsOverLimit] = useState(false);
  const [willBeOverLimit, setWillBeOverLimit] = useState(false);

  const [activeSelector, setActiveSelector] = useState(null);
  const selectorRef = useRef(null);

  const [showAttachments, setShowAttachments] = useState(false);
  const attachmentsRef = useRef(null);
  const {
    state: {id},
  } = useAuth();

  const {state, getAvailableTokens, getUsageStats} = useAI();

  useEffect(() => {
    // Get current usage statistics from context
    const {success, daily, monthly} = getUsageStats(id)(state);

    if (!success) {
      console.error('Failed to get usage stats');
      return;
    }

    // Estimate tokens and costs for the current message
    const messageTokenEstimate = countTokens(message);

    // Calculate input cost using the document generation model
    const estimatedInputCost = calculateOperationCost(
      messageTokenEstimate,
      AI_INTERACTION_TYPES.DOCUMENT_GENERATION.model,
      'input',
    );

    // Estimate output tokens - typically 2-3x input for document generation
    const estimatedOutputTokens = messageTokenEstimate * 2.5;
    const estimatedOutputCost = calculateOperationCost(
      estimatedOutputTokens,
      AI_INTERACTION_TYPES.DOCUMENT_GENERATION.model,
      'output',
    );

    // Calculate existing context costs
    const contextInputCost = calculateOperationCost(
      currentConfig.tokens || 0,
      AI_INTERACTION_TYPES.DOCUMENT_GENERATION.model,
      'input',
    );

    // Calculate total estimated cost for this operation
    const totalEstimatedCost =
      estimatedInputCost + estimatedOutputCost + contextInputCost;

    // Determine if we're over limits
    const totalProjectedCost = daily.spent + totalEstimatedCost;
    const isCurrentlyOverLimit = daily.available <= 0;
    const wouldExceedLimit = totalProjectedCost > USAGE_LIMITS.DAILY.amount;

    // Update state based on our calculations
    setIsOverLimit(isCurrentlyOverLimit);
    setWillBeOverLimit(wouldExceedLimit);
    console.debug('Usage projections:', {
      currentSpent: daily.spent,
      estimatedNewCost: totalEstimatedCost,
      projectedTotal: totalProjectedCost,
      dailyLimit: USAGE_LIMITS.DAILY.amount,
      available: daily.available,
    });
  }, [message, currentConfig, id, state, getUsageStats]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setActiveSelector(null);
      }
      if (
        attachmentsRef.current &&
        !attachmentsRef.current.contains(event.target)
      ) {
        setShowAttachments(false);
      }
    };

    // Add event listener when a selector is open
    if (activeSelector) {
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }
    if (attachmentsRef) {
      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [activeSelector, attachmentsRef]);

  useEffect(() => {
    const totalItems = (selectedThreads?.length || 0) + (files?.length || 0);
    if (totalItems === 0) setShowAttachments(false);
  }, [selectedThreads, files]);

  const toggleSelector = selectorName => {
    setActiveSelector(activeSelector === selectorName ? null : selectorName);
  };

  const renderAttachmentsPreview = () => {
    const totalItems = (selectedThreads?.length || 0) + (files?.length || 0);
    if (totalItems === 0) return null;

    const previewItems = [...(selectedThreads || []), ...(files || [])].slice(
      0,
      3,
    );
    const invalidFile = files.some(x => {
      const {isValid, message} = isFileValidForClaude(x);
      return !isValid;
    });

    return (
      <div
        className="chat-panel-attachments-preview"
        onClick={e => {
          setShowAttachments(!showAttachments);
        }}>
        <div className="chat-panel-attachments-count">
          {totalItems}{' '}
          {selectedThreads?.length > 0 && files?.length > 0
            ? 'item'
            : selectedThreads?.length > 0
            ? 'workspace'
            : 'file'}
          {totalItems !== 1 ? 's' : ''} attached
        </div>
        {previewItems.map((item, index) => (
          <span
            key={item.id || item.name}
            className="chat-panel-attachment-icon">
            {item.status ? (
              item.status === 'draft' ? (
                <RxFile />
              ) : item.status === 'active' ? (
                <RiGroupLine />
              ) : (
                <RxCheck />
              )
            ) : (
              <RiFileTextLine />
            )}
          </span>
        ))}
        {totalItems > 3 && (
          <span className="chat-panel-attachments-toggle">
            +{totalItems - 3} more
          </span>
        )}
        {invalidFile && (
          <span className="chat-panel-attachments-count text-red">
            ! Invalid file
          </span>
        )}
      </div>
    );
  };

  const renderTemplateOrWizard = () => {
    if (currentTemplate) {
      return (
        <button
          type="button"
          className="chat-panel-template"
          onClick={() =>
            setActiveSelector(prev => {
              return prev === 'template' ? null : 'template';
            })
          }>
          <div className="chat-panel-template-icon">
            {currentTemplate.icon || <RiFileTextLine />}
          </div>
          <div className="chat-panel-template-content">
            <span className="chat-panel-template-label">
              {currentTemplate?.label}
            </span>
            <span className="chat-panel-template-type">Template</span>
          </div>
        </button>
      );
    }
    // else if (showWizard) {
    //   // If no template is selected but wizard is available, show the wizard button
    //   return (
    //     <button
    //       type="button"
    //       onClick={onLaunchWizard}
    //       className="chat-panel-wizard">
    //       Setup Wizard
    //     </button>
    //   );
    // }
    return null;
  };

  const renderCurrentThread = () => {
    if (thread) {
      return (
        <TooltipNoShadow
          text={'Loaded workspace. Restart document generator to remove'}>
          <button type="button" className="chat-panel-template">
            <div className="chat-panel-template-icon">
              {thread?.logo || <RiLinkM size={24} />}
            </div>
            <div className="chat-panel-template-content">
              <span className="chat-panel-template-label">{thread?.title}</span>
              <span className="chat-panel-template-type">Workspace</span>
            </div>
          </button>
        </TooltipNoShadow>
      );
    }

    return null;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (message.trim()) {
      onSendMessage(message);
      setMessage('');
      setActiveSelector(null);
      setShowAttachments(false);
    }
  };

  // Updated to handle full thread array
  const handleRemoveThread = (e, threadId) => {
    e.stopPropagation();
    onThreadSelect(selectedThreads.filter(t => t.id !== threadId));
  };

  // Updated to handle full files array
  const handleRemoveFile = (e, fileToRemove) => {
    e.stopPropagation();
    setFiles(files.filter(f => f.name !== fileToRemove.name));
  };

  // Modified to handle thread selection properly
  const handleThreadSelect = threads => {
    onThreadSelect(threads);
  };

  // Modified to handle file selection properly
  const handleFileSelect = newFiles => {
    setFiles(newFiles);
  };

  const handleTemplateSelect = template => {
    onTemplateSelect(template);
    setActiveSelector(null);
  };

  const contextInfo = useMemo(() => {
    const WARNING_THRESHOLD = 100;
    const MAX_TOKENS = constants.max_knowledge_capacity_tokens;

    // Calculate percentage of token usage
    const tokenPercentage = (tokens / MAX_TOKENS) * 100;

    return {
      threadCount: selectedThreads.length,
      fileCount: files.length,
      tokens: tokens,
      maxTokens: MAX_TOKENS,
      tokenPercentage: tokenPercentage,
      hasContext: selectedThreads.length > 0 || files.length > 0,
      isNearLimit: tokenPercentage > WARNING_THRESHOLD,
      formattedTokens: `${Math.round(tokens).toLocaleString()} / ${Math.round(
        MAX_TOKENS,
      ).toLocaleString()}`,
    };
  }, [selectedThreads, files, tokens]);

  return (
    <div
      className={`chat-panel ${isStreaming || isLoading ? 'isStreaming' : ''}`}>
      {renderAttachmentsPreview()}
      {showAttachments && (
        <div
          ref={attachmentsRef}
          className={`chat-panel-attachments ${
            showAttachments ? 'expanded' : ''
          }`}>
          <div className="chat-panel-attachments-grid">
            {selectedThreads.map(thread => (
              <div key={thread.id} className="chat-panel-attachment">
                {thread.status === 'draft' ? (
                  <RxFile className="chat-panel-attachment-icon" />
                ) : thread.status === 'active' ? (
                  <RiGroupLine className="chat-panel-attachment-icon" />
                ) : (
                  <RxCheck className="chat-panel-attachment-icon" />
                )}
                <span className="chat-panel-attachment-name">
                  {thread.title}
                </span>
                <button
                  className="chat-panel-attachment-remove"
                  onClick={e => handleRemoveThread(e, thread.id)}>
                  <RiCloseLine />
                </button>
              </div>
            ))}
            {files.map(file => {
              const {isValid, message} = isFileValidForClaude(file);
              return (
                <div
                  key={file.name}
                  className={`chat-panel-attachment ${
                    !isValid ? 'invalid' : ''
                  }`}>
                  <RiFileTextLine className="chat-panel-attachment-icon" />
                  <span className="chat-panel-attachment-name">
                    {file.name}
                  </span>
                  {!isValid && (
                    <span className="chat-panel-attachment-error">
                      {message}
                    </span>
                  )}
                  <button
                    className="chat-panel-attachment-remove"
                    onClick={e => handleRemoveFile(e, file)}>
                    <RiCloseLine />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <form
        onSubmit={handleSubmit}
        onKeyDown={e => {
          if (onSendMessage && e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            if (
              message?.length !== 0 &&
              tokens < constants.max_knowledge_capacity_tokens &&
              !isOverLimit
            ) {
              onSendMessage(message);
              setActiveSelector(null);
              setShowAttachments(false);
            }
          }
        }}
        className="chat-panel-form">
        <div className="chat-panel-input-container">
          {isOverLimit && (
            <p className="context-warning">
              Over daily usage limit. Reach out to us{' '}
              <a href="mailto:help@publicmind.ai">here</a> to increase your
              limit.
            </p>
          )}
          {willBeOverLimit && !isOverLimit && (
            <p className="context-warning">
              This message would exceed the available limit. Please shorten your
              message or remove some things from memory.
            </p>
          )}
          {contextInfo.tokenPercentage > 2 && (
            <div>
              <div className="context-size-label">
                <span>
                  {Math.min(contextInfo.tokenPercentage, 100).toFixed(0)}% of
                  memory used
                </span>
                <span className={contextInfo.isNearLimit ? 'near-limit' : ''}>
                  <TooltipNoShadow
                    text={'PublicMind has a memory limit of about 75 pages.'}>
                    <RxInfoCircled size={12} />
                  </TooltipNoShadow>
                  {/* {contextInfo.formattedTokens} */}
                </span>
              </div>
              <div className="context-progress">
                <div
                  className={`context-progress-fill ${
                    contextInfo.isNearLimit ? 'near-limit' : ''
                  }`}
                  style={{
                    width: `${Math.min(contextInfo.tokenPercentage, 100)}%`,
                  }}
                />
              </div>
              {contextInfo.isNearLimit && (
                <p className="context-warning">
                  Over limit. Remove files or threads to continue.
                </p>
              )}
              {tokens > constants.max_knowledge_capacity_tokens * 0.8 &&
                tokens < constants.max_knowledge_capacity_tokens && (
                  <p className="text-12 text-orange">
                    You are approaching the memory limit for this section
                  </p>
                )}
            </div>
          )}
          <div className="chat-panel-actions">
            <button
              type="button"
              className={`chat-panel-action-btn ${
                activeSelector === 'template' ? 'active' : ''
              }`}
              onClick={() => toggleSelector('template')}>
              <RiLayoutGridLine />
              <span>Select template</span>
            </button>
            <button
              type="button"
              className={`chat-panel-action-btn ${
                activeSelector === 'thread' ? 'active' : ''
              }`}
              onClick={() => toggleSelector('thread')}>
              <MdOutlineWorkspaces />
              <span>Add workspaces</span>
            </button>
            <button
              type="button"
              className={`chat-panel-action-btn ${
                activeSelector === 'file' ? 'active' : ''
              }`}
              onClick={() => toggleSelector('file')}>
              <RiAddLine />
              <span>Add files</span>
            </button>
          </div>
          <span className="chat-text-submit-container">
            <textarea
              value={message}
              onChange={e => setMessage(e.target.value)}
              placeholder={
                generated
                  ? 'Edit the document by clicking in the document or tell me what to change...'
                  : 'What should I create for you?'
              }
              className="chat-panel-input"
              rows={1}
            />
            {message?.length > 0 &&
              tokens < constants.max_knowledge_capacity_tokens && (
                <button
                  type="submit"
                  disabled={
                    message?.length === 0 || willBeOverLimit || isOverLimit
                  }
                  className="chat-panel-submit">
                  <RxArrowUp size={20} />
                </button>
              )}
          </span>
          <span className="flex-row">
            <div className="chat-panel-buttons">{renderTemplateOrWizard()}</div>
            <span className="margin-h4" />
            <div className="chat-panel-buttons">{renderCurrentThread()}</div>
          </span>
        </div>
      </form>

      {activeSelector && (
        <div ref={selectorRef} className="chat-panel-selector">
          <div className="chat-panel-selector-header">
            <h3>{getSelectorTitle(activeSelector)}</h3>
            <button onClick={() => setActiveSelector(null)}>
              <RiCloseLine />
            </button>
          </div>

          {activeSelector === 'thread' && (
            <MiniThreadSelector
              selectedThreads={currentConfig.selectedThreads}
              onThreadSelect={handleThreadSelect}
            />
          )}

          {activeSelector === 'file' && (
            <MiniFileUploader
              files={currentConfig.files}
              onFileSelect={handleFileSelect}
            />
          )}

          {activeSelector === 'template' && (
            <MiniTemplateSelector
              selectedTemplate={currentTemplate}
              onTemplateSelect={handleTemplateSelect}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ChatControlPanel;

const MiniThreadSelector = ({selectedThreads = [], onThreadSelect}) => {
  const {
    state: {id},
  } = useAuth();
  const {
    state: {threads},
  } = useThreads();
  const [searchQuery, setSearchQuery] = useState('');

  const filteredThreads = Object.values(threads)
    .filter(
      thread =>
        thread?.permissions?.find(x => x.user_id === id) &&
        thread?.status !== 'archived' &&
        (!searchQuery ||
          thread.title.toLowerCase().includes(searchQuery.toLowerCase())),
    )
    .sort((a, b) => b.updated - a.updated);

  const canAddMore = selectedThreads.length < MAX_ITEMS;

  const handleThreadSelect = thread => {
    const isAlreadySelected = selectedThreads.some(t => t.id === thread.id);

    if (isAlreadySelected) {
      const updatedThreads = selectedThreads.filter(t => t.id !== thread.id);
      onThreadSelect(updatedThreads);
    } else if (canAddMore) {
      const updatedThreads = [...selectedThreads, thread];
      onThreadSelect(updatedThreads);
    }
  };

  return (
    <div className="mini-selector">
      <div className="mini-selector-header">
        <div className="mini-selector-count">
          {selectedThreads.length}/{MAX_ITEMS} workspaces selected
        </div>
        <input
          type="search"
          placeholder="Search workspaces..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          className="mini-selector-search"
        />
      </div>

      <div className="mini-selector-list">
        {filteredThreads.map(thread => {
          const isSelected = selectedThreads.some(t => t.id === thread.id);

          return (
            <button
              key={thread.id}
              className={`mini-selector-item ${isSelected ? 'selected' : ''}`}
              onClick={() => handleThreadSelect(thread)}
              disabled={!canAddMore && !isSelected}>
              {thread.status === 'draft' ? (
                <RxFile />
              ) : thread.status === 'active' ? (
                <RiGroupLine />
              ) : (
                <RxCheck />
              )}
              {thread.custom_logo && (
                <img
                  src={thread.custom_logo.url}
                  alt=""
                  className="mini-selector-logo"
                />
              )}
              <span className="mini-selector-title" title={thread.title}>
                {thread?.logo ? thread?.logo + ' ' : null}
                {thread.title}

                <span className={`mini-selector-status`}>
                  (
                  {thread.status === 'complete'
                    ? 'Final post only'
                    : 'All posts used'}
                  )
                </span>
              </span>
              {isSelected && <RiCheckLine className="mini-selector-check" />}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const MAX_ITEMS = 10;
const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const MiniFileUploader = ({files = [], onFileSelect}) => {
  const fileInputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [processingFolder, setProcessingFolder] = useState(false);
  const canAddMore = files?.length < MAX_ITEMS;

  const processCompressedFile = async file => {
    const zip = new JSZip();
    try {
      const zipContents = await zip.loadAsync(file);
      const extractedFiles = [];

      for (const [path, zipEntry] of Object.entries(zipContents.files)) {
        // Skip macOS system files and directories
        if (
          zipEntry.dir ||
          path.startsWith('__MACOSX/') ||
          path.startsWith('._') ||
          path.includes('/.')
        ) {
          continue;
        }

        const content = await zipEntry.async('blob');
        const extractedFile = new File([content], path.split('/').pop(), {
          type: content.type || 'application/octet-stream',
        });

        if (extractedFile.size <= MAX_FILE_SIZE) {
          extractedFiles.push(extractedFile);
        }
      }

      return extractedFiles;
    } catch (error) {
      console.error('Error processing compressed file:', error);
      return [];
    }
  };

  const processEntry = async entry => {
    if (entry.isFile) {
      return new Promise(resolve => {
        entry.file(async file => {
          if (file.type.includes('zip') || file.type.includes('compressed')) {
            const extractedFiles = await processCompressedFile(file);
            resolve(extractedFiles);
          } else if (file.size <= MAX_FILE_SIZE) {
            resolve(file);
          } else {
            resolve(null);
          }
        });
      });
    } else if (entry.isDirectory) {
      const dirReader = entry.createReader();
      const entries = await new Promise(resolve => {
        dirReader.readEntries(resolve);
      });

      const files = await Promise.all(
        entries.map(entry => processEntry(entry)),
      );
      return files.flat().filter(Boolean);
    }
    return null;
  };

  const handleFiles = async newFiles => {
    const remainingSlots = MAX_ITEMS - files?.length;
    if (remainingSlots <= 0) return;

    const processedFiles = [];
    for (const file of Array.from(newFiles)) {
      if (file.type.includes('zip') || file.type.includes('compressed')) {
        const extractedFiles = await processCompressedFile(file);
        processedFiles.push(...extractedFiles);
      } else if (file.size <= MAX_FILE_SIZE) {
        processedFiles.push(file);
      }
    }

    // Only take up to the remaining slots
    const validFiles = processedFiles.slice(0, remainingSlots);
    if (validFiles?.length > 0) {
      onFileSelect([...files, ...validFiles]);
    }
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(e.type === 'dragenter' || e.type === 'dragover');
  };

  const handleDrop = async e => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const items = Array.from(e.dataTransfer.items);
    if (items.length > 0) {
      setProcessingFolder(true);
      const entries = items
        .map(item => (item.webkitGetAsEntry ? item.webkitGetAsEntry() : null))
        .filter(Boolean);

      const processedFiles = await Promise.all(
        entries.map(entry => processEntry(entry)),
      );
      const flattenedFiles = processedFiles.flat().filter(Boolean);

      handleFiles(flattenedFiles);
      setProcessingFolder(false);
    } else {
      handleFiles(e.dataTransfer.files);
    }
  };

  return (
    <div className="mini-selector">
      <div className="mini-selector-header">
        <div className="mini-selector-count">
          {files?.length}/{MAX_ITEMS} files selected
        </div>
        <div className="mini-selector-constraints">Max 10MB per file</div>
      </div>
      <div
        className={`mini-selector-dropzone ${dragActive ? 'active' : ''}`}
        onClick={() => canAddMore && fileInputRef.current?.click()}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}>
        <RiUploadLine
          className={`mini-selector-icon ${processingFolder ? 'spinning' : ''}`}
        />
        <div className="mini-selector-dropzone-text">
          <span>
            {processingFolder
              ? 'Processing folder...'
              : canAddMore
              ? 'Choose files or drag & drop'
              : 'Maximum files reached'}
          </span>
        </div>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        multiple
        onChange={e => {
          handleFiles(e.target.files);
          e.target.value = null;
        }}
        className="mini-selector-input"
        style={{display: 'none'}}
      />
    </div>
  );
};

const MiniTemplateSelector = ({selectedTemplate, onTemplateSelect}) => {
  const [activeSection, setActiveSection] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');

  const sections = [
    {
      id: 'all',
      label: 'All',
      options: Object.values(TEMPLATE_OPTIONS).flat(),
    },
    {
      id: 'summary',
      label: 'Summaries',
      options: TEMPLATE_OPTIONS.SUMMARY,
    },
    {
      id: 'email',
      label: 'Emails',
      options: TEMPLATE_OPTIONS.EMAIL,
    },
    {
      id: 'presentation',
      label: 'Presentations',
      options: TEMPLATE_OPTIONS.PRESENTATION,
    },
    {
      id: 'completed',
      label: 'Project Completion',
      options: TEMPLATE_OPTIONS.COMPLETED_PROJECT,
    },
    {
      id: 'live',
      label: 'Status Reports',
      options: TEMPLATE_OPTIONS.LIVE_PROJECT,
    },
    {
      id: 'draft',
      label: 'Project Planning',
      options: TEMPLATE_OPTIONS.DRAFT_PROJECT,
    },
  ];
  const currentOptions =
    sections.find(s => s.id === activeSection)?.options || [];

  const filteredTemplates = currentOptions.filter(
    template =>
      !searchQuery ||
      template.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
      template.description.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <div className="mini-selector">
      <div className="mini-selector-header">
        <div className="mini-selector-count">
          {selectedTemplate ? '1/1 template selected' : '0/1 template selected'}
        </div>
        <input
          type="search"
          placeholder="Search templates..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          className="mini-selector-search"
        />
      </div>

      <div className="template-sections">
        {sections.map(section => (
          <button
            key={section.id}
            className={`template-section-tab ${
              activeSection === section.id ? 'active' : ''
            }`}
            onClick={() => setActiveSection(section.id)}>
            <p className="text-center">{section.label}</p>
          </button>
        ))}
      </div>

      <div className="mini-selector-list">
        {filteredTemplates.map(template => {
          const isSelected = selectedTemplate?.label === template.label;

          return (
            <button
              key={template.label}
              className={`mini-selector-item ${isSelected ? 'selected' : ''}`}
              onClick={() => onTemplateSelect(isSelected ? null : template)}>
              <div className="mini-selector-icon">{template.icon}</div>
              <div className="mini-selector-content">
                <span className="mini-selector-title">{template.label}</span>
                <span className="mini-selector-description">
                  {template.description}
                </span>
              </div>
              {isSelected && <RiCheckLine className="mini-selector-check" />}
            </button>
          );
        })}
        {filteredTemplates?.length === 0 && (
          <p className="text-center text-secondary padding-bottom8">
            No matching templates
          </p>
        )}
      </div>
    </div>
  );
};
