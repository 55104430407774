import React from 'react';
import {FaConnectdevelop} from 'react-icons/fa';
import './AILoading.css';

const AILoadingOverlay = ({}) => {
  return (
    <div className="ai-assistant-loading">
      <div className="ai-loading-container">
        <div className={`ai-insights-brain-icon-large ${'processing'}`}>
          <FaConnectdevelop />
        </div>
      </div>
    </div>
  );
};

export default AILoadingOverlay;
