import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {ImBlocked} from 'react-icons/im';

import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {BiPoll} from 'react-icons/bi';
import {GoGitBranch} from 'react-icons/go';
import {MdLabelImportantOutline} from 'react-icons/md';
import {RiPushpinFill} from 'react-icons/ri';
import {
  RxActivityLog,
  RxCheckbox,
  RxCopy,
  RxDashboard,
  RxDoubleArrowDown,
  RxDoubleArrowUp,
  RxDragHandleDots2,
  RxEnter,
  RxFile,
  RxLayers,
  RxLockClosed,
  RxLockOpen1,
  RxLockOpen2,
  RxPencil1,
  RxPerson,
  RxQuestionMarkCircled,
  RxSewingPinFilled,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import {track} from '../../../api/analytics';
import {
  dateToTimestamp,
  formatMMDDYYYY,
  isSameDay,
  timeSince,
} from '../../../api/dates';
import ActionPopup from '../../../components/action-feedback-popup/action-feedback-popup';
import {Badge} from '../../../components/badge/badge';
import constants from '../../../components/constants';
import {OptionsDropdown} from '../../../components/inputs/drop-down';
import {Textfield} from '../../../components/inputs/textfields';
import {ProfileImage} from '../../../components/inputs/uploader';
import VerticalMenuBar from '../../../components/layout/vertical-menu-bar';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {Modal} from '../../../components/modal/modal';
import {
  EditThreadModal,
  EditThreadPermissionsModal,
} from '../../../components/modal/thread-modals';
import {MemberActivityTooltip} from '../../../components/tooltip/member-activity-tooltip';
import {InvertedTooltipNoShadow} from '../../../components/tooltip/tooltip';
import {useAuth} from '../../../hooks/use-auth';
import useCollaborator from '../../../hooks/use-collaborator';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import useUtilities from '../../../hooks/use-utilities';
import {ContentDisplay} from '../components/content-display';
import {BreakoutComment} from '../components/thread-comment';
import {ThreadFeedItem} from '../components/thread-feed-item';
import {ThreadHierarchy} from '../components/thread-hierarchy';

import {HiOutlineDocumentText} from 'react-icons/hi';
import Confetti from '../../../components/confetti/confetti';
import {ActiveThreadModal} from '../../../components/modal/thread-status-modals';
import {isProUser} from '../../../components/system-status/system-status';
import FloatingAssistantIcon from '../components/ai-floating-assistant';
import AIChatAssistant from '../components/ai-floating-chat';
import {
  CompletedStatusButton,
  DraftStatusButton,
  LiveStatusButton,
} from '../components/thread-lifecycle-buttons';
import ThreadMessageGroup from '../components/thread-message-group';
import {CreateComment} from '../inputs/create-comment-inputs';
import {ReplyToComment} from '../inputs/create-reply-inputs';
import {AssistantModal} from '../modals/assistant-modal';
import {ThreadInfoPopup} from '../modals/ThreadInfoPopup';
import '../overview-tabs/ThreadFeed.css';
import '../ThreadStyles.css';

const ThreadDetail = ({id, focused_content, setFocusedContent, setCurrent}) => {
  if (!id) {
    return null;
  }

  return (
    <ThreadDetailContent
      id={id}
      focused_content={focused_content}
      setFocusedContent={setFocusedContent}
      setCurrent={setCurrent}
    />
  );
};

const ThreadDetailContent = ({
  id,
  focused_content,
  setFocusedContent,
  setCurrent,
}) => {
  const {threadActions} = useCollaborator();
  const {loadProfiles} = useLoader();

  const {
    state: {id: user_id, profiles},
  } = useAuth();
  const {
    state: {
      threads,
      content_ids,
      thread_content,
      thread_subs,
      thread_subs_other,
      thread_subs_loaded,
      thread_request_ids,
      threads_loaded,
      request_subscriptions,
      thread_ids,
    },
    getContents,
    getContent,
    updateThreadSubscription,
    getThreadSubscriptionsByThread,
    createThreadSubscription,
    getThreadRequests,
    getThread,
    attachThreadRequestListener,
  } = useThreads();

  const thread = threads?.[id] ?? {};
  const {parent} = thread;
  const sub = thread_subs?.[id] ?? null;

  const {
    id: thread_id,
    content,
    updated,
    last_sender,
    last_content,
    members,
    custom_logo,
    status,
  } = thread;

  const has_access = status === 'active' ? members?.includes(user_id) : true;
  const {editable, writeable} = threadActions(thread);
  const complete = status === 'complete';

  const scrollRef = useRef();

  const message_ids = content_ids?.[id] ?? [];

  const [initialSub, setInitial] = useState({...(sub || {})});
  const [loading, setLoading] = useState(false);
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
  const [reply, setReply] = useState(null);
  const [curQuote, setCurQuote] = useState(null);

  const [showChat, setShowChat] = useState(false);
  const [view, setView] = useState('default');
  const [sort, setSort] = useState({value: 'recent', label: 'Recent'});
  const [filter, setFilter] = useState({value: 'all', label: 'All'});
  const [search, setSearch] = useState('');
  const [activeMenuItem, setActiveMenuItem] = useState('activity');
  const [index, setIndex] = useState('content');
  const [collapse, setCollapse] = useState(null);

  useEffect(() => {
    if (id) {
      setShowChat(false);
      setReply(null);
    }
  }, [id, threads_loaded]);

  const setQuote = (contentId, text) => {
    setCurQuote(text);
    setReply(contentId);
  };

  // HANDLE SORTING AND FILTERING
  const sortedContent = useSortedContent(
    message_ids,
    thread_content,
    filter,
    activeMenuItem,
    view,
    search,
    sort,
    user_id,
  );

  // LOAD IN CONTENT - SHOULD ALWAY BE PRE-LOADED NOW
  useEffect(() => {
    if (!has_access) return;

    const load = async () => {
      setLoading(true);
      const query = {thread_id: id, filter: {status: {eq: 'active'}}};
      await getContents(query, id);
      setLoading(false);
    };
    // CHECK IF HAS ACCESS BEFORE LOADING
    const init = Object.keys(content_ids);
    if (
      thread_id &&
      has_access &&
      id &&
      init?.length &&
      content_ids[id] === undefined
    ) {
      load();
    }

    if (id && thread_request_ids[id] === undefined) {
      const query = {thread_id: id, filter: {status: {eq: 'requested'}}};
      getThreadRequests(query);
    }

    if (id && request_subscriptions[id] === undefined) {
      attachThreadRequestListener(
        {filter: {thread_id: {eq: id}, status: {eq: 'requested'}}},
        id,
      );
    }
  }, [id]);

  // UPDATE INITIAL SUB FOR NEW THREADS
  useEffect(() => {
    if (sub && !initialSub?.id) {
      setInitial(sub);
    }
  }, [sub]);

  // FETCH ALL PROFILES IN THREAD
  useEffect(() => {
    if (!has_access) return;

    loadProfiles(members);
  }, [members]);

  useEffect(() => {
    if (!has_access) {
      return;
    }

    if (!id || !thread_subs_loaded) {
      return;
    }

    // SAVE THE INITIAL SUB BEFORE UPDATING IT
    setInitial(sub);

    const new_content = !sub?.last_read || sub?.last_read < updated;

    // IF NOT ACTIVE OR NO NEW CONTENT TO HAVE SEEN IGNORE
    if (!new_content) {
      return;
    }

    if (sub) {
      const update = {id: sub.id, thread_id: id, last_read: dateToTimestamp()};
      updateThreadSubscription(update);
    } else {
      const created = {
        id: v4(),
        user_id,
        thread_id: id,
        status: null,
        last_read: dateToTimestamp(),
      };
      createThreadSubscription(created);
    }
  }, [id, thread_subs_loaded, has_access]);

  // IF THE LAST SENDER UPDATE THE INITIAL SUB VALUE
  useEffect(() => {
    if (!has_access) return;

    if (last_sender === user_id) {
      setInitial(sub);
    }
  }, [last_sender, last_content, sub?.last_read]);

  useEffect(() => {
    if (!has_access) return;

    const load = async () => {
      setLoadingSubscriptions(true);
      await getThreadSubscriptionsByThread(
        {
          filter: {thread_id: {eq: id}, user_id: {ne: user_id}},
        },
        id,
      );

      setLoadingSubscriptions(false);
    };
    if (id && thread_subs_other?.[id] === undefined) {
      load();
    }

    // RESET CONTENT ON SWITCHED THREADS
    setIndex('content');
    setActiveMenuItem('activity');
  }, [id]);

  useEffect(() => {
    if (!has_access) return;

    if (parent && thread_content[parent] === undefined) {
      getThread(parent);
    }
  }, [parent]);

  const scrollToComment = useCallback(() => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({behavior: 'smooth'});
    }, 100); // 100ms delay, adjust as needed
  }, []);

  const findFocusedIndex = () => {
    if (!focused_content) {
      return 0;
    }

    let index = 0;
    sortedContent.forEach((id, i) => {
      if (id.type) {
        return;
      }
      const children = message_ids[id];

      if (id === focused_content || children.includes(focused_content)) {
        index = i;
      }
    });

    return index + 1;
  };

  const [page, setPage] = useState(1);
  const page_size = 10;
  const focused_index = findFocusedIndex();

  const containerRef = useRef(null);

  const handleScroll = () => {
    if (!containerRef.current) {
      return;
    }

    const {scrollTop, scrollHeight, clientHeight} = containerRef.current;
    const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;

    // Load more when user has scrolled to 80% of the container
    if (scrollPercentage > 0.8 && sortedContent?.length > page * page_size) {
      setPage(prev => prev + 1);
    }
  };

  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [page, sortedContent?.length, id]);

  // RESET PAGE ON NAVIGATE AND FILTER
  useEffect(() => {
    setPage(1);
  }, [id]);

  if (!has_access)
    return (
      <div className="flex-column padding-h64 ">
        <div className="flex-column align-center">
          <div className="thread-search-empty">
            <div className="flex-column align-center justify-center">
              <ImBlocked className="thread-search-empty-icon" />

              <p className="padding-bottom8">
                You do not have access{' '}
                {thread?.title?.length > 0
                  ? `to ${thread?.title}`
                  : 'to this thread'}
              </p>
              {thread?.title && (
                <p className="text-secondary">
                  <span className="text-bold text-secondary-button">
                    Request access
                  </span>{' '}
                  below or reach out to the{' '}
                  <span className="text-bold text-primary-button">
                    thread owner
                  </span>
                </p>
              )}
            </div>
          </div>
        </div>
        {thread?.title && (
          <ThreadFeedItem
            item={thread}
            setIndex={setIndex}
            setCurrent={setCurrent}
            setContent={setFocusedContent}
          />
        )}
      </div>
    );

  return (
    <>
      <VerticalMenuBar
        id={id}
        activeItem={activeMenuItem}
        setActiveItem={setActiveMenuItem}
        setIndex={setIndex}
      />
      <ThreadHeader
        id={id}
        setCurrent={setCurrent}
        setFocusedContent={setFocusedContent}
        search={search}
        setSearch={setSearch}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
        setCollapse={setCollapse}
        collapse={collapse}
        view={view}
        setView={setView}
      />
      <div className="thread-message-container" ref={containerRef}>
        <div ref={scrollRef} />
        {loading && <SpinningIndicator />}
        {index === 'content' && (
          <ThreadContent
            key={id}
            id={id}
            focused_content={focused_content}
            sortedContent={sortedContent?.slice(
              0,
              Math.max(focused_index, page * page_size),
            )}
            activeMenuItem={activeMenuItem}
            setReply={setReply}
            setQuote={setQuote}
            initialSub={initialSub}
            setFocusedContent={setFocusedContent}
            collapse={collapse}
            view={view}
          />
        )}
        {index === 'sub-threads' && <SubThreads id={id} search={search} />}
        {index === 'curate-thread' && <CurateThread id={id} />}
      </div>
      {writeable && reply && (
        <ReplyToComment
          id={id}
          parent={reply}
          onClose={() => {
            setReply(null);
            setCurQuote(null);
          }}
          isOpen={!!reply}
          quoteText={curQuote}
        />
      )}
      {index === 'content' && writeable && !complete && (
        <CreateComment id={id} onSuccess={scrollToComment} />
      )}
      {!complete && writeable && isProUser(user_id) && (
        <FloatingAssistantIcon onLaunch={() => setShowChat(true)} />
      )}
      {showChat && (
        <AIChatAssistant
          sub={sub}
          thread={thread}
          onClose={() => setShowChat(false)}
        />
      )}
    </>
  );
};

export const ThreadContent = ({
  id,
  focused_content,
  sortedContent,
  activeMenuItem,
  setReply,
  setQuote,
  initialSub,
  view,
  collapse,
}) => {
  const {
    state: {thread_content, threads, thread_subs, content_ids},
  } = useThreads();

  const getIcon = () => {
    switch (activeMenuItem) {
      case 'activity':
        return view === 'curated' ? (
          <span className="flex-row align-center justify-center">
            <RxLayers className="thread-search-empty-icon" />
            <span className="margin-h8" />
            <RxActivityLog className="thread-search-empty-icon" />
          </span>
        ) : (
          <RxActivityLog className="thread-search-empty-icon" />
        );
      case 'form':
        return view === 'curated' ? (
          <span className="flex-row align-center justify-center">
            <RxLayers className="thread-search-empty-icon" />
            <span className="margin-h8" />
            <HiOutlineDocumentText className="thread-search-empty-icon" />
          </span>
        ) : (
          <HiOutlineDocumentText className="thread-search-empty-icon" />
        );
      case 'milestone':
        return view === 'curated' ? (
          <span className="flex-row align-center justify-center">
            <RxLayers className="thread-search-empty-icon" />
            <span className="margin-h8" />
            <RxSewingPinFilled className="thread-search-empty-icon" />
          </span>
        ) : (
          <RxSewingPinFilled className="thread-search-empty-icon" />
        );
      case 'decision_point':
        return view === 'curated' ? (
          <span className="flex-row align-center justify-center">
            <RxLayers className="thread-search-empty-icon" />
            <span className="margin-h8" />
            <MdLabelImportantOutline className="thread-search-empty-icon" />
          </span>
        ) : (
          <MdLabelImportantOutline className="thread-search-empty-icon" />
        );
      case 'task':
        return view === 'curated' ? (
          <span className="flex-row align-center justify-center">
            <RxLayers className="thread-search-empty-icon" />
            <span className="margin-h8" />
            <RxCheckbox className="thread-search-empty-icon" />
          </span>
        ) : (
          <RxCheckbox className="thread-search-empty-icon" />
        );
      case 'question':
        return view === 'curated' ? (
          <span className="flex-row align-center justify-center">
            <RxLayers className="thread-search-empty-icon" />
            <span className="margin-h8" />
            <RxQuestionMarkCircled className="thread-search-empty-icon" />
          </span>
        ) : (
          <RxQuestionMarkCircled className="thread-search-empty-icon" />
        );
      case 'poll':
        return view === 'curated' ? (
          <span className="flex-row align-center justify-center">
            <RxLayers className="thread-search-empty-icon" />
            <span className="margin-h8" />
            <BiPoll className="thread-search-empty-icon" />
          </span>
        ) : (
          <BiPoll className="thread-search-empty-icon" />
        );
      default:
        return view === 'curated' ? (
          <RxLayers className="thread-search-empty-icon" />
        ) : (
          <RxActivityLog className="thread-search-empty-icon" />
        );
    }
  };
  const thread = threads?.[id] ?? {};
  const {parent, content, status} = thread;
  const sub = thread_subs?.[id] ?? null;
  const message_ids = content_ids?.[id] ?? [];

  return (
    <>
      {parent && (
        <BreakoutComment
          item={thread_content?.[parent] ?? {}}
          description={content}
          onReply={e => {
            e.stopPropagation();
          }}
          sub={sub}
          viewOnly={true}
        />
      )}
      {sortedContent?.map(item => {
        if (typeof item === 'string') {
          // It's a content_id
          return (
            <ThreadMessageGroup
              key={item}
              contentId={item}
              messageIds={message_ids[item]?.sort((a, b) => {
                const a_content = thread_content?.[a] ?? {};
                const b_content = thread_content?.[b] ?? {};
                return a_content?.created - b_content.created;
              })}
              onReply={setReply}
              onQuote={setQuote}
              focusedContent={focused_content}
              sub={initialSub}
              collapse={collapse}
            />
          );
        } else {
          // It's a date break
          return item;
        }
      })}
      {!parent && sortedContent?.length === 0 && (
        <>
          {/* {activeMenuItem === 'break out' && <p>test</p>} */}
          <div className="thread-search-empty">
            {status !== 'draft' && getIcon()}
            {status === 'draft' ? (
              <p>
                This is a{' '}
                <span className="text-bold text-secondary-button">
                  Personal Workspace
                </span>{' '}
              </p>
            ) : (
              <p>
                No{' '}
                {activeMenuItem === 'activity'
                  ? 'posts'
                  : activeMenuItem === 'decision_point'
                  ? 'decision points'
                  : activeMenuItem + 's'}{' '}
                yet
              </p>
            )}
            {status === 'draft' ? (
              <>
                <p className="text-secondary">
                  Convert to{' '}
                  <span style={{color: 'var(--blue)'}} className="text-bold">
                    Group
                  </span>{' '}
                  by clicking{' '}
                  <span className="text-bold text-secondary-button">
                    <RxFile />
                  </span>{' '}
                  in the top bar
                </p>
                <p className="text-secondary">
                  You can also share a read-only version from the upper right
                  'Share' button
                </p>
              </>
            ) : view === 'curated' ? (
              <p className="text-secondary">
                Create your{' '}
                <span className="text-bold text-primary-button">Organized</span>{' '}
                view from the left sidebar
              </p>
            ) : (
              <p className="text-secondary">
                Add your first{' '}
                <span className="text-bold text-secondary-button">post</span>{' '}
                below and{' '}
                <span className="text-bold text-primary-button">
                  invite members
                </span>{' '}
                at the top
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
};

export const ThreadHeader = ({
  id,
  setCurrent,
  setFocusedContent,
  search,
  setSearch,
  filter,
  setFilter,
  sort,
  setSort,
  setCollapse,
  collapse,
  view,
  setView,
  isPublic = false,
}) => {
  const navigate = useNavigate();
  const {copyString} = useUtilities();
  const {prettyName} = useStringFormatter();
  const {threadActions, threadPermissions} = useCollaborator();
  const {
    state: {
      threads_loaded,
      thread_content,
      threads,
      thread_subs,
      thread_subs_other,
      thread_request_ids,
      thread_subs_loaded,
      thread_ids,
    },
    getThread,
    getContent,
    getContents,
    updateThreadSubscription,
    updateThread,
  } = useThreads();
  const {
    state: {id: user_id, profiles},
  } = useAuth();

  const thread = threads?.[id] ?? {};
  const sub = thread_subs?.[id] ?? null;

  const {
    title,
    permissions,
    type,
    security_level,
    logo,
    custom_logo,
    status,
    members,
  } = thread;
  const draft = status === 'draft';
  const active = status === 'active';
  const completed = status === 'complete';
  const member = members?.includes(user_id);

  const {editable, writeable} = threadActions(thread);
  const current_requests = thread_request_ids[id] ?? [];

  const [firstTime, setFirstTime] = useState(false);
  const [editPermissions, setEditPermissions] = useState(false);
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [popup, setPopup] = useState({on: false, message: ''});
  const [confetti, setConfetti] = useState(false);

  const [threadHierarchy, setThreadHierarchy] = useState([]);
  const [memberActivities, setMemberActivities] = useState([]);
  const [activityStats, setActivityStats] = useState({
    lastHour: 0,
    today: 0,
    last24Hours: 0,
  });

  // CHECKS IF FIRST TIME VIEWING A THREAD AND SHOWS VIDEO
  useEffect(() => {
    if (thread_subs_loaded && id && !sub?.id) {
      // IF FIRST AND ONLY THREAD SHOW HOW TO VIDEO
      if (thread_ids === 1) {
        setFirstTime(!sub?.id);
      } else {
        // OTHERWISE SHOW INFO
        setIsInfoPopupOpen(true);
      }
    }
  }, [id, thread_subs_loaded]);

  const fetchThreadHierarchy = useCallback(
    async currentId => {
      if (!threads_loaded) {
        return;
      }
      const hierarchy = [];
      let current = threads[currentId] || thread_content[currentId];

      while (current) {
        hierarchy.unshift(current);

        if (current.type === 'thread' && !current.parent) {
          // We've reached the top-level Thread
          break;
        }

        if (
          current.type === 'comment' ||
          current.type === 'milestone' ||
          current.type === 'task' ||
          current.type === 'poll' ||
          current.type === 'decision_point' ||
          current.type === 'question'
        ) {
          // If it's a ThreadContent, we need to find its parent Thread
          if (threads[current.thread_id]) {
            current = threads[current.thread_id];
          } else {
            try {
              await getThread(current.thread_id);
              current = threads[current.thread_id];
            } catch (error) {
              console.error('Error fetching parent Thread:', error);
              break;
            }
          }
        } else if (current.type === 'thread' || current.type === 'sub-thread') {
          if (thread_content[current.parent]) {
            current = thread_content[current.parent];
          } else {
            // If it's a Thread, we need to find its parent ThreadContent
            try {
              const {success} = await getContent(current.parent);
              if (success) {
                current = thread_content?.[current.parent];
              } else {
                console.error('Parent ThreadContent not found');
                break;
              }
            } catch (error) {
              console.error('Error fetching parent ThreadContent:', error);
              break;
            }
          }
        } else {
          console.error('Unknown item type:', current);
          break;
        }
      }

      setThreadHierarchy(hierarchy);
    },
    [threads, getThread, getContents, thread_content],
  );

  useEffect(() => {
    setThreadHierarchy([]);
    fetchThreadHierarchy(id);
  }, [id]);

  const calculateActivities = useMemo(() => {
    const subs = thread_subs_other?.[id] ?? [];

    if (!subs.length || !permissions) {
      return {
        activities: [],
        stats: {
          lastHour: 0,
          today: 0,
          last24Hours: 0,
        },
      };
    }

    const now = Date.now();
    const oneHourAgo = now - 60 * 60 * 1000;
    const startOfToday = new Date().setHours(0, 0, 0, 0);
    const twentyFourHoursAgo = now - 24 * 60 * 60 * 1000;

    let lastHourCount = 0;
    let todayCount = 0;
    let last24HoursCount = 0;

    const activitiesMap = new Map();

    subs.forEach(sub_id => {
      const sub = thread_subs?.[sub_id] ?? {};
      const {last_read, user_id} = sub;
      const lastActiveTimestamp = last_read || 0;
      const lastActive = lastActiveTimestamp * 1000; // Convert AWSTimestamp to milliseconds

      let activityStatus = 'inactive';

      if (lastActive > oneHourAgo) {
        lastHourCount++;
        activityStatus = 'lastHour';
      } else if (lastActive > startOfToday) {
        todayCount++;
        activityStatus = 'today';
      } else if (lastActive > twentyFourHoursAgo) {
        last24HoursCount++;
        activityStatus = 'last24Hours';
      }

      // Use Map to ensure no duplicates
      if (
        !activitiesMap.has(user_id) ||
        lastActiveTimestamp > activitiesMap.get(user_id).lastActive
      ) {
        activitiesMap.set(user_id, {
          member: user_id,
          lastActive: lastActiveTimestamp,
          activityStatus,
        });
      }
    });

    const activities = Array.from(activitiesMap.values()).sort(
      (a, b) => b.lastActive - a.lastActive,
    );

    return {
      activities: activities,
      stats: {
        lastHour: lastHourCount,
        today: todayCount,
        last24Hours: last24HoursCount,
      },
    };
  }, [id, permissions, thread_subs_other, thread_subs]);

  useEffect(() => {
    if (calculateActivities) {
      setActivityStats(calculateActivities.stats);
      setMemberActivities(calculateActivities.activities);
    }
  }, [calculateActivities]);

  const getActivityColor = useCallback(() => {
    if (activityStats.lastHour > 0) return 'green';
    if (activityStats.today > 0) return 'yellow';
    return 'red';
  }, [activityStats]);

  const getActiveCount = useCallback(() => {
    if (activityStats.lastHour > 0) return activityStats.lastHour;
    if (activityStats.today > 0) return activityStats.today;
    return activityStats.last24Hours;
  }, [activityStats]);

  return (
    <>
      <div
        className={`thread-detail-sticky-header ${isPublic ? 'public' : ''}`}>
        {!isPublic && (
          <ThreadHierarchy
            hierarchy={threadHierarchy}
            setCurrent={setCurrent}
            setFocusedContent={setFocusedContent}
          />
        )}
        <div className="thread-header">
          <span className="flex-row align-center justify-center">
            {editable && draft && (
              <DraftStatusButton onClick={() => setActiveModal(true)} />
            )}
            {editable && completed && (
              <CompletedStatusButton
                onClick={() => {
                  setActiveModal(true);
                }}
              />
            )}
            {editable && active && (
              <LiveStatusButton
                onClick={async () => {
                  setCompleteModal(true);
                }}
              />
            )}
            {thread?.type === 'sub-thread' ? (
              <GoGitBranch size={16} className="padding-right4" />
            ) : (
              ''
            )}
            {custom_logo && (
              <img
                src={custom_logo?.url}
                alt="Logo"
                style={{
                  height: '24px',
                  maxWidth: '48px',
                  width: 'auto',
                  marginRight: '8px',
                }}
              />
            )}
            <h2 className={`${completed || draft ? 'padding-right8' : ''}`}>
              {logo ? logo + ' ' : null}
              {title ? (
                title
              ) : (
                <span className="text-secondary text-italic">
                  Unable to Access Thread. Refresh page.
                </span>
              )}
            </h2>
            {draft || completed ? null : security_level === 'secure' ||
              type === 'direct' ? (
              <RxLockClosed
                title="Secure Thread"
                className="security-icon-small secure"
                onClick={() => setIsInfoPopupOpen(true)}
              />
            ) : security_level === 'private' ? (
              <RxLockOpen1
                title="Private Thread"
                className="security-icon-small private"
                onClick={() => setIsInfoPopupOpen(true)}
              />
            ) : (
              <RxLockOpen2
                title="Public Thread"
                className="security-icon-small open"
                onClick={() => setIsInfoPopupOpen(true)}
              />
            )}
            <div className="thread-actions">
              {active && editable && (
                <>
                  {(true || security_level === 'open') && type !== 'direct' && (
                    <button
                      className="thread-action-button"
                      onClick={() => {
                        const link = `${constants.root_url}/threads/invite/${id}`;
                        copyString(link);
                        setPopup({
                          on: true,
                          message: 'Invite link copied to clipboard',
                        });
                        track('thread_invite_link_copied_small', {
                          link,
                          user_id,
                        });
                      }}>
                      <InvertedTooltipNoShadow text={'Copy Invite Link'}>
                        <span className="flex-column align-center">
                          <RxCopy />
                        </span>
                      </InvertedTooltipNoShadow>
                    </button>
                  )}

                  {(type === 'thread' || type === 'sub-thread') && (
                    <button
                      className="thread-action-button"
                      disabled={!editable}
                      onClick={() => setEditPermissions(!editPermissions)}>
                      <InvertedTooltipNoShadow text={'Edit/update members'}>
                        <span
                          className={`flex-column align-center ${
                            permissions?.length === 1
                              ? 'border-primary-button'
                              : ''
                          }`}>
                          <RxPerson
                            color={
                              permissions?.length === 1
                                ? 'var(--primary-button)'
                                : ''
                            }
                          />
                        </span>
                      </InvertedTooltipNoShadow>
                    </button>
                  )}
                  <button
                    className="thread-action-button"
                    onClick={() => setEdit(!edit)}>
                    <InvertedTooltipNoShadow
                      text={'Edit workspace information'}>
                      <span className="flex-column align-center">
                        <RxPencil1 />
                      </span>
                    </InvertedTooltipNoShadow>
                  </button>

                  <button
                    className="thread-action-button"
                    onClick={() => {
                      navigate(`/threads/admin/panel/${id}`);
                    }}>
                    <InvertedTooltipNoShadow text={'See member activity'}>
                      <span className="flex-column align-center">
                        <RxDashboard />
                      </span>{' '}
                    </InvertedTooltipNoShadow>
                  </button>
                </>
              )}
              {!isPublic && (
                <MemberActivityTooltip
                  memberActivities={memberActivities}
                  prettyName={member =>
                    prettyName(profiles?.[member] || {email: member})
                  }
                  timeSince={timeSince}>
                  <div className="activity-indicator">
                    <span
                      className={`activity-dot ${getActivityColor()}`}></span>
                    <span>{getActiveCount()}</span>
                  </div>
                </MemberActivityTooltip>
              )}
            </div>
          </span>
          <div className="flex-row">
            {user_id && member && (
              <Badge
                count={editable ? current_requests?.length : 0}
                style={{top: 0, right: 0}}>
                <button
                  title="Click to see thread information"
                  className="thread-info-button margin-h8"
                  onClick={() => setIsInfoPopupOpen(true)}>
                  About
                </button>
              </Badge>
            )}
            {user_id && member && (
              <Badge count={0}>
                <button
                  title="Pin to top of list"
                  className="thread-info-button "
                  onClick={async () => {
                    const update = {
                      id: sub?.id,
                      thread_id: id,
                      priority: sub?.priority ? null : `${dateToTimestamp()}`,
                    };

                    await updateThreadSubscription(update);
                    if (sub?.priority) {
                      track('thread_unpinned', {sub: update, user_id});
                    } else {
                      track('thread_pinned', {sub: update, user_id});
                    }
                  }}>
                  {sub?.priority ? 'Unpin' : 'Pin'}
                </button>
              </Badge>
            )}
            {active && type !== 'direct' && editable && (
              <button
                title="Copy invite link to share"
                className="thread-info-button margin-h8"
                onClick={() => {
                  const link = `${constants.root_url}/threads/invite/${id}`;
                  copyString(link);
                  setPopup({
                    on: true,
                    message: 'Invite link copied to clipboard',
                  });
                  track('thread_invite_link_copied_big', {link, user_id});
                }}>
                Invite Link
              </button>
            )}
            {user_id && draft && editable && (
              <button
                title="Copy read-only share link"
                className="thread-info-button margin-h8"
                onClick={() => {
                  const link = `${constants.root_url}/threads/public/${id}`;
                  copyString(link);
                  setPopup({
                    on: true,
                    message: 'Read-only link copied to clipboard',
                  });
                  track('public_thread_invite_link_copied_big', {
                    link,
                    user_id,
                  });
                }}>
                Share
              </button>
            )}
          </div>
        </div>
        <div className="sort-section">
          <span className="search-section">
            <Textfield
              value={search}
              type="text"
              placeholder="Search here..."
              onChange={e => setSearch(e.target.value)}
              header=""
            />
          </span>
          {/* <label className="margin-h8">Filter by:</label>
          <OptionsDropdown
            value={filter}
            setValue={newFilter => setFilter(newFilter)}
            items={[
              {value: 'all', label: 'All'},
              {value: 'comment', label: 'Comment'},
              {value: 'task', label: 'Task'},
              {value: 'milestone', label: 'Milestone'},
            ]}
          /> */}
          <label className="margin-h8">Sort by:</label>
          <OptionsDropdown
            value={sort}
            setValue={newSort => setSort(newSort)}
            items={[
              {value: 'recent', label: 'Recent'},
              {value: 'active', label: 'Activity'},
            ]}
          />
          <button
            className="thread-info-button margin-h8"
            onClick={() => {
              setCollapse(cur => {
                if (cur) return null;
                return dateToTimestamp();
              });
            }}>
            <InvertedTooltipNoShadow
              text={collapse ? 'Open all replies' : 'Collapse all replies'}>
              {collapse ? <RxDoubleArrowDown /> : <RxDoubleArrowUp />}
            </InvertedTooltipNoShadow>
          </button>

          {/* <div className="custom-curated-view-toggle">
            <label className="custom-curated-view-toggle__switch">
              <input
                type="checkbox"
                checked={view === 'curated'}
                onChange={() => {
                  let newView = view === 'default' ? 'curated' : 'default';
                  setView(newView);
                }}
              />
              <span className="custom-curated-view-toggle__slider"></span>
            </label>
            <span className="custom-curated-view-toggle__label">
              {view === 'default' ? 'Default' : 'Organized'}
            </span>
          </div> */}
        </div>
      </div>
      <ThreadInfoPopup
        isOpen={isInfoPopupOpen}
        onClose={() => setIsInfoPopupOpen(false)}
        thread={thread}
        setCurrent={setCurrent}
      />
      <EditThreadPermissionsModal
        active={editPermissions}
        setActive={setEditPermissions}
        thread={thread}
      />
      <ActiveThreadModal
        active={activeModal}
        setActive={setActiveModal}
        thread={thread}
      />
      <AssistantModal
        active={completeModal}
        setActive={setCompleteModal}
        thread_data={{thread, toComplete: true}}
      />
      {/* <CompleteThreadModal
        active={completeModal}
        setActive={setCompleteModal}
        thread={thread}
        setConfetti={setConfetti}
      /> */}
      <EditThreadModal active={edit} setActive={setEdit} thread={thread} />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
      <Modal active={firstTime} setActive={setFirstTime}>
        <div className=" overview-pop-up">
          <h3>Welcome to your first thread! 🧵</h3>
          <p className="text-secondary">
            This thread is secure and yours alone.{' '}
            <span
              className="clickable text-link"
              onClick={() => {
                navigate('/threads/create');
              }}>
              Click here
            </span>{' '}
            to create a new one or watch our overview video below.
          </p>
          <p className="text-secondary text-12">
            Need help? Head to the 🟩 Welcome Thread{' '}
            <span
              className="clickable text-link"
              onClick={() => {
                navigate('/threads/e91ac7e1-9bd6-4125-8c6a-85de120874ea');
              }}>
              here
            </span>{' '}
            to ask a question.
          </p>
        </div>
        <div style={{position: 'relative', paddingBottom: '56.25%', height: 0}}>
          <iframe
            title="Overview Video"
            src={
              'https://www.loom.com/embed/b9fa27732fd145979368e3cad311326b?sid=8da2ce52-b37a-422c-a83e-f7e80d335879'
            }
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </Modal>
      <Confetti active={confetti} setActive={setConfetti} />
    </>
  );
};

const SubThreads = ({id, search}) => {
  const navigate = useNavigate();
  const {
    state: {threads, thread_content, content_ids, thread_ids},
    updateThread,
    defaultUpdate,
  } = useThreads();
  const {
    state: {id: user_id},
  } = useAuth();

  const message_ids = content_ids?.[id] ?? [];
  const [breakout_threads, setThreads] = useState([]);

  useEffect(() => {
    const parents = Object.keys(message_ids);
    let sub_threads = [];

    parents.forEach(content_id => {
      const kids = message_ids[content_id];
      const content = thread_content?.[content_id] ?? {};
      const {children} = content;

      // Collect sub threads from the direct children
      children?.forEach(sub_thread_id => {
        const thread = threads?.[sub_thread_id] ?? null;
        if (
          thread &&
          thread.security_level !== 'secure' &&
          !sub_threads.includes(sub_thread_id) &&
          thread.status !== 'archived'
        ) {
          sub_threads.push(sub_thread_id);
        }
      });

      // Collect sub threads from the "kids" children
      kids.forEach(child_id => {
        const content = thread_content?.[child_id] ?? {};
        const {children} = content;
        children?.forEach(sub_thread_id => {
          const thread = threads?.[sub_thread_id] ?? null;
          if (
            thread &&
            thread.security_level !== 'secure' &&
            !sub_threads.includes(sub_thread_id) &&
            thread.status !== 'archived'
          ) {
            sub_threads.push(sub_thread_id);
          }
        });
      });
    });

    // Sort sub_threads by thread.title (alphabetically)
    sub_threads = sub_threads.sort((a, b) => {
      const threadA = threads?.[a]?.title?.toLowerCase() ?? '';
      const threadB = threads?.[b]?.title?.toLowerCase() ?? '';
      return threadA.localeCompare(threadB);
    });
    setThreads(sub_threads);
  }, [id, content_ids, message_ids, thread_content, threads]);

  const getSecurityTitle = security_level => {
    switch (security_level) {
      case 'secure':
        return <p>Secure</p>;
      case 'private':
        return <span className="">Private</span>;
      case 'open':
      default:
        return <span className="">Public</span>;
    }
  };
  const filteredThreads = useMemo(() => {
    if (!search) return breakout_threads;
    return breakout_threads.filter(thread_id => {
      const thread = threads?.[thread_id] ?? {};
      return thread.title?.toLowerCase().includes(search.toLowerCase());
    });
  }, [breakout_threads, threads, search]);

  return (
    <div className="padding-8">
      {/* <h4>Sub Threads</h4> */}
      <table className="sub-threads-container ">
        <thead>
          <tr>
            <th>Name</th>
            <th>Security Level</th>
            <th>Created</th>
            <th>Member Count</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredThreads?.length > 0
            ? filteredThreads?.map(thread_id => {
                const thread = threads?.[thread_id] ?? {};
                const {permissions, members, security_level, title, created} =
                  thread;
                const active_member = members?.includes(user_id);
                const joinable = security_level === 'open' && !active_member;

                const handleJoin = async () => {
                  const now = dateToTimestamp();
                  const updated_permissions = [
                    ...permissions,
                    {user_id, role: 'collaborator', created: now, updated: now},
                  ];
                  const updated_members = updated_permissions.map(
                    item => item.user_id,
                  );
                  await updateThread({
                    id: thread_id,
                    permissions: updated_permissions,
                    members: updated_members,
                  });
                  const new_threads = [...thread_ids];
                  if (!new_threads?.includes(thread_id)) {
                    new_threads.push(thread_id);
                  }
                  defaultUpdate({thread_ids: new_threads});
                  navigate(`/threads/${thread_id}`);
                };

                return (
                  <tr key={thread_id}>
                    <td className="text-left">{title}</td>
                    <td className="">{getSecurityTitle(security_level)}</td>
                    <td>{formatMMDDYYYY(new Date(created * 1000))}</td>
                    <td>{members?.length ?? 0}</td>
                    <td>
                      {active_member && (
                        <button
                          className="feed-item__button feed-item__button--enter"
                          onClick={() => {
                            navigate(`/threads/${thread_id}`);
                          }}>
                          <RxEnter /> Enter
                        </button>
                      )}
                      {!active_member && security_level === 'private' && (
                        <button
                          className="feed-item__button feed-item__button--request"
                          onClick={() =>
                            navigate(`/threads/request/${thread_id}`)
                          }>
                          Request to Join
                        </button>
                      )}
                      {joinable && (
                        <button
                          className="feed-item__button feed-item__button--join"
                          onClick={handleJoin}>
                          Join
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      {filteredThreads?.length === 0 && (
        <span className="flex-column align-center justify-center">
          <div className="sub-threads-empty">
            <GoGitBranch className="sub-threads-empty-icon" />
            <p>No breakout workspaces yet</p>
            <p>Secure workspaces do not appear here</p>
          </div>
        </span>
      )}
    </div>
  );
};

const CurateThread = ({id}) => {
  const {
    state: {content_ids, thread_content},
    updateContent,
  } = useThreads();

  const message_ids = content_ids?.[id] ?? {};

  const [popup, setPopup] = useState({on: false, message: ''});
  const [lists, setLists] = useState({
    curated: [],
    hidden: [],
  });

  useEffect(() => {
    const parents = Object.keys(message_ids);

    const cur = [];
    const hid = [];

    parents.forEach(content_id => {
      const content = thread_content?.[content_id];
      if (!content) {
        return;
      }
      const {index} = content;

      if (index === null) {
        hid.push(content_id);
      } else {
        cur.push(content_id);
      }
    });
    const sorted = cur.sort((a, b) => {
      return thread_content?.[a]?.index - thread_content?.[b]?.index;
    });
    setLists({curated: sorted, hidden: hid});
  }, [content_ids?.[id], thread_content]);

  const onDragEnd = result => {
    const {source, destination} = result;

    if (!destination) return;

    const newLists = {...lists};
    const [removed] = newLists[source.droppableId].splice(source.index, 1);
    newLists[destination.droppableId].splice(destination.index, 0, removed);

    setLists(newLists);
  };

  const handleSave = async () => {
    const {curated, hidden} = lists;

    try {
      await Promise.all(
        curated.map(async (content_id, index) => {
          const current = thread_content?.[content_id];

          if (current && current?.index !== index) {
            await updateContent({id: content_id, index});
          }
        }),
      );

      await Promise.all(
        hidden.map(async content_id => {
          const current = thread_content?.[content_id];
          if (current && current?.index !== null) {
            await updateContent({id: content_id, index: null});
          }
        }),
      );

      setPopup({
        on: true,
        message: 'New workspace organization saved successfully!',
      });
    } catch (error) {
      console.error('Error saving workspace organization:', error);
      setPopup({
        on: true,
        message: 'Failed to save new workspace organization. Please try again.',
      });
    }
  };

  const renderList = listKey => (
    <Droppable droppableId={listKey} key={listKey}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`droppable-area ${
            snapshot.isDraggingOver ? 'dragging-over' : ''
          } ${lists[listKey].length === 0 ? 'empty-list' : ''} `}>
          {lists[listKey].map((content_id, index) => {
            const content = thread_content?.[content_id] ?? {};

            return (
              <Draggable
                key={content_id}
                draggableId={content_id}
                index={index}>
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`draggable-item-wrapper ${
                      listKey === 'hidden' ? 'translucent' : ''
                    }`}>
                    <DraggableItem item={content} />
                  </div>
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
          {lists[listKey].length === 0 && (
            <div className="empty-list-placeholder">Drop items here</div>
          )}
        </div>
      )}
    </Droppable>
  );

  return (
    <div className="curate-thread-container">
      <span className="flex-row justify-between align-center">
        <span className="flex-column">
          <h5>Organize Your Workspace</h5>
          <p className="text-secondary">
            Drag and drop posts and press 'Save Changes'. Use the toggle above
            to see this view when on your workspace.
          </p>
        </span>
        <button className="curate-thread-save-button" onClick={handleSave}>
          Save Changes
        </button>
      </span>
      <span className="border-bottom padding-bottom8" />
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="curate-thread-grid padding-top8">
          <div className="curate-thread-column">
            <p className="text-bold">Organized</p>
            <div className="curate-thread-scroll-container">
              {renderList('curated')}
            </div>
          </div>
          <div className="curate-thread-column">
            <div className="curate-thread-header">
              <p className="text-secondary">Hidden</p>
            </div>
            <div className="curate-thread-scroll-container">
              {renderList('hidden')}
            </div>
          </div>
        </div>
      </DragDropContext>
      <ActionPopup message={popup.message} on={popup.on} setOff={setPopup} />
    </div>
  );
};

const DraggableItem = ({item}) => {
  const {
    state: {profiles},
  } = useAuth();
  const {type, content, owner_id, created, priority} = item;

  const user = profiles?.[owner_id] ?? {};
  const {first_name, last_name, profile_image} = user;

  const getContentIcon = () => {
    switch (type) {
      case 'milestone':
        return <RxSewingPinFilled size={16} />;
      case 'question':
        return <RxQuestionMarkCircled size={16} />;
      case 'decision_point':
        return <MdLabelImportantOutline size={16} />;
      case 'poll':
        return <BiPoll size={16} />;
      case 'task':
        return <RxCheckbox size={16} />;
      default:
        return priority ? <RiPushpinFill size={16} /> : null;
    }
  };

  return (
    <div className="draggable-item">
      {/* <span className="flex-row justify-between padding-top8"></span> */}
      <div className="draggable-item-header">
        <RxDragHandleDots2 size={'32px'} />

        <ProfileImage
          data={profile_image}
          style={{height: '32px', width: '32px'}}
        />
        <div className="draggable-item-info">
          <p className="draggable-item-name">{`${first_name} ${last_name}`}</p>
          <p className="draggable-item-time">{timeSince(created)}</p>
        </div>
        <span className="draggable-item-type">{getContentIcon()}</span>
      </div>
      <div className="draggable-item-content">
        <ContentDisplay item={item} uneditable={true} />
      </div>
    </div>
  );
};

const useDebounce = (value, delay = 100) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const useSearchContent = (message_ids, thread_content, search) => {
  // Debounce the search input
  const debouncedSearch = useDebounce(search, 100);

  return useMemo(() => {
    if (!debouncedSearch) return null;

    const searchTerm = debouncedSearch.toLowerCase();
    const contentIndex = new Map(
      Object.entries(thread_content).map(([id, content]) => [id, content]),
    );

    // Create a search map that holds search results and their relevance scores
    const searchResults = new Map();

    // Search through parent content and their replies
    Object.keys(message_ids).forEach(parent_id => {
      const parent = contentIndex.get(parent_id);
      if (!parent) return;

      let relevanceScore = 0;
      const contentMatches = findSearchMatches(parent.content, searchTerm);
      relevanceScore += contentMatches * 2; // Parent matches weighted more heavily

      // Search through replies
      const replies = message_ids[parent_id];
      if (replies?.length) {
        replies.forEach(reply_id => {
          const reply = contentIndex.get(reply_id);
          if (reply) {
            const replyMatches = findSearchMatches(reply.content, searchTerm);
            relevanceScore += replyMatches;
          }
        });
      }

      // Include metadata search (optional but useful)
      if (
        parent.type === searchTerm ||
        parent.owner_id?.toLowerCase().includes(searchTerm)
      ) {
        relevanceScore += 1;
      }

      if (relevanceScore > 0) {
        searchResults.set(parent_id, relevanceScore);
      }
    });

    return searchResults;
  }, [message_ids, thread_content, debouncedSearch]);
};

const findSearchMatches = (content, searchTerm) => {
  if (!content) return 0;

  const text = content.toLowerCase();
  let matches = 0;
  let pos = text.indexOf(searchTerm);

  while (pos !== -1) {
    matches++;
    pos = text.indexOf(searchTerm, pos + 1);
  }

  return matches;
};

export const useSortedContent = (
  message_ids,
  thread_content,
  filter,
  activeMenuItem,
  view,
  search,
  sort,
  user_id,
) => {
  // Separate search results computation
  const searchResults = useSearchContent(message_ids, thread_content, search);

  return useMemo(() => {
    // Create an index for faster lookups
    const contentIndex = new Map(
      Object.entries(thread_content).map(([id, content]) => [id, content]),
    );

    // Pre-filter the content
    const filteredContent = Object.keys(message_ids).filter(parent_id => {
      const parent = contentIndex.get(parent_id);
      if (!parent) return false;

      if (filter.value !== 'all' && parent.type !== filter.value) return false;
      if (activeMenuItem !== 'activity' && parent.type !== activeMenuItem)
        return false;
      if (view === 'curated' && parent.index == null) return false;

      // Use pre-computed search results
      if (searchResults) {
        return searchResults.has(parent_id);
      }

      return true;
    });

    // Sorting function
    const sortFunction = (a, b) => {
      const a_content = contentIndex.get(a) || {};
      const b_content = contentIndex.get(b) || {};

      // Priority sorting
      const priorityDiff =
        (b_content.priority ? 1 : 0) - (a_content.priority ? 1 : 0);
      if (priorityDiff !== 0) return priorityDiff;

      // If searching, sort by relevance score
      if (searchResults) {
        const relevanceDiff =
          (searchResults.get(b) || 0) - (searchResults.get(a) || 0);
        if (relevanceDiff !== 0) return relevanceDiff;
      }

      if (view === 'curated') {
        return (a_content.index ?? -1) - (b_content.index ?? -1);
      }

      switch (sort.value) {
        case 'tasks':
          return sortTasks(a_content, b_content);
        case 'active':
          const relevanceDiff =
            findRelevance(b, message_ids, contentIndex, user_id) -
            findRelevance(a, message_ids, contentIndex, user_id);
          return relevanceDiff !== 0
            ? relevanceDiff
            : sortTime(a_content, b_content);
        default:
          return sortTime(a_content, b_content);
      }
    };

    // Sort the filtered content
    const sortedContent = filteredContent.sort(sortFunction);

    // Add date breaks if needed
    if (sort.value === 'recent' && !searchResults) {
      return addDateBreaks(sortedContent, contentIndex);
    }

    return sortedContent;
  }, [
    message_ids,
    thread_content,
    filter,
    activeMenuItem,
    view,
    searchResults, // Now depending on searchResults instead of raw search term
    sort,
    user_id,
  ]);
};

// Helper functions
const sortTasks = (a, b) => {
  if (a.type !== 'task' && b.type !== 'task') return 0;
  if (a.type === 'task' && b.type !== 'task') return -1;
  if (a.type !== 'task' && b.type === 'task') return 1;

  const statusOrder = ['not-started', 'in-progress', 'completed'];
  const statusDiff =
    statusOrder.indexOf(a.task_status) - statusOrder.indexOf(b.task_status);
  return statusDiff !== 0 ? statusDiff : b.created - a.created;
};

const sortTime = (a, b) => {
  if (a.created === undefined && b.created === undefined) return 0;
  if (a.created === undefined) return 1;
  if (b.created === undefined) return -1;
  return b.created - a.created;
};

const findRelevance = (id, message_ids, contentIndex, user_id) => {
  const content = contentIndex.get(id) || {};
  const replies = message_ids[id];
  const now = Date.now();

  let sum = Math.max(0, 10 - (now - content.created) / (1000 * 60 * 60 * 24));
  sum += content.owner_id === user_id ? 5 : 0;

  if (replies?.length) {
    sum += replies.reduce((acc, reply_id) => {
      const reply = contentIndex.get(reply_id) || {};
      return (
        acc +
        (reply.owner_id === user_id ? 3 : 0) +
        Math.max(0, 5 - (now - reply.created) / (1000 * 60 * 60 * 24))
      );
    }, 0);
  }

  return sum;
};
const createDateBreak = date => {
  let dateString = timeSince(date);
  if (dateString === 'Invalid Date') return <></>;
  return (
    <div key={`date-break-${date}`} className="date-break">
      <span>{dateString}</span>
    </div>
  );
};
const addDateBreaks = (sortedContent, contentIndex) => {
  const result = [];
  let currentDate = null;
  let priorityContentEnded = false;

  for (const content_id of sortedContent) {
    const content = contentIndex.get(content_id);

    if (content?.priority) {
      result.push(content_id);
    } else {
      if (!priorityContentEnded) {
        priorityContentEnded = true;
        currentDate = null;
      }

      const contentDate = content?.created;
      if (!currentDate || !isSameDay(contentDate, currentDate)) {
        currentDate = contentDate;
        result.push(createDateBreak(currentDate));
      }

      result.push(content_id);
    }
  }

  return result;
};
export default ThreadDetail;
