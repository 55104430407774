import React, {useEffect, useMemo, useState} from 'react';
import {FaConnectdevelop} from 'react-icons/fa';

import {RxExclamationTriangle, RxUpdate} from 'react-icons/rx';
import {track} from '../../api/analytics';
import {useAuth} from '../../hooks/use-auth';
import {useThreads} from '../../hooks/use-threads';
import './SystemStatus.css';

const TRAINING_THREAD_MIN = 0;
export const isProUser = id => {
  return (
    id === 'jack@publicmind.ai' ||
    id === 'charlie@publicmind.ai' ||
    id === 'kirtan@publicmind.ai' ||
    id === 'shsimmons@dew.sc.gov' ||
    id === 'noblegold@yahoo.com' ||
    id === 'andy.bernardin@sc.edu' ||
    id === 'aduvall@ed.sc.gov' ||
    id === 'catherine_kettner@innovationfirst.com' ||
    id === 'chcaswell10@gmail.com' ||
    id === 'help@publicmind.ai'
  );
};
const SystemStatus = ({onLaunchAssistant}) => {
  const {
    state: {processing: authProcessing, error: authError, id},
  } = useAuth();

  const {
    state: {
      threads,
      threads_loaded,
      thread_subs_loaded,
      templates_loaded,
      error: threadError,
      systemStatus,
      listeners,
    },
  } = useThreads();

  const [status, setStatus] = useState('idle');
  const [message, setMessage] = useState('');
  const [showSystemMessage, setShowSystemMessage] = useState(false);

  const intelligenceStatus = useMemo(() => {
    const completedThreads = Object.values(threads).filter(
      thread =>
        thread?.status === 'complete' &&
        thread?.permissions.find(x => x.user_id === id),
    );

    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

    const recentThreads = completedThreads.filter(
      thread => new Date(thread.updated * 1000) > twoMonthsAgo,
    );

    const count = recentThreads.length;
    return {
      isReady: count >= TRAINING_THREAD_MIN,
      count,
      remaining: Math.max(TRAINING_THREAD_MIN - count, 0),
      percentage: Math.min((count / TRAINING_THREAD_MIN) * 100, 100),
    };
  }, [threads]);

  useEffect(() => {
    if (systemStatus?.type === 'processing') {
      setStatus('processing');
      setMessage(systemStatus.message || 'Processing...');
    } else if (systemStatus?.type === 'error') {
      setStatus('error');
      setMessage(systemStatus.message || 'Error occurred');
    } else if (systemStatus?.type === 'success') {
      setStatus('ready');
      setMessage(systemStatus.message || 'Operation successful');
    } else if (authProcessing) {
      setStatus('processing');
      setMessage('Processing authentication...');
    } else if (!threads_loaded || !thread_subs_loaded || !templates_loaded) {
      setStatus('loading');
      setMessage('Loading content...');
    } else if (authError || threadError) {
      if (authError !== 'Profile does not exist.') {
        setStatus('error');
        setMessage(authError || threadError);
      }
    } else {
      setStatus('ready');
      setMessage('Ready');
    }

    if (systemStatus?.type) {
      setShowSystemMessage(true);
      const timer = setTimeout(() => {
        setShowSystemMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [
    authProcessing,
    threads_loaded,
    thread_subs_loaded,
    templates_loaded,
    authError,
    threadError,
    systemStatus,
  ]);

  return (
    <div
      className="pmistat-wrapper pmistat-intelligence--clickable"
      onClick={() => {
        if (onLaunchAssistant) {
          track('launched_doc_generator', {});
          onLaunchAssistant(isProUser(id));
        }
      }}>
      <div className="pmistat-container">
        {showSystemMessage ? (
          <div className="pmistat-message">
            <div className="pmistat-icon">
              {status === 'ready' && (
                <FaConnectdevelop className="pmistat-icon--ready" />
              )}
              {status === 'processing' && (
                <RxUpdate className="pmistat-icon--processing" />
              )}
              {status === 'loading' && (
                <RxUpdate className="pmistat-icon--loading" />
              )}
              {status === 'error' && (
                <RxExclamationTriangle className="pmistat-icon--error" />
              )}
            </div>
            <span className="pmistat-text">{message}</span>
          </div>
        ) : (
          <div className={`pmistat-intelligence `}>
            <div className="pmistat-ring">
              <svg viewBox="0 0 24 24">
                <circle
                  className="pmistat-ring__bg"
                  cx="12"
                  cy="12"
                  r="10"
                  strokeWidth="2"
                />
                <circle
                  className={`pmistat-ring__progress ${
                    intelligenceStatus.isReady ? 'active' : ''
                  }`}
                  cx="12"
                  cy="12"
                  r="10"
                  strokeWidth="2"
                  strokeDasharray={`${
                    intelligenceStatus.percentage * 0.628
                  }, 62.8`}
                />
              </svg>
              {/* {!intelligenceStatus.isReady && (
                <RxUpload
                  className={`pmistat-brain ${
                    intelligenceStatus.isReady
                      ? 'pmistat-brain--active'
                      : 'pmistat-brain--inactive'
                  }`}
                />
              )} */}
              {(intelligenceStatus.isReady || !isProUser(id)) && (
                <FaConnectdevelop
                  className={`pmistat-brain ${
                    intelligenceStatus.isReady
                      ? 'pmistat-brain--active'
                      : 'pmistat-brain--inactive'
                  }`}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SystemStatus;
