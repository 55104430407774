import {useCreateBlockNote} from '@blocknote/react';
import React, {useState} from 'react';
import {v4} from 'uuid';
import {confirmUser, createAccount, getCurrentAccount} from '../../api/auth';
import {dateToTimestamp} from '../../api/dates';
import {
  deleteOpp,
  deleteView,
  fetchAllThreadSubs,
  fetchOpps,
  fetchPageViews,
  fetchPosts,
  fetchProfile,
  fetchProfiles,
  fetchReferrals,
  fetchThreads,
  fetchUnsortedThreadContents,
  listOrgs,
  listSortedPosts,
  removeOrg,
  removeProfile,
  removeThreadSub,
  setOpp,
  setOrg,
  setPageView,
  setProfile,
  updateGrp,
  updateOpp,
  updateOrg,
  updatePosts,
  updateRef,
  updateThreadChain,
  updateThreadContentMessage,
  updateUserProfile,
  updateView,
} from '../../api/graphql';
import {createOpportunities} from '../../api/recommender';
import industriesJSON from '../../assets/lists/industries.json';
import typesJSON from '../../assets/lists/organization-types.json';
import statesJSON from '../../assets/lists/states.json';
import constants from '../../components/constants';
import {schema} from '../../components/inputs/block-input';
import {
  ArraySearchableDropDown,
  SearchableDropDown,
} from '../../components/inputs/drop-down';
import {Textfield} from '../../components/inputs/textfields';
import {useAuth} from '../../hooks/use-auth';
import useLoader from '../../hooks/use-loader';
import useSearchParameters from '../../hooks/use-search-parameters';
import useStringFormatter from '../../hooks/use-string-formatter';
import useUtilities from '../../hooks/use-utilities';
import oldTagsJSON from './../../assets/lists/old-tags.json';
import sc_ipeds from './../../assets/lists/sc-educators.json';

const MasterSettings = ({}) => {
  return (
    <div className="page-container">
      <Opportunities />
      <Organizations />
      <Profiles />
      <OrgCreator />
    </div>
  );
};

const Opportunities = ({}) => {
  const {prettyName} = useStringFormatter();
  const createopportunity = async () => {
    try {
      const p1 = 'chcaswell10@gmail.com';
      const p2 = 'jack@publicmind.ai';

      const opp = {
        id: v4(),
        members: [p1, p2],
        status: 'inactive',
        tags: ['opp_hiring'],
        content: [
          {
            user_id: p1,
            content_id: p1,
            type: 'user',
          },
          {
            user_id: p2,
            content_id: p2,
            type: 'user',
          },
        ],
        data: [
          {
            user_id: p1,
            notes: '',
            status: null,
            updated: null,
          },
          {
            user_id: p2,
            notes: '',
            status: null,
            updated: null,
          },
        ],
      };

      await setOpp(opp);
      console.log('SUCCESS MAKING OPP', opp);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="card">
      <h4>Opportunities</h4>
      <button onClick={createopportunity}> CREATE OPP</button>
    </div>
  );
};

const OrgCreator = ({}) => {
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {
    state: {profiles, groups},
  } = useAuth();

  const createManualOrgs = async orgs => {
    try {
      if (!orgs) {
        return;
      }
      const ecos = {};
      await Promise.all(
        orgs.map(async emp => {
          const {name, live, skills, source, ...rest} = emp;
          if (live || !name) {
            return;
          }

          if (skills) {
            const {soft, hard} = separateSkills(skills);
            emp.soft_skills = soft;
            emp.hard_skills = hard;
          }

          const response = await listOrgs({
            filter: {name: {eq: name}},
            limit: 1000,
          });

          const exists = response.items.length;
          let id = response?.items?.[0]?.id ?? null;

          const org = {
            id: id || v4(),
            name: name,
            query_name: name
              .replace(constants.remove_special_chars_regex, '')
              .toUpperCase(),
            status: 'unactivated',
            owner: rest?.members?.length ? rest.members[0] : null,
            type: null,
            specific_type: null,
            industries: [],
            website: null,
            states: [],
            address: null,
            bio: null,
            members: [],
            invitations: [],
            hard_skills: [],
            soft_skills: [],
            degrees: [],
            majors: [],
            cities: [],
            opportunities: [],
            collaborators: [],
            member_count: null,
            position_count: null,
            workspaces: [],
            saved_orgs: [],
            ignored_orgs: [],
            expiration: null,
            subscription: null,
            last_seen: null,
            notification_settings: [],
            content: [],
            ...rest,
          };

          if (exists) {
            // await updateOrg(org);
            console.log('UPDATE', name, org.id);
          } else {
            if (source) {
              // await setSource(source);
              console.log('SOURCE CREATE');
            }

            await setOrg(org);
            console.log('CREATE', name, org.id);
          }
        }),
      );
    } catch (err) {
      console.log('ERR', err);
    }
  };

  const createManualPeople = async people => {
    try {
      if (!people) {
        return;
      }
      await Promise.all(
        people.map(async peep => {
          const {email, live, ...rest} = peep;
          if (live || !email) {
            return;
          }

          const exists = await fetchProfile(email);
          const user = {
            id: email,
            email,
            status: 'unactivated',
            sub: null,
            organization_ids: [],
            first_name: null,
            last_name: null,
            phone: null,
            position: null,
            bio: null,
            referral_org: null,
            referral_user: null,
            signup_timestamp: null,
            last_seen: null,
            ...rest,
          };

          if (exists) {
            console.log('UPDATE - DO NOTHING', email);
          } else {
            await setProfile(user);
            console.log('SUCCESS CREATE', email);
          }
        }),
      );
    } catch (err) {
      console.log('ERR', err);
    }
  };

  const separateSkills = array => {
    const soft = [];
    const hard = [];
    // array.forEach(item => {
    //   const exists = update_this.find(skill => item === skill.value);
    //   if (exists) {
    //     if (exists.type === 'hard') {
    //       hard.push(item);
    //     }
    //     if (exists.type === 'soft') {
    //       soft.push(item);
    //     }
    //   } else {
    //     console.log('SKILL DOESNT EXIST?');
    //   }
    // });
    return {soft, hard};
  };

  const convertOrgsToProfiles = async () => {
    try {
      const {items} = await listOrgs({limit: 10000});
      const {items: profile_items} = await fetchProfiles({limit: 10000});

      const all_profiles = {};
      profile_items.forEach(prof => {
        const {id} = prof;
        all_profiles[id] = prof;
      });

      await Promise.all(
        items.map(async org => {
          const {
            id: org_id,
            name,
            soft_skills,
            hard_skills,
            degrees,
            majors,
            opportunities,
            collaborators,
            members,
            specific_type,
          } = org;
          try {
            const combined = [
              ...(soft_skills || []),
              ...(hard_skills || []),
              ...(degrees || []),
              ...(majors || []),
              ...(opportunities || []),
              ...(collaborators || []),
            ];
            // CONVERT TYPE
            let updated_type = null;
            if (specific_type) {
              updated_type = oldTagsJSON[specific_type] || specific_type;
            }
            // CONVERT TAGS
            const mapped = combined.map(tag => {
              const updated = oldTagsJSON[tag];
              if (updated) {
                return updated;
              }
              const degree = oldTagsJSON.degrees[tag];
              return degree || tag;
            });

            // UPDATE EXISTING PROFILES
            if (members?.length ?? false) {
              await Promise.all(
                members.map(async user_id => {
                  const prof = all_profiles[user_id] || null;
                  if (prof) {
                    const {inner_tags, outer_tags} = prof;
                    // IF CURRENT TAGS EXIST KEEP THEM INSTEAD
                    // if (tags?.length) {
                    //   return
                    // }
                    await updateUserProfile({id: user_id, tags: mapped});
                  }
                }),
              );
              const updated_org = {
                id: org_id,
                specific_type: updated_type,
              };
              await updateOrg(updated_org);
            } else {
              // IF NO TAGS THEN IGNORE
              if (mapped?.length) {
                return;
              }
              // CREATE NEW PROFILES
              const default_profile = {
                id: v4(),
                email: null,
                status: 'bot',
                sub: null,
                organization_ids: [org_id],
                first_name: name + ' Admin',
                last_name: null,
                phone: null,
                position: null,
                bio: null,
                referral_org: null,
                referral_user: null,
                signup_timestamp: null,
                last_seen: null,
                tags: mapped,
              };
              const updated_org = {
                id: org_id,
                members: [...(members || []), default_profile.id],
                specific_type: updated_type,
              };
              await setProfile(default_profile);
              await updateOrg(updated_org);
            }
          } catch (err) {
            console.log('INNER ERROR', err);
          }
        }),
      );
      console.log(profiles);
    } catch (err) {
      console.log('HIGH LEVEL ERROR', err);
    }
  };

  const convertPostTags = async () => {
    try {
      const {items} = await listSortedPosts({status: 'active', limit: 10000});

      await Promise.all(
        items.map(async post => {
          try {
            const {id, tags} = post;
            const mapped = [...(tags || [])].map(tag => {
              const updated = oldTagsJSON[tag];
              if (updated) {
                return updated;
              }
              const degree = oldTagsJSON.degrees[tag];
              return degree || tag;
            });
            await updatePosts({id, tags: mapped});
          } catch (err) {
            console.log({post, err});
          }
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const removeDuplicateOrgs = async () => {
    try {
      const {items} = await listOrgs({limit: 10000});
      const orgs = {};
      await Promise.all(
        items.map(async org => {
          const {id, name, members} = org;
          if (orgs[name] === undefined) {
            orgs[name] = id;
          } else {
            // DELETE THE ORG
            // console.log(name, members);
            await removeOrg({id});
          }
        }),
      );
    } catch (err) {}
  };

  const removeEmptyBots = async () => {
    try {
      const {items} = await fetchProfiles({limit: 10000});
      await Promise.all(
        items.map(async prof => {
          const {id, status, tags} = prof;
          if (status === 'bot' && !tags.length) {
            await removeProfile({id});
          }
        }),
      );
    } catch (err) {}
  };

  const combineDuplicateURLS = async () => {
    try {
      const {items} = await fetchPageViews({limit: 10000});
      const url_objs = {};
      items.forEach(item => (url_objs[item.id] = item));
      const handled = {};

      const combine = async (url, item) => {
        console.log(url);
        const existing = url_objs[url];
        if (existing) {
          const {id, views, type, last_viewed} = existing;
          const updated_url = {
            id: url,
            views: views + item.views,
            type,
            last_viewed,
          };
          await updateView(updated_url);
          await deleteView({id: item.id});
          handled[url] = true;
        } else {
          const {id, views, type, last_viewed} = item;
          const new_url = {id: url, views, type, last_viewed};
          if (handled[url] === undefined) {
            await setPageView(new_url);
            handled[url] = true;
          } else {
            await updateView(new_url);
          }
          await deleteView({id: item.id});
        }
      };

      await Promise.all(
        items.map(async item => {
          const {id, views, type} = item;
          if (type !== 'page_view' || id.includes('/feed/')) {
            return;
          }
          if (id.includes('detail')) {
            if (id.includes('posts')) {
              const updated_id = '/feed' + id;
              await combine(updated_id, item);
            }
            if (id.includes('profiles')) {
              let updated_id = id.replace('home', 'feed');
              updated_id = updated_id.replace('search', 'feed');
              await combine(updated_id, item);
            }
            if (id.includes('organizations')) {
              let updated_id = id.replace('home', 'feed');
              updated_id = updated_id.replace('search', 'feed');
              await combine(updated_id, item);
            }
          }
        }),
      );
      console.log(items.length);
    } catch (err) {
      console.log(err);
    }
  };

  const convertPostsRR = async () => {
    try {
      const {items} = await listSortedPosts({
        status: 'active',
        limit: 10000,
      });

      await Promise.all(
        items.map(async post => {
          try {
            const {id, tags} = post;

            // await updatePosts({id, inner_tags: tags || []});
          } catch (err) {
            console.log({post, err});
          }
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const convertUsersRR = async () => {
    try {
      const {items} = await listOrgs({limit: 10000});
      const {items: profile_items} = await fetchProfiles({limit: 10000});

      const org_obj = {};
      items.forEach(org => {
        const {id} = org;
        org_obj[id] = org;
      });
      await Promise.all(
        profile_items.map(async user => {
          const {id, organization_ids, tags, outer_tags, inner_tags} = user;
          if (organization_ids?.length) {
            const org = org_obj?.[organization_ids[0]] ?? {};
            const {type} = org;
            if (type === 'employer') {
              // ADD EMPLOYER TAGS TO OUTER
              const updated = outer_tags || tags || [];
              await updateUserProfile({id, outer_tags: updated});
            } else {
              // ADD EDU TAGS TO INNER
              const updated = inner_tags || tags || [];
              await updateUserProfile({id, inner_tags: updated});
            }
          } else {
            // DEFAULT TO INNER
            const updated = inner_tags || tags || [];
            await updateUserProfile({id, inner_tags: updated});
          }
          console.log('SUC');
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addStateUsers = async () => {
    try {
      const {items: profile_items} = await fetchProfiles({limit: 10000});
      await Promise.all(
        profile_items.map(async user => {
          const {id, properties} = user;

          await updateUserProfile({
            id,
            properties: properties || ['state_SC'],
          });
          console.log('SUCCESS');
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const generateAllOpps = async () => {
    try {
      const {items} = await fetchProfiles({limit: 10000});

      await Promise.all(
        items.map(async user => {
          const {id, inner_tags, outer_tags, group_ids} = user;
          const body = {
            id,
            type: 'user',
            inner_added: inner_tags || [],
            inner_removed: [],
            outer_added: outer_tags || [],
            outer_removed: [],
            group_ids,
          };
          const response = await createOpportunities(body);
          console.log(response);
        }),
      );
      console.log('DONE');
    } catch (err) {
      console.log(err);
    }
  };

  const addStatePosts = async () => {
    try {
      const {items} = await fetchPosts({limit: 10000});
      await Promise.all(
        items.map(async post => {
          const {id, properties} = post;

          await updatePosts({
            id,
            properties: properties || ['state_SC'],
          });
          console.log('SUCCESS');
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const convertOppIds = async () => {
    try {
      const filter = {content_ids: {attributeExists: false}};
      const {items, nextToken} = await fetchOpps({
        limit: constants.all_items,
        filter,
      });

      let all_items = [...items];
      let token = nextToken;
      let i = 0;

      while (token && i < 50) {
        console.log(all_items.length);
        const {items, nextToken} = await fetchOpps({
          limit: constants.all_items,
          filter,
          nextToken: token,
        });

        all_items = [...all_items, ...items];
        token = nextToken;
        i++;
      }

      await Promise.all(
        all_items.map(async post => {
          try {
            const {id, content} = post;
            const mapped = content.map(item => item.content_id);
            await updateOpp({id, content_ids: mapped});
            console.log('S');
          } catch (err) {
            console.log({post, err});
          }
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addOppTimestamp = async () => {
    try {
      const filter = {timestamp: {attributeExists: false}};
      const {items, nextToken} = await fetchOpps({
        limit: constants.all_items,
        filter,
      });

      let all_items = [...items];
      let token = nextToken;
      let i = 0;

      while (token && i < 50) {
        console.log(all_items.length);
        const {items, nextToken} = await fetchOpps({
          limit: constants.all_items,
          filter,
          nextToken: token,
        });

        all_items = [...all_items, ...items];
        token = nextToken;
        i++;
      }

      await Promise.all(
        all_items.map(async post => {
          try {
            const {id, createdAt} = post;
            const date = new Date(createdAt);
            const timestamp = dateToTimestamp(date);
            await updateOpp({id, timestamp});
            console.log('S');
          } catch (err) {
            console.log({post, err});
          }
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const convertSubscriptions = async () => {
    try {
      const {items: profile_items} = await fetchProfiles({limit: 10000});
      const {items: org_items} = await listOrgs({limit: 10000});

      const orgs = {};
      org_items.forEach(org => {
        orgs[org.id] = org;
      });

      await Promise.all(
        profile_items.map(async profile => {
          const {id, organization_ids} = profile;
          if (organization_ids?.length) {
            const org = orgs[organization_ids[0]];
            if (org) {
              const {expiration, subscription} = org;
              const update = {id, expiration, subscription};
              await updateUserProfile(update);
              console.log('S');
            }
          }
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const correctReferrals = async () => {
    const {items} = await fetchReferrals({});

    await Promise.all(
      items.map(async ref => {
        const {id, status} = ref;
        // console.log(id, status);
        if (status === 'invited') {
          const profile = await fetchProfile(id);
          const {status: prof_status} = profile || {};
          if (profile && prof_status !== 'unactivated') {
            console.log(id, status, prof_status);
            await updateRef({id, status: 'signed_up'});
          }
        }
      }),
    );
  };

  const updateExpirations = async () => {
    try {
      const {items: profile_items} = await fetchProfiles({limit: 10000});

      await Promise.all(
        profile_items.map(async profile => {
          const {id, expiration, subscription} = profile;
          // IF THEY HAVE AN EXPIRATION, INCREASE IT
          if (subscription && expiration) {
            const now = dateToTimestamp() + constants.days_30;
            const update = {id, expiration: now};
            await updateUserProfile(update);

            console.log('S');
          }
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const addDefaultGroupToUsers = async () => {
    const {items: profile_items} = await fetchProfiles({limit: 10000});
    const default_group = 'be73f058-8ab4-465d-b08e-75ddfc802684';

    const group = groups?.[default_group] ?? {};
    const {members} = group;

    const ids = [...(members || [])];
    await Promise.all(
      profile_items.map(async profile => {
        const {id, group_ids} = profile;
        // IF THEY HAVE GROUPS IGNORE
        if (!group_ids?.length) {
          ids.push(id);
          const update = {id, group_ids: [default_group]};
          await updateUserProfile(update);

          console.log('S');
        }
      }),
    );

    await updateGrp({id: default_group, members: ids});
    console.log('COMPLETE');
  };

  const findGrouplessOpps = async () => {
    const filter = {groups: {attributeExists: false}};
    const {items, nextToken} = await fetchOpps({
      limit: constants.all_items,
      filter,
    });

    let all_items = [...items];
    let token = nextToken;
    let i = 0;

    while (token && i < 50) {
      console.log(all_items.length);
      const {items, nextToken} = await fetchOpps({
        limit: constants.all_items,
        filter,
        nextToken: token,
      });

      all_items = [...all_items, ...items];
      token = nextToken;
      i++;
    }

    console.log(all_items);
  };

  const clearArchivedOpps = async () => {
    try {
      const {items} = await fetchPosts({
        filter: {status: {eq: 'archived'}},
        limit: 10000,
      });

      console.log(items.length);
      await Promise.all(
        items.map(async item => {
          const {id} = item;
          const {items: opps} = await fetchOpps({
            filter: {content_ids: {contains: id}, status: {eq: 'inactive'}},
          });
          console.log(opps.length);
          await Promise.all(
            opps.map(async opp => {
              const {id: opp_id} = opp;
              await deleteOpp({id: opp_id});
            }),
          );
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const convertPostGroupsArray = async () => {
    const {items} = await fetchPosts({limit: 10000});

    await Promise.all(
      items.map(async post => {
        const {id, group_id} = post;

        if (group_id) {
          await updatePosts({
            id,
            group_ids: [group_id],
          });
          console.log('SUCCESS');
        } else {
          console.log('NO GROUP');
        }
      }),
    );
    console.log('DONE');
  };

  const resolveProfileStatus = async () => {
    const {items} = await fetchProfiles({limit: 10000});

    const default_user = 'jack@publicmind.ai';

    await Promise.all(
      items.map(async user => {
        const {id, status, referral_user} = user;

        if (status === 'unauthenticated') {
          await updateUserProfile({id, status: 'unactivated'});
          console.log('SUC STATUS', id);
        }
        if (!referral_user) {
          await updateUserProfile({id, referral_user: default_user});
          console.log('SUC REF', id);
        }
      }),
    );
    console.log('DONE');
  };

  const addPermissions = async () => {
    const {items} = await fetchThreads({limit: 10000});

    await Promise.all(
      items.map(async item => {
        const {id, owner_id, admins, subscribers} = item;
        const permissions = [];
        const now = dateToTimestamp();
        permissions.push({
          user_id: owner_id,
          role: 'owner',
          created: now,
          updated: now,
        });

        admins.forEach(admin => {
          const current = permissions.find(user => user.user_id === admin);
          if (!current) {
            permissions.push({
              user_id: admin,
              role: 'admin',
              created: now,
              updated: now,
            });
          }
        });

        subscribers.forEach(subscriber => {
          const current = permissions.find(user => user.user_id === subscriber);
          if (!current) {
            permissions.push({
              user_id: subscriber,
              role: 'subscriber',
              created: now,
              updated: now,
            });
          }
        });

        const members = permissions.map(item => item.user_id);

        await updateThreadChain({id, permissions, members});
        console.log('S');
      }),
    );
    console.log('DONE');
  };

  const cleanupThreadSubs = async () => {
    try {
      const {items} = await fetchAllThreadSubs({limit: constants.all_items});

      // console.log(items.length);

      const mapped = {};
      items.forEach(item => {
        const {user_id, thread_id, last_read} = item;
        if (mapped[user_id] === undefined) {
          mapped[user_id] = {};
        }
        if (mapped[user_id][thread_id] === undefined) {
          mapped[user_id][thread_id] = {recent: null, to_delete: []};
        }

        const best = mapped[user_id][thread_id].recent;
        if (!best) {
          mapped[user_id][thread_id].recent = item;
        } else if (last_read > best.last_read) {
          mapped[user_id][thread_id].to_delete.push(best.id);
          mapped[user_id][thread_id].recent = item;
        } else {
          mapped[user_id][thread_id].to_delete.push(item.id);
        }
      });

      const users = Object.keys(mapped);
      await Promise.all(
        users.map(async user => {
          const threads = Object.keys(mapped[user]);

          await Promise.all(
            threads.map(async thread_id => {
              const {to_delete} = mapped[user][thread_id];
              console.log(user, to_delete?.length);

              await Promise.all(
                to_delete.map(async sub_id => {
                  await removeThreadSub({id: sub_id});
                  console.log(user, 'S');
                }),
              );
            }),
          );
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const updateMultiChoicePolls = async () => {
    const {items} = await fetchUnsortedThreadContents({
      limit: constants.all_items,
      filter: {type: {eq: 'poll'}, sub_type: {eq: 'multi_choice_poll'}},
    });

    await Promise.all(
      items.map(async item => {
        const {type, sub_type, responses} = item;
        const mapped = [];
        responses.forEach(r => {
          const {response, user_id} = r;
          if (!response?.length) {
            return;
          }
          const split = response.split(',');
          split.forEach(split => {
            if (!split?.length) {
              return;
            }
            mapped.push({user_id: user_id, response: split});
          });
        });

        console.log(responses?.length, mapped?.length);

        await updateThreadContentMessage({id: item.id, responses: mapped});
      }),
    );
  };

  const editor = useCreateBlockNote();

  const convert_old_content = async () => {
    const options = {
      limit: 1000,
      filter: {
        // owner_id: {eq: 'chcaswell10@gmail.com'},
        // mentions: {contains: 'chcaswell10@gmail.com'},

        version: {ne: 'block'},
        // thread_id: {eq: '43133a4a-8644-4667-a66e-c40bacb78777'},
      },
    };
    const {items, nextToken} = await fetchUnsortedThreadContents(options);
    let all_items = [...items];
    let token = nextToken;

    while (token) {
      console.log(token);
      const {items, nextToken} = await fetchUnsortedThreadContents({
        ...options,
        nextToken: token,
      });
      all_items = [...all_items, ...items];
      token = nextToken;
    }

    console.log(all_items.length, token);

    try {
      await Promise.all(
        all_items.map(async item => {
          const {content} = item;

          const blocks = await editor.tryParseMarkdownToBlocks(content);
          const isLetterListItem = text => {
            // Match patterns like "a.", "b.", "A.", "B." at the start of text
            return /^[a-zA-Z]\.\s/.test(text);
          };

          const isListItem = type => {
            return type === 'numberedListItem' || type === 'bulletListItem';
          };

          const processBlock = block => {
            const {content, type} = block;

            if (!Array.isArray(content)) {
              return block;
            }

            const processedContent = content
              .map(c => {
                const {type, content, href, text} = c;

                if (type === 'text' && text === '@') {
                  return null;
                }

                if (type === 'link') {
                  if (href.includes('/feed/profiles/detail/')) {
                    const first = content?.[0] ?? null;
                    const {text} = first;
                    const split = href.split('/');
                    const email = split[split.length - 1];
                    return {
                      type: 'mention',
                      props: {
                        user: text,
                        email,
                      },
                    };
                  }
                  return c;
                }

                return c;
              })
              .filter(item => item !== null);

            let processedBlock = {...block, content: processedContent};

            if (type === 'heading') {
              // processedBlock = {...processedBlock, type: 'paragraph'};
            }

            return processedBlock;
          };

          const nestBlocks = blocks => {
            const result = [];
            let letterListParent = null;

            for (let i = 0; i < blocks.length; i++) {
              const block = processBlock(blocks[i]);
              const nextBlock = i < blocks.length - 1 ? blocks[i + 1] : null;

              // Handle lettered lists
              if (
                block.type === 'paragraph' &&
                block.content?.[0]?.text &&
                isLetterListItem(block.content[0].text)
              ) {
                const convertedBlock = {
                  ...block,
                  type: 'bulletListItem',
                  content: [
                    {
                      ...block.content[0],
                      text: block.content[0].text.replace(/^[a-zA-Z]\.\s/, ''),
                    },
                  ],
                  children: [],
                };

                letterListParent = convertedBlock;
                result.push(convertedBlock);
              } else if (isListItem(block.type) && letterListParent) {
                letterListParent.children.push(block);

                if (!nextBlock || !isListItem(nextBlock.type)) {
                  letterListParent = null;
                }
              } else {
                letterListParent = null;
                result.push(block);
              }
            }

            return result;
          };

          const mapped = nestBlocks(blocks);

          const stringy = JSON.stringify(mapped);
          const content_html = await editor.blocksToHTMLLossy();

          await updateThreadContentMessage({
            id: item.id,
            content: stringy,
            content_html,
            version: 'block',
          });
          console.log('S');
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  const editor2 = useCreateBlockNote({
    schema: schema,
    trailingBlock: false,
  });

  const updateContentHtml = async () => {
    try {
      const options = {
        limit: 1000,
        filter: {
          version: {eq: 'block'},
        },
      };
      const {items, nextToken} = await fetchUnsortedThreadContents(options);
      let all_items = [...items];
      let token = nextToken;

      while (token) {
        console.log(token);
        const {items, nextToken} = await fetchUnsortedThreadContents({
          ...options,
          nextToken: token,
        });
        all_items = [...all_items, ...items];
        token = nextToken;
      }

      console.log(all_items.length, token);

      console.log(all_items);

      for (const item of all_items) {
        console.log({item});
        const {content, id, content_html} = item; // Assuming each item has an id field
        const parsed = JSON.parse(content);
        try {
          if (content_html) {
            // console.log('exists', content_html);
            return;
          }

          const blocks = editor2.document;

          // Load the content into the editor
          editor2.replaceBlocks(blocks, parsed);

          console.log(editor2.document);

          // Convert to HTML
          // const html = await editor2.blocksToHTMLLossy();

          // console.log({html});
        } catch (itemError) {
          console.error(`Error processing item:`, itemError, parsed);
          // Continue with next item even if current one fails
          continue;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const testing_content = async () => {
    const temp = `#### **Relevant Sub-Control Mappings** [DRAFT]

##### **NIST 800-53 v5 | CIS 8.1 | MITTRE ATT&CK TTP's**

##### **Beginner:**
ID.AM-1: Physical devices and systems within the organization are inventoried

---

##### **Intermediate:**
- **CIS v8.1-Safeguard 1.1:** Establish and Maintain Detailed Asset Inventory

>Establish and maintain an accurate, detailed, and up-to-date inventory of all enterprise assets with the potential to store or process data, to include: end-user devices (including portable and mobile), network devices, non-computing/IoT devices, and servers. Ensure the inventory records the network address (if static), hardware address, machine name, enterprise asset owner, department for each asset, and whether the asset has been approved to connect to the network. For mobile end-user devices, MDM type tools can support this process, where appropriate. This inventory includes assets connected to the infrastructure physically, virtually, remotely, and those within cloud environments. Additionally, it includes assets that are regularly connected to the enterprise’s network infrastructure, even if they are not under control of the enterprise. Review and update the inventory of all enterprise assets bi-annually, or more frequently.

---

##### **Advanced**

- **NIST 800-53 r5 - CM-8:** SYSTEM COMPONENT INVENTORY

**Control:** 

a. Develop and document an inventory of system components that: 
> 1. Accurately reflects the system; 
> 2. Includes all components within the system; 
> 3. Does not include duplicate accounting of components or components assigned to any other system; 
> 4. Is at the level of granularity deemed necessary for tracking and reporting; and 
> 5. Includes the following information to achieve system component accountability: [Assignment: organization-defined information deemed necessary to achieve effective system component accountability]; and 

b. Review and update the system component inventory [Assignment: organization-defined frequency].

- **NIST 800-53 r5 -  PM-5:** SYSTEM INVENTORY  
> **Control:**  Develop and update [Assignment: organization-defined frequency] an inventory of organizational systems. 


##### **Relevant MITRE ATT&CK TTPs:**

- **T1135:** Network Share Discovery - Maintaining an inventory of assets helps prevent adversaries from discovering unmonitored assets or network shares that may be vulnerable.

- **T1016:** System Network Configuration Discovery - Knowing what assets are on the network helps limit the exposure from adversaries trying to map the network.


`;

    const blocks = await editor.tryParseMarkdownToBlocks(temp);

    const isLetterListItem = text => {
      // Match patterns like "a.", "b.", "A.", "B." at the start of text
      return /^[a-zA-Z]\.\s/.test(text);
    };

    const isListItem = type => {
      return type === 'numberedListItem' || type === 'bulletListItem';
    };

    const processBlock = block => {
      const {content, type} = block;

      if (!Array.isArray(content)) {
        return block;
      }

      const processedContent = content
        .map(c => {
          const {type, content, href, text} = c;

          if (type === 'text' && text === '@') {
            return null;
          }

          if (type === 'link') {
            if (href.includes('/feed/profiles/detail/')) {
              const first = content?.[0] ?? null;
              const {text} = first;
              const split = href.split('/');
              const email = split[split.length - 1];
              return {
                type: 'mention',
                props: {
                  user: text,
                  email,
                },
              };
            }
            return c;
          }

          return c;
        })
        .filter(item => item !== null);

      let processedBlock = {...block, content: processedContent};

      if (type === 'heading') {
        // processedBlock = {...processedBlock, type: 'paragraph'};
      }

      return processedBlock;
    };

    const nestBlocks = blocks => {
      const result = [];
      let letterListParent = null;

      for (let i = 0; i < blocks.length; i++) {
        const block = processBlock(blocks[i]);
        const nextBlock = i < blocks.length - 1 ? blocks[i + 1] : null;

        // Handle lettered lists
        if (
          block.type === 'paragraph' &&
          block.content?.[0]?.text &&
          isLetterListItem(block.content[0].text)
        ) {
          const convertedBlock = {
            ...block,
            type: 'bulletListItem',
            content: [
              {
                ...block.content[0],
                text: block.content[0].text.replace(/^[a-zA-Z]\.\s/, ''),
              },
            ],
            children: [],
          };

          letterListParent = convertedBlock;
          result.push(convertedBlock);
        } else if (isListItem(block.type) && letterListParent) {
          letterListParent.children.push(block);

          if (!nextBlock || !isListItem(nextBlock.type)) {
            letterListParent = null;
          }
        } else {
          letterListParent = null;
          result.push(block);
        }
      }

      return result;
    };

    const mapped = nestBlocks(blocks);
    console.log(JSON.stringify(mapped));
    return mapped;
  };

  return (
    <div className="card">
      <button
        onClick={async () => {
          await updateContentHtml();
          // testing_content();
          // await convert_old_content();
          // await createDefaultThreads();
          // await correctReferrals();
          // await updateExpirations();
          // await addDefaultGroupToUsers();
          // await findGrouplessOpps();
          // await clearArchivedOpps();
          // await addDefaultGroupToPosts();
          // await convertPostGroupsArray();
          // await resolveProfileStatus();
          // await addPermissions();
          // await cleanupThreadSubs();
          // await updateMultiChoicePolls();
        }}>
        CURRENT
      </button>
      <button
        onClick={async () => {
          // await correctReferrals();
        }}>
        CORRECT REFERRALS
      </button>
      <button
        onClick={async () => {
          // await convertSubscriptions();
        }}>
        UDPATE SUBS TO PROFILES
      </button>
      <button
        onClick={async () => {
          // await addOppTimestamp();
        }}>
        ADD TIMESTAMP OPPS
      </button>
      <button
        onClick={async () => {
          // await convertOppIds();
        }}>
        ADD CONTENT_IDS OPPS
      </button>
      <button
        onClick={async () => {
          await generateAllOpps();
        }}>
        GEN ALL OPPS
      </button>
      <button
        onClick={async () => {
          // await convertPostsRR();
        }}>
        Convert Posts to REQUEST RESOURCES
      </button>
      <button
        onClick={async () => {
          // await convertUsersRR();
        }}>
        Convert Users to REQUEST RESOURCES
      </button>
      <button
        onClick={async () => {
          // await convertPostTags();
        }}>
        Convert Posts to new tags
      </button>
      <button
        onClick={async () => {
          // await convertOrgsToProfiles();
        }}>
        Convert Orgs To Profiles
      </button>
      <button
        onClick={async () => {
          // await addStateUsers();
        }}>
        ADD SC TO PROFILES
      </button>
      <button
        onClick={async () => {
          // await addStatePosts();
        }}>
        ADD SC TO POSTS
      </button>
      {/* <button
        onClick={async () => {
          await combineDuplicateURLS();
        }}>
        CONVERT URLS
      </button> */}
      {/* <button
        onClick={async () => {
          await removeEmptyBots();
        }}>
        REMOVE EMPTY BOTS
      </button> */}
      {/* <button
        onClick={async () => {
          await removeDuplicateOrgs();
        }}>
        DELETE DUPLICATE ORGS BY NAME
      </button> */}
      <button
        onClick={async () => {
          // await createManualOrgs(manualOrgs);
        }}>
        Manual Orgs from array
      </button>
      <button
        onClick={async () => {
          // await createManualPeople(manualPeople);
        }}>
        Manual People from array
      </button>
      {/* <button
        onClick={async () => {
          try {
            const email = 'chcaswell10@gmail.com';
            await setWaitlist({
              id: email,
              email,
              timestamp: dateToTimestamp(),
              referred_by: null,
            });
          } catch (err) {
            console.log(err);
          }
        }}>
        WAITLIST TESTING
      </button>
      <button
        onClick={() => {
          const val = formatNumber(9.1);
          console.log({val});
        }}>
        TESTING
      </button> */}
    </div>
  );
};

const Organizations = ({}) => {
  const [name, setName] = useState('');
  const [owner, setOwner] = useState('');
  const [type, setType] = useState('');
  const [bio, setBio] = useState('');
  const [industries, setIndustries] = useState([]);
  const [states, setStates] = useState([]);

  const createOrg = async () => {
    try {
      const query_name = name
        .replace(constants.remove_special_chars_regex, '')
        .toUpperCase();
      // const org = {
      //   id: v4(),
      //   name,
      //   query_name,
      //   status: 'unactivated',
      //   owner,
      //   type,
      //   industries,
      //   website: null,
      //   domains: [],
      //   states,
      //   address: null,
      //   bio,
      //   admin: [owner],
      //   members: [owner],
      //   connectors: [],
      //   expiration: new Date().getTime() + constants.days_30,
      //   subscription: '3 month',
      //   parent: null,
      //   children: [],
      //   last_seen: null,
      // };

      const full_org = {
        id: v4(),
        name: 'TESTING',
        query_name: 'TESTING',
        status: 'complete',
        verified: '',
        goal: '',
        owner: 'chcaswell10@gmail.com',
        type: 'employer',
        industries: ['Tech'],
        website: '',
        domains: [],
        states: ['NC', 'SC'],
        address: 'Charlotte, NC',
        bio: 'Something',
        admin: ['chcaswell10@gmail.com'],
        members: ['chcaswell10@gmail.com'],
        hard_skills: ['2.A.1.a', '2.A.1.c'],
        soft_skills: ['2.A.2.b'],
        degrees: ['5'],
        majors: ['14'],
        cities: ['Charleston'],
        opportunities: ['Internship Programs'],
        collaborators: ['community_colleges'],
        member_count: '50',
        position_count: '',
        connectors: [],
        expiration: null,
        subscription: '',
        parent: '',
        children: [],
        last_seen: null,
      };

      // await setOrg(orgs.scrwa);
      // console.log('SUCCESS CREATING ORG', orgs.scrwa);
    } catch (err) {
      console.log('ERR', err);
    }
  };

  const createSCEdus = async () => {
    const clean_website = url => {
      if (!url.startsWith('https://')) {
        // If not, prepend 'https://'
        url = 'https://' + url;
      }

      // Check for any other changes or validations
      // (You can add more validations or changes here if needed)

      return url;
    };
    await Promise.all(
      sc_ipeds.map(async edu => {
        try {
          const {UNITID, INSTNM, ADDR, WEBADDR} = edu;
          const org = {
            id: v4(),
            name: INSTNM,
            query_name: INSTNM.replace(
              constants.remove_special_chars_regex,
              '',
            ).toUpperCase(),
            status: 'unactivated',
            owner: null,
            type: 'educator',
            website: clean_website(WEBADDR),
            address: ADDR,
            bio: '',
            members: [],
            expiration: null,
            subscription: null,
            last_seen: null,
            hard_skills: [],
            soft_skills: [],
            degrees: [],
            majors: [],
            cities: [],
            opportunities: [],
            collaborators: [],
            member_count: '',
            position_count: '',
            workspaces: [],
            saved_orgs: [],
            ignored_orgs: [],
          };
          const source = {
            id: v4(),
            organization_id: org.id,
            source: 'ipeds',
            source_id: UNITID,
            pretty: INSTNM,
            description: '',
          };
          // console.log(org, source);
          await setOrg(org);
          // await setSource(source);
          console.log('SUCCESS');
        } catch (err) {
          console.log('ERR', err);
        }
      }),
    );
  };

  return (
    <div className="card">
      <h4>Organizations</h4>
      <div className="grid-container">
        <div className="grid-6">
          <Textfield
            value={name}
            header="Name"
            onChange={e => setName(e.target.value)}
          />
          <Textfield
            value={owner}
            header="Owner"
            onChange={e => setOwner(e.target.value)}
          />
          <Textfield
            value={bio}
            header="Bio"
            onChange={e => setBio(e.target.value)}
          />
          <SearchableDropDown
            header="Type"
            setValue={setType}
            items={typesJSON}
          />
          <ArraySearchableDropDown
            header="Industries"
            values={industries}
            setValues={setIndustries}
            items={industriesJSON}
          />
          <ArraySearchableDropDown
            header="States"
            values={states}
            setValues={setStates}
            items={statesJSON}
            mappings={{key: 'abbreviation', value: 'abbreviation'}}
          />
          <button onClick={createOrg}>create org</button>
          {/* <button onClick={createSCEdus}>create SC EDUS</button> */}
        </div>
      </div>
    </div>
  );
};

const Profiles = ({}) => {
  const {copyString} = useUtilities();
  const {paramsToData, dataToParams} = useSearchParameters();
  const [email, setEmail] = useState('');
  const [url, setURL] = useState(null);

  const createProf = async () => {
    try {
      // const prof = {
      //   id: email,
      //   email,
      //   status: 'unactivated',
      //   sub: null,
      //   organization_ids: ['todo'],
      //   first_name: null,
      //   last_name: null,
      //   phone: null,
      //   position: null,
      //   bio: null,
      //   referral_org: null,
      //   referral_user: null,
      //   signup_timestamp: null,
      //   last_seen: null,
      // };

      const prof = {
        id: 'jack@publicmind.ai',
        email: 'jack@publicmind.ai',
        status: 'unactivated',
        sub: null,
        organization_ids: ['53ec6d2b-c292-4dd7-a9e0-d9863f29239c'],
        first_name: null,
        last_name: null,
        phone: null,
        position: null,
        bio: null,
        referral_org: null,
        referral_user: null,
        signup_timestamp: null,
        last_seen: null,
      };

      // await setProfile(people.kate);
      // console.log('SUCCESS PROF', people.kate);
    } catch (err) {
      console.log('ERR', err);
    }
  };

  const currentUser = async () => {
    try {
      const user = await getCurrentAccount();
      console.log(user);
    } catch (err) {
      console.log('ERR', err);
    }
  };

  const createUser = async () => {
    try {
      const email = 'ccaswell@finsiteful.com';
      const password = 'Riven2023!';
      const attributes = {
        email,
      };

      await createAccount(email, password, attributes);
    } catch (err) {
      console.log('ERR', err);
    }
  };

  const confirmAccount = async () => {
    try {
      const email = 'chcaswell10@gmail.com';
      const code = '660459';

      await confirmUser(email, code);
    } catch (err) {
      console.log('ERR', err);
    }
  };

  const createLink = () => {
    const hash = dataToParams({id: email});
    // const hash = dataToParams({status: 'active', referred_by: 'scbio'});
    const link = `${constants.signin_url}?${hash}`;
    copyString(link);
    setURL(link);
  };

  return (
    <div className="card">
      <h4>Profiles</h4>
      <div className="grid-container">
        <div className="grid-6">
          <Textfield
            value={email}
            header="Email"
            onChange={e => setEmail(e.target.value)}
          />
          {url && <p>{url}</p>}
          <button onClick={createProf}>create prof</button>
          <button onClick={createLink}>invite link</button>
        </div>
        <div className="grid-6">
          <button onClick={currentUser}>current user</button>
          <button onClick={createUser}>create user</button>
          <button onClick={confirmAccount}>confirm user</button>
        </div>
      </div>
    </div>
  );
};

function formatNumber(number) {
  // Check if the input is a valid number

  // Convert the number to a string
  const numberString = number.toString();

  // Check the number of decimal places
  let integer = numberString.split('.')[0] || '';
  let decimal = numberString.split('.')[1] || '';
  const integerDigits = integer.length;
  const decimalPlaces = decimal.length;

  if (integerDigits < 2) {
    integer = numberToString(integer, 2);
  }

  switch (decimalPlaces) {
    case 1:
      decimal = numberToString(decimal, 2, true);
      return integer + '.' + decimal;
    case 3:
      decimal = numberToString(decimal, 4, true);
      return integer + '.' + decimal;
    case 0:
      return integer;
    case 2:
    case 4:
    default:
      return integer + '.' + decimal;
  }
}

const numberToString = (number, size, end) => {
  try {
    let num = number.toString();
    while (num.length < size) {
      if (end) {
        num = num + '0';
      } else {
        num = '0' + num;
      }
    }
    return num;
  } catch (err) {
    return number.toString();
  }
};

export default MasterSettings;
