import {filterSuggestionItems} from '@blocknote/core';
import {BlockNoteView} from '@blocknote/mantine';
import {
  getDefaultReactSlashMenuItems,
  SuggestionMenuController,
} from '@blocknote/react';
import {default as React, useEffect, useMemo, useRef} from 'react';
import {MdOutlineWorkspaces} from 'react-icons/md';
import {RiLayoutGridLine} from 'react-icons/ri';
import {RxArrowUp} from 'react-icons/rx';
import {insertDividerLineItem} from '../../../components/blocks/divider';
import {blockInputTheme} from '../../../components/inputs/block-input';
import {getDocumentSectionCount} from '../modals/ai-document-generator/ai-document-state';
import './AIWorkspace.css';
import SectionIndicator from './document-section-indicator';

const AIWorkspace = ({
  editor,
  currentConfig,
  streamingContent,
  isStreaming,
  isLoading,
  activeSection,
  setActiveSection,
}) => {
  const contentRef = useRef(null);
  const totalSections = getDocumentSectionCount(editor);

  const isEmpty = useMemo(() => {
    return (
      editor.document?.length === 1 &&
      editor.document?.[0].content?.length === 0
    );
  }, [editor.document]);

  const getCurrentTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? blockInputTheme.dark : blockInputTheme.light;
  };

  // Parse markdown content into blocks
  const parseContent = text => {
    if (!text) return [];

    const lines = text.split('\n');
    const blocks = [];
    let currentList = null;

    lines.forEach(line => {
      const trimmedLine = line.trim();

      if (line.startsWith('# ')) {
        blocks.push({type: 'heading', content: trimmedLine.slice(2), level: 1});
        currentList = null;
      } else if (line.startsWith('## ')) {
        blocks.push({type: 'heading', content: trimmedLine.slice(3), level: 2});
        currentList = null;
      } else if (line.startsWith('### ')) {
        blocks.push({type: 'heading', content: trimmedLine.slice(4), level: 3});
        currentList = null;
      } else if (trimmedLine.startsWith('- ') || trimmedLine.startsWith('* ')) {
        const content = trimmedLine.slice(2);
        currentList = {type: 'bulletListItem', content};
        blocks.push(currentList);
      } else if (trimmedLine === '---') {
        // blocks.push({type: 'divider'});
        // currentList = null;
      } else if (trimmedLine !== '') {
        blocks.push({type: 'paragraph', content: trimmedLine});
        currentList = null;
      } else if (trimmedLine === '') {
        currentList = null;
      }
    });

    return blocks;
  };

  // Handle streaming content updates
  useEffect(() => {
    if (editor && streamingContent) {
      try {
        let section = 0;

        const replacedBlocks = [];
        editor.forEachBlock(block => {
          if (block?.type === 'divider') {
            section += 1;
          } else {
            if (section === activeSection) {
              replacedBlocks.push(block);
            }
          }
        });

        const blocks = parseContent(streamingContent);
        // IF BLOCKS TO REPLACE
        if (replacedBlocks?.length) {
          editor.replaceBlocks(replacedBlocks, blocks);
        } else {
          // OTHERWISE ADD TO END
          const all_blocks = editor.document;
          const last = all_blocks[all_blocks?.length - 1];
          editor.insertBlocks(blocks, last, 'after');
        }

        if (contentRef.current) {
          contentRef.current.scrollTo({
            top: contentRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      } catch (error) {
        console.error('Error processing streaming content:', error);
      }
    }
  }, [streamingContent, editor]);

  //Change Active Section
  useEffect(() => {
    if (!editor || isStreaming) return;

    const unsubscribe = editor.onSelectionChange(() => {
      const currentBlock = editor.getTextCursorPosition()?.block;
      if (!currentBlock) return;

      let sectionCount = 0;
      let foundSection = false;

      editor.forEachBlock(block => {
        if (foundSection) return;
        if (currentBlock.id === block.id) {
          setActiveSection(sectionCount);
          foundSection = true;
        }
        if (block.type === 'divider') {
          sectionCount++;
        }
      });
    });

    return () => unsubscribe();
  }, [editor, isStreaming]);

  return (
    <div
      style={{
        height: '100%',
        overflowY: 'scroll',
        transition: 'height 0.3s ease',
      }}>
      {!isEmpty || isStreaming || isLoading ? (
        <div className="ai-workspace-content content-body" ref={contentRef}>
          <BlockNoteView
            editor={editor}
            slashMenu={false}
            editable={!isStreaming}
            theme={getCurrentTheme()}>
            <SuggestionMenuController
              triggerCharacter={'/'}
              getItems={async query => {
                const defaultItems = await getDefaultReactSlashMenuItems(
                  editor,
                );
                const dividerItem = insertDividerLineItem(editor);
                return filterSuggestionItems(
                  [...defaultItems, dividerItem],
                  query,
                );
              }}
              preventBackgroundScroll={true}
            />
          </BlockNoteView>
          <SectionIndicator
            currentConfig={currentConfig}
            activeSection={activeSection}
            totalSections={totalSections}
            onSectionClick={index => {
              // Find the target section's position and scroll to it
              let currentSection = 0;
              editor.forEachBlock(block => {
                if (currentSection === index) {
                  const element = document.querySelector(
                    `[data-block-id="${block.id}"]`,
                  );
                  element?.scrollIntoView({behavior: 'smooth'});
                  setActiveSection(index);
                  return;
                }
                if (block.type === 'divider') {
                  currentSection++;
                }
              });
            }}
          />
          {isStreaming && <div className="ai-workspace-cursor" />}
        </div>
      ) : (
        <EmptyAIWorkspace />
      )}
    </div>
  );
};

export default AIWorkspace;

const EmptyAIWorkspace = () => {
  return (
    <div className="ai-workspace-empty">
      <div className="ai-workspace-empty-header">
        <h1 className="ai-workspace-empty-title">
          Generate a New Document in Seconds
        </h1>
        {/* <p className="ai-workspace-empty-subtitle">
          Generate professional documents by chatting with PublicMind. Start
          from scratch or use a template, then organize your content into
          sections with dedicated knowledge sources.
        </p> */}
      </div>

      <div className="ai-workspace-empty-steps">
        <div className="ai-workspace-empty-step">
          <div className="step-number-workspace">
            <RiLayoutGridLine size={20} />
          </div>
          <h3 className="step-title-workspace">Select a Template</h3>
          <p className="step-description-workspace">
            Select a template below from emails to presentations to reports
          </p>
        </div>
        <div className="ai-workspace-empty-step">
          <div className="step-number-workspace">
            <MdOutlineWorkspaces size={20} />
          </div>

          <h3 className="step-title-workspace">Add Knowledge</h3>
          <p className="step-description-workspace">
            Add workspaces or files so PublicMind understands your work
          </p>
        </div>
        <div className="ai-workspace-empty-step">
          <div className="step-number-workspace">
            <RxArrowUp size={20} />
          </div>

          <h3 className="step-title-workspace">Send a Message to Start</h3>
          <p className="step-description-workspace">
            Tell PublicMind about the document then send it
          </p>
        </div>
      </div>
    </div>
  );
};
