import React, {useCallback, useEffect, useState} from 'react';
import {FaConnectdevelop} from 'react-icons/fa';
import {
  RiCheckLine,
  RiErrorWarningLine,
  RiInformationLine,
  RiRefreshLine,
  RiTimeLine,
} from 'react-icons/ri';
import ReactMarkdown from 'react-markdown';
import {
  dateToTimestamp,
  readableDateandTime,
  timeSince,
} from '../../../api/dates';
import {isProUser} from '../../../components/system-status/system-status';

import {useAI} from '../../../hooks/use-ai';
import {useAuth} from '../../../hooks/use-auth';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {
  AI_INTERACTION_TYPES,
  AIInteractionManager,
} from '../../../manager/promptSystem';
import './AIInsights.css';

const INSIGHTS_STORAGE_KEY = 'workspace_insights';
const INSIGHTS_TTL = 60 * 60 * 1000; // 60 minutes

const TypingIndicator = () => (
  <div className="insights-typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </div>
);

const StatusMessage = ({message, type, onDismiss}) => {
  useEffect(() => {
    if (onDismiss) {
      const timer = setTimeout(onDismiss, 5000);
      return () => clearTimeout(timer);
    }
  }, [onDismiss]);

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <RiErrorWarningLine />;
      case 'success':
        return <RiCheckLine />;
      default:
        return <RiInformationLine />;
    }
  };

  return (
    <div className={`ai-insights-status ${type}`}>
      <div className="ai-insights-status-content">
        {getIcon()}
        <span>{message}</span>
      </div>
    </div>
  );
};

const AIInsights = ({
  threads,
  thread_content,
  thread_subs,
  currentUserId,
  unreadPosts,
}) => {
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState('');
  const [lastUpdated, setLastUpdated] = useState(null);
  const [status, setStatus] = useState(null);

  const {prettyName} = useStringFormatter();
  const {
    state: {profile, id, profiles},
  } = useAuth();
  const {state, getDailyUsage, getUsageStats, recordUsage} = useAI();

  const aiManager = React.useMemo(
    () => new AIInteractionManager(id, recordUsage),
    [],
  );

  const showStatus = useCallback((message, type = 'info') => {
    setStatus({message, type});
  }, []);

  const dismissStatus = useCallback(() => {
    setStatus(null);
  }, []);

  const getRelevantUpdates = useCallback(() => {
    const timestamp = profile?.last_seen;
    return Object.values(thread_content)
      .filter(content => {
        const thread = threads[content?.thread_id];
        const isUnread = unreadPosts?.some(item => item.id === content?.id);
        return (
          (content?.status === 'active' &&
            content?.created > timestamp &&
            content?.owner_id !== currentUserId) ||
          (isUnread && content?.version === 'block')
        );
      })
      .sort((a, b) => b.created - a.created);
  }, [thread_content, unreadPosts]);

  const getLast5Updates = useCallback(() => {
    return Object.values(thread_content)
      .filter(content => {
        return (
          content?.status === 'active' && content?.owner_id !== currentUserId
        );
      })
      .sort((a, b) => b.created - a.created)
      .slice(0, 5);
  }, [thread_content]);

  const checkCachedInsights = useCallback(() => {
    try {
      const cachedInsights = localStorage.getItem(INSIGHTS_STORAGE_KEY);
      if (cachedInsights && unreadPosts?.length === 0) {
        const {data, timestamp} = JSON.parse(cachedInsights);
        if (Date.now() - timestamp * 1000 < INSIGHTS_TTL) {
          const minutesRemaining = Math.max(
            0,
            Math.ceil((INSIGHTS_TTL - (Date.now() - timestamp * 1000)) / 60000),
          );
          showStatus(
            `Summary is current. Next refresh available in ${minutesRemaining} minutes.`,
            'info',
          );
          setLastUpdated(timestamp);
          return {data, isCurrent: true};
        }
      }
      return {isCurrent: false};
    } catch (error) {
      console.error('Error checking cached insights:', error);
      localStorage.removeItem(INSIGHTS_STORAGE_KEY); // Clear corrupted cache
      return {isCurrent: false};
    }
  }, [unreadPosts]);

  const loadInsights = async () => {
    const {monthly} = getUsageStats(id)(state);

    if (monthly.remaining <= 0) {
      showStatus('Monthly usage limit exceeded.', 'error');
      setSummary(
        '## Usage Limit Exceeded\n\nYou have reached your monthly usage limit. Please email [help@publicmind.ai](mailto:help@publicmind.ai) to increase your limit.',
      );
      setLoading(false);
      return;
    }
    const {data: cachedData, isCurrent} = checkCachedInsights();

    if (isCurrent) {
      setSummary(cachedData);
      setLoading(false);

      return;
    }

    setLoading(true);
    setSummary('');

    try {
      const updates = getRelevantUpdates();

      // Prepare user context
      const userContext = {
        name: prettyName(profile),
        position: profile?.position,
        bio: profile?.bio,
        accessLevel: isProUser(id) ? 'Pro User' : 'Standard User',
      };
      // Use AIInteractionManager to generate content
      await aiManager.generateContent({
        interactionType: AI_INTERACTION_TYPES.WORKSPACE_INTEL,
        user: userContext,
        threadUpdates: updates.length > 0 ? updates : getLast5Updates(),
        additionalConfig: {
          profiles,
          baseThreads: threads,
          baseContent: thread_content,
          currentTime: readableDateandTime(profile?.last_seen),
          rootUrl: window.location.origin,
        },
        callbacks: {
          onUpdate: newContent => {
            setSummary(prev => prev + newContent);
          },
          onComplete: response => {
            const timestamp = Math.floor(Date.now() / 1000);
            localStorage.setItem(
              INSIGHTS_STORAGE_KEY,
              JSON.stringify({
                data: response.content,
                timestamp,
              }),
            );
            setLastUpdated(timestamp);
            showStatus('Workspace Summary updated successfully', 'success');
          },
          onError: error => {
            console.error('Error generating insights:', error);
            showStatus(
              'Unable to process workspace updates. Please try again.',
              'error',
            );
            setSummary(
              '# Workspace Summary Temporarily Unavailable\n\nI apologize for the interruption. Please try refreshing in a moment.',
            );
          },
          onStateChange: ({to}) => {
            if (to === 'streaming') {
              setLoading(false);
            }
          },
        },
      });
    } catch (error) {
      console.error('Error loading insights:', error);
      showStatus(
        'Unable to process workspace updates. Please try again.',
        'error',
      );
    } finally {
      setLoading(false);
    }
  };

  // Load insights on mount
  useEffect(() => {
    loadInsights();
    // Cleanup: cancel any ongoing operations when component unmounts
    return () => {
      aiManager.cancel();
    };
  }, []);

  return (
    <div className="ai-insights-container">
      {status && (
        <StatusMessage
          message={status.message}
          type={status.type}
          onDismiss={dismissStatus}
        />
      )}

      <div className="ai-insights-header">
        <h3 className="ai-insights-title">
          <div
            className={`ai-insights-brain-icon ${loading ? 'processing' : ''}`}>
            <FaConnectdevelop />
          </div>
          Summary
          <p className="text-secondary">
            Short summary of all recent updates across all workspaces
          </p>
        </h3>
        <button
          className="ai-insights-refresh"
          onClick={loadInsights}
          disabled={loading}>
          <RiRefreshLine />
          {loading ? 'Processing...' : 'Refresh'}
        </button>
      </div>

      <div className={`ai-insights-content ${loading ? 'loading' : ''}`}>
        <div className="insights-message">
          {loading && !summary && <TypingIndicator />}
          {summary && <ReactMarkdown>{summary}</ReactMarkdown>}
        </div>
      </div>

      {lastUpdated && (
        <div className="last-update-info">
          <RiTimeLine />
          Summary updated {timeSince(lastUpdated)}
        </div>
      )}
    </div>
  );
};

export const GenericAIResponseContainer = ({
  loading,
  onRefresh,
  text,
  lastUpdated = dateToTimestamp(),
}) => {
  return (
    <div className="ai-insights-container">
      <div className="ai-insights-header">
        <h3 className="ai-insights-title">
          <div
            className={`ai-insights-brain-icon ${loading ? 'processing' : ''}`}>
            <FaConnectdevelop />
          </div>
          Workspace Intelligence
        </h3>
        {onRefresh && (
          <button
            className="ai-insights-refresh"
            onClick={onRefresh}
            disabled={loading}>
            <RiRefreshLine />
            {loading ? 'Processing...' : 'Refresh'}
          </button>
        )}
      </div>

      <div className={`ai-insights-content ${loading ? 'loading' : ''}`}>
        <div className="insights-message">
          {loading && !text && <TypingIndicator />}
          {text && <ReactMarkdown>{text}</ReactMarkdown>}
        </div>
      </div>

      {lastUpdated && (
        <div className="last-update-info">
          <RiTimeLine />
          Intelligence updated {timeSince(lastUpdated)}
        </div>
      )}
    </div>
  );
};

export default AIInsights;
