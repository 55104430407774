import React, {useEffect, useMemo, useState} from 'react';
import {GoGitBranch} from 'react-icons/go';
import {RiGroupLine} from 'react-icons/ri';

import {
  RxCheck,
  RxChevronDown,
  RxChevronUp,
  RxDotFilled,
  RxFile,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {track} from '../../../api/analytics';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import '../ThreadStyles.css';
import './thread-list.css';

// DISPLAYS THE LEFT THREAD SIDEBAR
export const ThreadList = ({current, setCurrent, setIndex}) => {
  const navigate = useNavigate();
  const {
    state: {id: userId},
  } = useAuth();
  const {
    state: {threads, thread_ids, thread_subs},
  } = useThreads();

  const [status, setStatus] = useState('active');

  const groupedThreads = useMemo(() => {
    const groups = {
      active: {
        pinned: [],
        unread: [],
        other: [],
      },
      draft: {pinned: [], unread: [], other: []},
      complete: {
        pinned: [],
        unread: [],
        other: [],
      },
    };

    thread_ids.forEach(threadId => {
      const thread = threads[threadId];
      const sub = thread_subs?.[threadId] ?? null;
      const {type, status} = thread || {};

      if (type === 'direct') {
        return;
      }

      const append = status => {
        // Check if thread is pinned and is a sub-thread
        if (sub?.priority) {
          groups[status].pinned.push(threadId);
          return; // Skip further categorization for pinned sub-threads
        }

        // Check if thread is unread
        const isUnread =
          userId !== thread.last_sender &&
          (!sub?.last_read || sub?.last_read < thread.updated);

        if (isUnread) {
          groups[status].unread.push(threadId);
          return;
        }

        if (sub?.group) {
          if (groups[status][sub?.group] === undefined) {
            groups[status][sub?.group] = [];
          }
          groups[status][sub?.group].push(threadId);
          return;
        }

        groups[status].other.push(threadId);
      };

      switch (status) {
        case 'draft':
          append('draft');
          break;
        case 'complete':
          append('complete');
          break;
        case 'active':
        default:
          append('active');
      }
    });

    // Sort threads within each group
    const sortThreads = (a, b) => threads[b].updated - threads[a].updated;

    Object.keys(groups).forEach(status => {
      Object.keys(groups[status]).forEach(key => {
        groups[status][key].sort(sortThreads);
      });
    });

    const prio = type => {
      switch (type) {
        case 'pinned':
          return 2;
        case 'unread':
          return 1;
        case 'other':
          return -1;
        default:
          return 0;
      }
    };

    const sorted = {};
    Object.keys(groups).forEach(status => {
      const section = Object.keys(groups[status])
        .sort((a, b) => {
          return prio(b) - prio(a);
        })
        .map(key => {
          return {section: key, thread_ids: groups[status][key]};
        });
      sorted[status] = section;
    });

    return sorted;
  }, [thread_ids, threads, thread_subs, userId]);

  useEffect(() => {
    if (!current) {
      return;
    }

    const drafts = groupedThreads.draft;
    const active = groupedThreads.complete;
    const complete = groupedThreads.complete;

    Object.keys(drafts).forEach(key => {
      const {thread_ids} = drafts[key];
      if (thread_ids?.includes(current)) {
        setStatus('draft');
      }
    });
    Object.keys(active).forEach(key => {
      const {thread_ids} = active[key];
      if (thread_ids?.includes(current)) {
        setStatus('active');
      }
    });
    Object.keys(complete).forEach(key => {
      const {thread_ids} = complete[key];
      if (thread_ids?.includes(current)) {
        setStatus('complete');
      }
    });
  }, [current, groupedThreads]);

  return (
    <>
      <div className="status-buttons">
        <button
          title="Collaborative workspaces"
          className={`status-button status-button--active ${
            status === 'active' ? 'active' : ''
          }`}
          onClick={() => setStatus('active')}>
          <RiGroupLine className="status-button__icon" />
          {status === 'active' && (
            <span className="status-button__text">Group</span>
          )}
        </button>
        <button
          title="Personal Workspaces"
          className={`status-button status-button--draft ${
            status === 'draft' ? 'active' : ''
          }`}
          onClick={() => setStatus('draft')}>
          <RxFile className="status-button__icon" />
          {status === 'draft' && (
            <span className="status-button__text">Personal</span>
          )}
        </button>
        <button
          title="Completed Threads"
          className={`status-button status-button--complete ${
            status === 'complete' ? 'active' : ''
          }`}
          onClick={() => setStatus('complete')}>
          <RxCheck className="status-button__icon" />
          {status === 'complete' && (
            <span className="status-button__text">Closed</span>
          )}
        </button>
      </div>
      <div>
        {!groupedThreads[status].some(
          group => group?.thread_ids?.length > 0,
        ) && (
          <div className="empty-workspace-list">
            <p>
              Your {status === 'draft' ? 'personal' : 'collaborative'}{' '}
              workspaces will appear here
            </p>
            <button
              onClick={() => {
                navigate('/threads/create');
              }}
              className="basic-button padding-top8">
              Create Workspace
            </button>
          </div>
        )}
        {groupedThreads[status].map(group => {
          const {section, thread_ids} = group;
          if (thread_ids?.length === 0) return null;
          return (
            <Section
              key={section}
              title={section}
              thread_ids={thread_ids}
              current={current}
              setIndex={setIndex}
              setCurrent={setCurrent}
            />
          );
        })}
      </div>
    </>
  );
};

const Section = ({title, thread_ids, current, setIndex, setCurrent}) => {
  const navigate = useNavigate();
  const {
    state: {threads},
  } = useThreads();

  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    if (thread_ids?.includes(current)) {
      setToggle(true);
    }
  }, [current, thread_ids]);

  if (thread_ids?.length === 0) return null;

  return (
    <div className="thread-group">
      <h5
        className="thread-group__title clickable"
        onClick={() => {
          setToggle(!toggle);
        }}>
        {title} {toggle ? <RxChevronDown /> : <RxChevronUp />}
      </h5>
      {toggle && (
        <div className="">
          {thread_ids.map(threadId => (
            <ThreadItem
              key={threadId}
              item={threads[threadId]}
              isActive={current === threadId}
              onClick={() => {
                setIndex('thread-detail');
                setCurrent(threadId);
                navigate(`/threads/${threadId}`);
                track('clicked_thread_sidebar', {
                  thread_group: title,
                  thread_name: threads?.[threadId] || 'Unknown',
                  thread_id: threadId,
                });
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const ThreadItem = ({item, onClick, isActive}) => {
  const {
    id,
    title,
    content,
    updated,
    last_sender,
    logo,
    custom_logo,
    type,
    parent,
  } = item;

  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {thread_subs, threads, thread_content},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  const new_content =
    user_id !== last_sender && (!sub?.last_read || sub?.last_read < updated);

  return (
    <div
      className={`thread-item ${isActive ? 'active' : ''} ${
        type === 'thread' ? 'top-thread' : ''
      } justify-between`}
      style={{
        borderLeft: `4px solid ${sub?.color ?? null}`,
      }}
      onClick={onClick}>
      <span
        className={`flex-row align-center ${new_content ? 'text-bold' : ''}`}>
        {type === 'sub-thread' ? (
          <GoGitBranch
            className="text-secondary padding-right4"
            size={'14px'}
          />
        ) : (
          ''
        )}
        {custom_logo && (
          <img
            src={custom_logo?.url}
            alt="Logo"
            style={{
              height: '16px',
              maxWidth: '32px',
              width: 'auto',
              marginRight: '2px',
            }}
          />
        )}
        <p className="thread-item__title">
          {logo} {title}
          {type === 'sub-thread' &&
            threads?.[thread_content?.[parent]?.thread_id] && (
              <span className="sub-thread-indicator">
                {' '}
                ({threads?.[thread_content?.[parent]?.thread_id]?.title})
              </span>
            )}
          {type === 'direct' && (
            <span className="sub-thread-indicator"> (Direct)</span>
          )}
        </p>
      </span>
      <RxDotFilled color={new_content ? 'red' : 'transparent'} />
    </div>
  );
};
