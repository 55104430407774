import React, {useEffect, useRef, useState} from 'react';
import {FaConnectdevelop} from 'react-icons/fa';
import {RxUpdate} from 'react-icons/rx';
import './AIFloating.css';

const FloatingAssistantIcon = ({isGenerating = false, onLaunch}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [status, setStatus] = useState('initializing');
  const initialLoadComplete = useRef(false);

  useEffect(() => {
    // Handle initial load state
    if (!initialLoadComplete.current && isGenerating) {
      setStatus('initializing');
      setShowMessage(true);
      return;
    }

    if (isGenerating) {
      setStatus('reviewing');
      setShowMessage(true);
    } else {
      // Only transition to ready if we've completed initial load
      if (!initialLoadComplete.current) {
        initialLoadComplete.current = true;
      }
      setStatus('ready');

      // Smooth transition to hide message
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isGenerating]);

  const getMessage = () => {
    switch (status) {
      case 'initializing':
        return 'Initializing...';
      case 'reviewing':
        return 'Analyzing thread...';
      case 'ready':
        return 'Agent Ready';
      default:
        return '';
    }
  };

  return (
    <div
      className={`floating-assistant ${status === 'ready' ? 'clickable' : ''}`}
      onClick={() => {
        if (status === 'ready') onLaunch();
      }}
      title={status === 'ready' ? 'Chat with PublicMind' : getMessage()}>
      <div className="floating-assistant-container">
        {showMessage ? (
          <div className="floating-assistant-message">
            <div className="floating-assistant-icon">
              {status !== 'ready' ? (
                <RxUpdate className="floating-assistant-icon--processing" />
              ) : (
                <FaConnectdevelop className="floating-assistant-icon--ready" />
              )}
            </div>
            <span className="floating-assistant-text">{getMessage()}</span>
          </div>
        ) : (
          <div className="floating-assistant-intelligence">
            <div className="floating-assistant-ring">
              <svg viewBox="0 0 24 24">
                <circle
                  className="floating-assistant-ring__bg"
                  cx="12"
                  cy="12"
                  r="10"
                  strokeWidth="2"
                />
                <circle
                  className={`floating-assistant-ring__progress ${
                    status === 'ready' ? 'active' : ''
                  }`}
                  cx="12"
                  cy="12"
                  r="10"
                  strokeWidth="2"
                  strokeDasharray="62.8, 62.8"
                />
              </svg>
              <FaConnectdevelop
                className={`floating-assistant-brain ${
                  status === 'ready' ? 'floating-assistant-brain--active' : ''
                }`}
              />
            </div>
          </div>
        )}
      </div>
      {!showMessage && (
        <p className="text-secondary text-center text-12">Chat</p>
      )}
    </div>
  );
};

export default FloatingAssistantIcon;
