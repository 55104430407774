/**
 * AI Context Management System
 *
 * This system manages AI usage tracking, cost calculations, and resource allocation
 * for an AI-powered application focused on public sector use cases. It implements
 * sophisticated usage limits, cost tracking, and resource optimization to ensure
 * responsible AI usage.
 *
 * The system has several key responsibilities:
 * 1. Track and limit AI usage across different time periods
 * 2. Calculate costs for different AI models and operation types
 * 3. Validate operations against usage limits
 * 4. Maintain usage history and statistics
 *
 * @module ai-context
 */

import {v4} from 'uuid';
import {dateToTimestamp} from '../api/dates';
import {fetchAIUsage, recordAIUsage} from '../api/graphql';
import createDataContext from './create-data-context';

/**
 * Usage limits configuration defining maximum spending amounts and safety buffers
 * for different time periods. The buffer amounts help prevent accidental overages
 * by creating a safety margin below the absolute maximum.
 */
const USAGE_LIMITS = {
  DAILY: {
    amount: 25.0, // Daily spending limit in dollars
    buffer: 2.5, // Safety margin to prevent overage
  },
  MONTHLY: {
    amount: 500.0, // Monthly spending limit in dollars
    buffer: 25.0, // Monthly safety buffer
  },
};

/**
 * AI model pricing configuration defining costs per page (400 tokens) for different
 * models and operation types. Each model has separate pricing for input and output
 * operations to reflect their different computational costs.
 */
const AI_PRICING = {
  'claude-3-5-sonnet-20241022': {
    input: 0.1, // Cost per page for input operations
    output: 0.5, // Cost per page for output operations
  },
  'llama-v3p1-8b-instruct': {
    input: 0.01, // Lower cost alternative for input
    output: 0.01, // Lower cost alternative for output
  },
};

/**
 * Standard tokens per page used for cost calculations. This constant helps convert
 * between token counts and page-based pricing.
 */
export const TOKENS_PER_PAGE = 400;

/**
 * Calculates the cost of an AI operation based on token count, model, and operation type.
 *
 * This function converts token usage into pages and applies the appropriate pricing
 * based on the AI model and whether it's an input or output operation.
 *
 * @param {number} tokens - Number of tokens used in the operation
 * @param {string} model - Identifier for the AI model used
 * @param {string} type - Operation type ('input' or 'output')
 * @returns {number} The calculated cost in dollars
 */
const calculateOperationCost = (tokens, model, type) => {
  const pages = tokens / TOKENS_PER_PAGE;
  const pricePerPage = AI_PRICING[model]?.[type] ?? 0;
  return pages * pricePerPage;
};

/**
 * Calculates available spending based on historical usage records and time period.
 * This function analyzes past usage to determine remaining budget and provides
 * comprehensive spending metrics.
 *
 * @param {Array} usageRecords - Historical usage records
 * @param {string} userId - User identifier
 * @param {string} timeframe - Time period to analyze ('daily' or 'monthly')
 * @returns {Object} Spending metrics including available budget, spent amount, and limits
 */
const calculateAvailableSpending = (
  usageRecords,
  userId,
  timeframe = 'daily',
) => {
  // Calculate start of current period
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Get appropriate timestamp based on timeframe
  const startTimestamp =
    timeframe === 'daily'
      ? dateToTimestamp(today)
      : dateToTimestamp(new Date(today.getFullYear(), today.getMonth(), 1));

  // Calculate total spend for the period by filtering relevant records
  // and summing their costs
  const periodSpend = usageRecords
    .filter(
      record => record.user_id === userId && record.created >= startTimestamp,
    )
    .reduce((total, record) => {
      const operationCost = calculateOperationCost(
        record.tokens_used,
        record.model_used,
        record.token_type,
      );
      return total + operationCost;
    }, 0);

  const limits =
    timeframe === 'daily' ? USAGE_LIMITS.DAILY : USAGE_LIMITS.MONTHLY;

  return {
    available: Math.max(0, limits.amount - periodSpend - limits.buffer),
    spent: periodSpend,
    limit: limits.amount,
    remaining: Math.max(0, limits.amount - periodSpend),
  };
};

/**
 * Estimates the cost of a planned operation before execution.
 * This function helps with proactive budget management by providing
 * cost projections.
 *
 * @param {number} tokens - Estimated token usage
 * @param {string} model - Target AI model
 * @param {string} type - Operation type
 * @returns {number} Estimated cost in dollars
 */
const estimateOperationCost = (tokens, model, type) => {
  return calculateOperationCost(tokens, model, type);
};

/**
 * Checks if a planned operation is viable given current usage limits and history.
 * This function validates operations against both daily and monthly limits,
 * considering buffer amounts.
 *
 * @param {number} cost - Estimated operation cost
 * @param {string} userId - User identifier
 * @param {Array} usageRecords - Usage history
 * @returns {Object} Viability status and reason if not viable
 */
const checkOperationViability = (cost, userId, usageRecords) => {
  const {available: dailyAvailable} = calculateAvailableSpending(
    usageRecords,
    userId,
    'daily',
  );
  const {available: monthlyAvailable} = calculateAvailableSpending(
    usageRecords,
    userId,
    'monthly',
  );

  return {
    viable: cost <= dailyAvailable && cost <= monthlyAvailable,
    reason:
      cost > dailyAvailable
        ? 'Daily spending limit would be exceeded'
        : cost > monthlyAvailable
        ? 'Monthly spending limit would be exceeded'
        : null,
  };
};

/**
 * Creates a function to check if an operation can be performed given current limits.
 * This higher-order function combines cost estimation and viability checking.
 */
const canPerformOperation =
  dispatch => (userId, estimatedTokens, model, type) => state => {
    const estimatedCost = estimateOperationCost(estimatedTokens, model, type);
    return checkOperationViability(estimatedCost, userId, state.usageRecords);
  };

/**
 * Reducer function for managing AI context state.
 * Handles state updates for usage records, errors, and loading status.
 */
const aiReducer = (state, action) => {
  switch (action.type) {
    case 'set_usage_records':
      return {
        ...state,
        usageRecords: action.payload,
        loaded: true,
      };
    case 'add_usage_record':
      return {
        ...state,
        usageRecords: [...state.usageRecords, action.payload],
      };
    case 'set_error':
      return {
        ...state,
        error: action.payload,
      };
    case 'set_loading':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

/**
 * Initializes the AI context system for a user.
 * Loads historical usage data and sets up initial state.
 *
 * @param {string} user_id - User identifier
 * @returns {Promise<Object>} Initialization status
 */
const init = dispatch => async user_id => {
  try {
    dispatch({type: 'set_loading', payload: true});

    // Fetch all usage records with pagination
    let allRecords = [];
    let nextToken = null;

    do {
      const {success, data, error} = await fetchAIUsage(user_id, {
        limit: 1000,
        nextToken,
      });

      if (!success) {
        throw new Error(error);
      }

      allRecords = [...allRecords, ...data.items];
      nextToken = data.nextToken;
    } while (nextToken);

    dispatch({
      type: 'set_usage_records',
      payload: allRecords,
    });

    dispatch({type: 'set_loading', payload: false});
    return {success: true};
  } catch (error) {
    console.error('Failed to fetch AI usage:', error);
    dispatch({type: 'set_loading', payload: false});
    dispatch({
      type: 'set_error',
      payload: error.message,
    });
    return {success: false, error: error.message};
  }
};

/**
 * Records a new AI usage entry.
 * Calculates costs and updates usage records in state.
 *
 * @param {Object} item - Usage details including tokens, model, and type
 * @returns {Promise<Object>} Recording status
 */
const recordUsage = dispatch => async item => {
  try {
    const cost = calculateOperationCost(
      item.tokens_used,
      item.model_used,
      item.token_type,
    );

    const usageRecord = {
      id: v4(),
      created: dateToTimestamp(),
      cost,
      ...item,
    };

    await recordAIUsage(usageRecord);

    dispatch({
      type: 'add_usage_record',
      payload: usageRecord,
    });

    return {success: true};
  } catch (error) {
    console.error('Failed to record AI usage:', error);
    dispatch({
      type: 'set_error',
      payload: error.message,
    });
    return {success: false, error: error.message};
  }
};

/**
 * Retrieves current usage statistics for a user.
 * Provides comprehensive metrics for both daily and monthly usage.
 *
 * @param {string} userId - User identifier
 * @returns {Object} Usage statistics including daily and monthly metrics
 */
const getUsageStats = dispatch => userId => state => {
  try {
    const daily = calculateAvailableSpending(
      state.usageRecords,
      userId,
      'daily',
    );
    const monthly = calculateAvailableSpending(
      state.usageRecords,
      userId,
      'monthly',
    );

    return {
      success: true,
      daily,
      monthly,
    };
  } catch (error) {
    console.error('Failed to calculate usage stats:', error);
    return {
      success: false,
      error: error.message,
    };
  }
};

/**
 * Default state values for the AI context system.
 */
const defaultValues = {
  usageRecords: [],
  error: null,
  loaded: false,
  loading: false,
};

/**
 * Available functions for interacting with the AI context system.
 */
const functions = {
  init,
  recordUsage,
  getUsageStats,
  canPerformOperation,
};

/**
 * Create and export the context provider and context object.
 */
export const {Provider, Context} = createDataContext(
  aiReducer,
  functions,
  defaultValues,
);

export {
  AI_PRICING,
  calculateOperationCost,
  estimateOperationCost,
  USAGE_LIMITS,
};
