import {useFormik} from 'formik';
import React, {useRef, useState} from 'react';
import {FaConnectdevelop} from 'react-icons/fa';
import {IoMdArrowBack} from 'react-icons/io';
import {MdOutlineWorkspaces} from 'react-icons/md';
import {RiGroupLine} from 'react-icons/ri';
import {RxFile, RxLockClosed, RxLockOpen1, RxLockOpen2} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {track} from '../../../api/analytics';
import {dateToTimestamp} from '../../../api/dates';
import {sendThreadInvitations} from '../../../api/email';
import tagsJSON from '../../../assets/lists/tags.json';
import {FormikArraySearchableDropDown} from '../../../components/inputs/drop-down';
import {
  BulkEmailUploader,
  PermissionsInput,
} from '../../../components/inputs/email-input';
import {EmojiInput} from '../../../components/inputs/emoji-input';
import {
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../../components/inputs/textfields';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {useAuth} from '../../../hooks/use-auth';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import {RenderMemberContent} from '../modals/ThreadInfoPopup';
import './CreateThread.css';

const CreateThread = ({}) => {
  return (
    <div className="page-container">
      <CreateThreadChain />
    </div>
  );
};

const CreateThreadChain = ({}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();

  const {
    state: {id, profiles, profile},
  } = useAuth();
  const {createThread} = useThreads();

  const [file, setFile] = useState(null);
  const [emojiPicker, setEmoji] = useState(false);
  const now = dateToTimestamp();

  const formik = useFormik({
    initialValues: {
      id: v4(),
      owner_id: id,
      status: 'draft',
      type: 'thread',
      security_level: 'open',
      permissions: [{user_id: id, role: 'owner', created: now, updated: now}],
      media: [],
      custom_logo: null,
      tags: [],
      title: '',
      content: '',
      logo: '',
      parent: null,
      expiration: null,
      ai_assisted: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {permissions} = values;
        const members = permissions.map(permission => permission.user_id);
        const thread = {
          ...values,
          members,
          created: now,
          updated: now,
        };
        if (file) {
          thread.custom_logo = file;
        }
        const {success, error} = await createThread(thread);
        if (success) {
          track('thread_created', {thread, user_id: id});
          helpers.resetForm();
          navigate(`/threads/${thread?.id}`);
          const other_members = members.filter(mem => mem !== id);
          if (other_members?.length) {
            const {success, error} = await sendThreadInvitations({
              emails: other_members,
              thread,
              profile: prettyName(profile),
            });
          }
        }
        if (error) {
          // TODO
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  const {security_level} = formik?.values;

  const pretty_security_levels = {
    open: 'Public',
    private: 'Private',
    secure: 'Secure',
  };

  return (
    <div className="create-thread">
      <br />
      <IoMdArrowBack
        className="clickable padding-top8"
        onClick={() => {
          navigate(-1);
        }}
      />
      <br />
      <div className="create-thread__header">
        <span className="flex-row align-center">
          <MdOutlineWorkspaces size={32} className="padding-right8" />
          <h2>PublicMind Workspaces</h2>
        </span>
        <p className="text-secondary">
          Workspaces are personal or collaborative smart containers for your
          work. Our workspaces are organized as a simple timeline of updates.
          Create one below.
        </p>
      </div>
      <StatusToggle formik={formik} />
      <br />

      <div className="create-thread__header-small">
        <h4>Workspace Details</h4>
        <p className="text-secondary">
          Customize your workspace title, description, and logo. Only title is
          required.
        </p>
      </div>
      <div className="create-thread__form pretty-form-group">
        <div className="create-thread__field ">
          <FormikTextfield
            formik={formik}
            name={'title'}
            header={`Title (${
              pretty_security_levels?.[security_level] ?? security_level
            })`}
          />
        </div>
        <div className="create-thread__field">
          <MultilineFormikTextfield
            formik={formik}
            name={'content'}
            header={`Description (${
              pretty_security_levels?.[security_level] ?? security_level
            })`}
            rows={8}
          />
        </div>
        <ThreadLogoUpload
          formik={formik}
          emojiPicker={emojiPicker}
          setEmoji={setEmoji}
        />
        <br />

        {formik.values.status === 'active' && (
          <>
            <div className="create-thread__header-small">
              <h4>Workspace Security Level</h4>
              <p className="text-secondary">
                Workspace are a secure way to host and work on your projects.
                You can choose the security level below.
              </p>
            </div>
            <TypeToggle formik={formik} />
            <div className="create-thread__header-small">
              <h4>Bulk Add Collaborators</h4>
              <p className="text-secondary">
                Collaborators are people working on the project with you. Add a
                CSV or copy and paste emails to bulk add them. Be sure to press
                the "Add Collaborators" button.
              </p>
            </div>
            <BulkEmailUploader formik={formik} />
            <div className="create-thread__header-small">
              <h4>Add Individuals and Specify Roles</h4>
              <p className="text-secondary">
                If you need to add other administrators to the project, do that
                here. Administrators have edit access on the thread. You will be
                able to invite more people after you create the thread via a
                simple link.
              </p>
            </div>
            <PermissionsInput
              formik={formik}
              name="permissions"
              header="Thread Members"
            />
            <br />
            <p className="text-bold">Workspace Members</p>
            <RenderMemberContent
              thread={formik.values}
              button={{
                text: 'Remove',
                onClick: async item => {
                  const {user_id} = item;
                  const {permissions} = formik?.values ?? {};
                  const filtered_permissions = permissions?.filter(
                    user => user.user_id !== user_id,
                  );
                  formik.setFieldValue('permissions', filtered_permissions);
                },
              }}
            />
          </>
        )}
        <div className="create-thread__submit-container">
          <button
            className="create-thread__submit-button"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            Create{' '}
            {formik.values.status === 'active' && (
              <strong>
                {pretty_security_levels?.[security_level] ?? security_level}
              </strong>
            )}{' '}
            {formik.values.status !== 'active' && <strong>Personal</strong>}{' '}
            Workspace
          </button>
        </div>
      </div>
    </div>
  );
};

export const UpdateThread = ({formik, isSmall}) => {
  const [emojiPicker, setEmoji] = useState(false);

  const {status, security_level} = formik.values;

  const active = status === 'active';
  const secure = security_level === 'secure';

  return (
    <div className="create-thread">
      <div className="create-thread__header">
        {!isSmall && <h4>Update Your Workspace</h4>}
      </div>
      {active && !secure && (
        <PublicPrivateToggle formik={formik} minimal={true} />
      )}
      <div className="create-thread__form pretty-form-group">
        <ThreadLogoUpload
          formik={formik}
          emojiPicker={emojiPicker}
          setEmoji={setEmoji}
        />
        <div className="create-thread__field">
          <FormikTextfield formik={formik} name={'title'} header="Title" />
        </div>
        <div className="create-thread__field">
          <MultilineFormikTextfield
            formik={formik}
            name={'content'}
            header="Description"
            rows={8}
          />
        </div>

        <FormikArraySearchableDropDown
          formik={formik}
          header="Tags"
          name="tags"
          items={tagsJSON}
          mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
        />
        <div className="create-thread__submit-container">
          <button
            type="submit"
            className="create-thread__submit-button"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateThread;

export const StatusToggle = ({formik}) => {
  const {
    state: {id},
  } = useAuth();

  const {status} = formik.values;

  const getSettings = () => {
    switch (status) {
      case 'draft':
        return {
          label: 'Personal',
          css_class: 'status-draft',
          description: 'Create private draft, change to Group if/when ready.',
        };
      case 'active':
      default:
        return {
          label: 'Group',
          css_class: 'status-active',
          description: 'Create a collaborative workspace and add others.',
        };
    }
  };

  const {label, css_class, description} = getSettings();

  return (
    <div className="status-toggle">
      <button
        className={`status-toggle__button ${css_class}`}
        aria-label="Toggle Thread Status">
        <span
          className="status-toggle__icon status-toggle__icon--secure"
          onClick={() => {
            const now = dateToTimestamp();
            formik.setFieldValue('status', 'draft');
            formik.setFieldValue('permissions', [
              {user_id: id, role: 'owner', created: now, updated: now},
            ]);
          }}>
          <RxFile />
        </span>
        <span
          className="status-toggle__icon status-toggle__icon--open"
          onClick={() => {
            formik.setFieldValue('status', 'active');
          }}>
          <RiGroupLine />
        </span>
        <span className="status-toggle__slider"></span>
      </button>
      <p className="status-toggle__label">{label}</p>
      <p className="public-private-toggle__description">{description}</p>
    </div>
  );
};

export const TypeToggle = ({formik, minimal = false}) => {
  const {security_level} = formik?.values;

  const getDescriptionText = () => {
    switch (security_level) {
      case 'secure':
        return (
          <span className="flex-column align-center">
            <p className="text-secondary">
              Invite-only. Not discoverable. For sensitive information.{' '}
            </p>
            {/* <p className="text-bold text-red">
              This setting cannot be changed after creation
            </p> */}
          </span>
        );
      case 'private':
        return (
          <span className="flex-column align-center">
            <p className="text-secondary">
              Invite-only. Discoverable by members.
            </p>
            {/* <p className="text-secondary">
              You can change this setting to Public later
            </p> */}
          </span>
        );
      case 'open':
        return (
          <span className="flex-column align-center">
            <p className="text-secondary">
              Public. Anyone can discover and join.
            </p>
            {/* <p className="text-secondary">
              You can change this setting to Private later
            </p> */}
          </span>
        );
      default:
        return '';
    }
  };
  const getLabelText = () => {
    switch (security_level) {
      case 'secure':
        return 'Secure Workspace';
      case 'private':
        return 'Private Workspace';
      case 'open':
        return 'Public Workspace';
      default:
        return '';
    }
  };
  const getToggleClass = () => {
    if (security_level === 'secure') return 'secure-active';
    if (security_level === 'private') return 'private-active';
    return 'discoverable-active';
  };

  return (
    <div className="ai-toggle">
      <button
        className={`type-toggle-button ${getToggleClass()}`}
        aria-label="Toggle Workspace Type">
        <span
          className="toggle-icon secure-icon"
          onClick={() => {
            formik.setFieldValue('security_level', 'secure');
          }}>
          <RxLockClosed />
        </span>
        <span
          className="toggle-icon private-icon"
          onClick={() => {
            formik.setFieldValue('security_level', 'private');
          }}>
          <RxLockOpen1 />
        </span>
        <span
          className="toggle-icon discoverable-icon"
          onClick={() => {
            formik.setFieldValue('security_level', 'open');
          }}>
          <RxLockOpen2 />
        </span>
        <span className="toggle-slider"></span>
      </button>

      <p className="text-bold">{getLabelText()}</p>
      {!minimal && getDescriptionText()}
    </div>
  );
};
export const NewToggle = ({type, setType, minimal = false}) => {
  const getDescriptionText = () => {
    switch (type) {
      case 'draft':
        return (
          <span className="flex-column align-center">
            {/* <p className="text-bold">Draft Thread </p> */}
            <p className="text-secondary">
              Create private draft, publish when ready.
            </p>
          </span>
        );
      case 'active':
        return (
          <span className="flex-column align-center">
            {/* <p className="text-bold">Live Thread</p> */}
            <p className="text-secondary">
              Create a Live Workspace and add members
            </p>
          </span>
        );
      case 'complete':
        return (
          <span className="flex-column align-center">
            {/* <p className="text-bold">Completed Thread</p> */}
            <p className="text-secondary">
              This Workspace will be added to your completed workspaces and your
              knowledge base.
            </p>
          </span>
        );
      default:
        return '';
    }
  };
  const getLabelText = () => {
    switch (type) {
      case 'complete':
        return 'Completed Workspace';
      case 'draft':
        return 'Draft Workspace';
      case 'active':
        return 'Live Workspace';
      default:
        return '';
    }
  };
  const getToggleClass = () => {
    if (type === 'draft') return 'draft-active';
    if (type === 'active') return 'live-active';
    return 'discoverable-active';
  };

  return (
    <div className="ai-toggle">
      <button
        className={`type-toggle-button ${getToggleClass()}`}
        aria-label="Toggle Workspace Type">
        <span
          className="toggle-icon draft-icon"
          onClick={() => {
            setType('draft');
          }}>
          <RxFile />
        </span>
        <span
          className="toggle-icon live-icon"
          onClick={() => {
            setType('active');
          }}>
          <RiGroupLine />
        </span>
        <span
          className="toggle-icon discoverable-icon"
          onClick={() => {
            setType('complete');
          }}>
          <FaConnectdevelop />
        </span>
        <span className="toggle-slider"></span>
      </button>

      <p className="text-bold">{getLabelText()}</p>
      {!minimal && getDescriptionText()}
    </div>
  );
};

export const PublicPrivateToggle = ({formik, minimal = false}) => {
  const {security_level} = formik?.values;

  const getDescriptionText = () => {
    switch (security_level) {
      case 'private':
        return 'Invite-only. Discoverable by members.';
      case 'open':
        return 'Public. Anyone can discover and join.';
      default:
        return '';
    }
  };

  const getLabelText = () => {
    switch (security_level) {
      case 'private':
        return 'Private Workspace';
      case 'open':
        return 'Public Workspace';
      default:
        return '';
    }
  };

  const getToggleClass = () => {
    return security_level === 'private'
      ? 'public-private-toggle__button--private'
      : 'public-private-toggle__button--open';
  };

  return (
    <div className="public-private-toggle">
      <button
        className={`public-private-toggle__button ${getToggleClass()}`}
        aria-label="Toggle Workspace Type">
        <span
          className="public-private-toggle__icon public-private-toggle__icon--private"
          onClick={() => {
            formik.setFieldValue('security_level', 'private');
          }}>
          <RxLockOpen1 />
        </span>
        <span
          className="public-private-toggle__icon public-private-toggle__icon--open"
          onClick={() => {
            formik.setFieldValue('security_level', 'open');
          }}>
          <RxLockOpen2 />
        </span>
        <span className="public-private-toggle__slider"></span>
      </button>
      <p className="public-private-toggle__label">{getLabelText()}</p>
      {!minimal && (
        <p className="public-private-toggle__description">
          {getDescriptionText()}
        </p>
      )}
    </div>
  );
};

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

const ThreadLogoUpload = ({formik, emojiPicker, setEmoji}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = e => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    if (selectedFile.size > MAX_FILE_SIZE) {
      alert(`File size exceeds the maximum limit of 5MB`);
      e.target.value = null;
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      alert('Invalid file type. Please use JPEG, PNG, or GIF.');
      e.target.value = null;
      return;
    }

    setFile(selectedFile);
    formik.setFieldValue('custom_logo', selectedFile);
    formik.setFieldValue('logo', ''); // Clear emoji when custom logo is set
  };

  const handleEmojiSelect = emoji => {
    formik.setFieldValue('logo', emoji);
    formik.setFieldValue('custom_logo', null); // Clear custom logo when emoji is set
    setFile(null);
  };

  const handleEditClick = () => {
    if (fileInputRef) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="thread-logo-upload">
      <label>Logo</label>
      <EmojiInput
        emoji={formik.values.logo}
        setEmoji={handleEmojiSelect}
        setShowPicker={setEmoji}
        showPicker={emojiPicker}
        style={{left: '0px'}}
      />
      <div className="logo-options">
        <span>or</span>
        <input
          ref={fileInputRef}
          id="thread_logo_input"
          type="file"
          accept={ALLOWED_FILE_TYPES.join(', ')}
          onChange={handleFileChange}
          style={{display: 'none'}}
        />
        <button onClick={handleEditClick}>
          {file ? 'Change Custom Logo' : 'Upload Custom Logo'}
        </button>
      </div>
      {file && (
        <div className="custom-logo-preview">
          <img src={URL.createObjectURL(file)} alt="Custom logo preview" />
          {loading ? (
            <SpinningIndicator />
          ) : (
            <button
              className="basic-button-secondary-critical"
              onClick={() => {
                formik.setFieldValue('custom_logo', null);
                setFile(null);
              }}>
              Remove
            </button>
          )}
        </div>
      )}
    </div>
  );
};
