import constants from '../../components/constants';

const generatePlatformInvite = props => {
  const {link = null, profile, note} = props || {};
  const {email, first_name, last_name} = profile || {};
  const pretty_name =
    first_name || last_name
      ? `${first_name || ''} ${last_name || ''}`.trim()
      : email;

  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet">
        <style>
            body { font-family: 'Inter', sans-serif; color: #333; line-height: 1.6; margin: 0; padding: 0; background-color: #f7f7f7; }
            .container { max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; }
            .logo { text-align: center; margin-bottom: 20px; }
            .content { background-color: #f4f4f4; padding: 32px; border-radius: 12px; }
            .button { 
                display: inline-block; 
                background-color: #4743c5; 
                color: white; 
                padding: 16px 32px; 
                text-decoration: none; 
                border-radius: 8px; 
                font-weight: 600;
                font-size: 16px;
                transition: all 0.3s ease;
            }
            .button:hover {
                background-color: #3d39b0;
                transform: translateY(-2px);
            }
            .secondary-button { 
                display: inline-block; 
                background-color: #ffffff; 
                color: #4743c5; 
                padding: 14px 28px; 
                text-decoration: none; 
                border-radius: 8px; 
                font-weight: 600; 
                border: 2px solid #4743c5;
                font-size: 15px;
                margin: 0 8px;
            }
            .footer { text-align: center; margin-top: 24px; color: #666; font-size: 14px; }
            .footer a { margin: 0 8px; color: #666; text-decoration: none; }
            .note { 
                white-space: pre-line; 
                border-left: 4px solid #4743c5; 
                padding: 16px; 
                border-radius: 8px; 
                margin: 24px 0;
                background: #ffffff;
            }
            .highlight-box {
                background: linear-gradient(135deg, #4743c5, #6e8efb);
                color: white;
                padding: 24px;
                border-radius: 12px;
                margin: 24px 0;
            }
            .feature-grid { 
                display: grid; 
                grid-template-columns: 1fr; 
                gap: 16px; 
                margin: 24px 0; 
            }
            .feature-item { 
                background: #ffffff; 
                padding: 20px; 
                border-radius: 8px;
                border: 1px solid #e1e4e8;
                transition: transform 0.3s ease;
            }
            .feature-item:hover {
                transform: translateY(-2px);
            }
            .feature-icon {
                font-size: 24px;
                margin-bottom: 12px;
            }
            .testimonial {
                background: #fff;
                padding: 20px;
                border-radius: 8px;
                margin: 24px 0;
                border-left: 4px solid #4743c5;
            }
            .badge {
                display: inline-block;
                background: rgba(71, 67, 197, 0.1);
                color: #4743c5;
                padding: 4px 12px;
                border-radius: 16px;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 16px;
            }
            .time-savings {
                background: #fff9e6;
                border: 1px solid #ffd700;
                padding: 16px;
                border-radius: 8px;
                text-align: center;
                margin: 24px 0;
                font-size: 18px;
                font-weight: 600;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="logo">
                <img src="${
                  constants.publicmind_logo
                }" alt="PublicMind Logo" height="48" width="48" />
                <h2 style="font-weight: 200; margin-top: 12px;">PUBLICMIND</h2>
            </div>
            <div class="content">
                <div class="badge">TRUSTED BY PUBLIC SECTOR TEAMS</div>
                
                <h2 style="margin: 0 0 16px 0;">Save <strong>2-3 Hours</strong> Every Day on Documentation</h2>
                
                ${
                  note
                    ? `
                    <div class="note">
                        <strong>A note from ${pretty_name}:</strong>
                        <div style="margin-top: 8px;">${note}</div>
                    </div>
                `
                    : ''
                }

                <div class="highlight-box">
                    <h3 style="margin: 0 0 12px 0;">Transform Hours of Writing into Minutes</h3>
                    <p style="margin: 0;">Stop spending your evenings writing reports and documentation. PublicMind reviews your work as you go and instantly generates perfect documentation, reports, and presentations - giving you time back for what matters most.</p>
                </div>

                <div class="time-savings">
                    Our users save an average of 11.5 hours per week on documentation
                </div>

<div class="feature-grid">
    <div class="feature-item">
        <div class="feature-icon">📝</div>
        <h4 style="margin: 0 0 8px 0">Transform Work Into Perfect Narratives</h4>
        <p style="margin: 0;">Instantly turn months of work into compelling stories. Your PublicMind partner helps craft the perfect narrative, finding the right words and impact metrics that matter.</p>
    </div>
    
    <div class="feature-item">
        <div class="feature-icon">🔍</div>
        <h4 style="margin: 0 0 8px 0">Never Miss Important Information</h4>
        <p style="margin: 0;">Your PublicMind assistant reads every email, document, and update so you don't have to. Get intelligent summaries of what matters, when it matters.</p>
    </div>
    
    <div class="feature-item">
        <div class="feature-icon">⭐️</div>
        <h4 style="margin: 0 0 8px 0">Reporting Made Effortless</h4>
        <p style="margin: 0;">When it's time for weekly updates, monthly reports, or yearly funding requests, PublicMind instantly analyzes your work to create the perfect report - saving you hours of searching and writing.</p>
    </div>
</div>

                <div class="testimonial">
                    <p style="margin: 0; font-style: italic;">Already trusted by teams at UofSC, SC Department of Employment and Workforce, SC Department of Education, and other public sector organizations.</p>
                </div>

                <div style="text-align: center; margin: 32px 0;">
                    <a href="${link}" class="button">Start Saving Time →</a>
                    <a href="https://calendly.com/jack-publicmind" class="secondary-button">Schedule a 10 Minute Demo</a>
                </div>

                <p style="text-align: center; color: #666; font-size: 14px;">
                    Questions? Contact us at <a href="mailto:help@publicmind.ai" style="color: #4743c5; text-decoration: none;">help@publicmind.ai</a>
                </p>
            </div>
            
            <div class="footer">
                <p style="font-weight: 600; color: #333;">PublicMind - Save Hours Every Week</p>
                <div style="margin-top: 16px;">
                    <a href="${constants.privacy_policy}">Privacy Policy</a>
                    <a href="${constants.terms_of_service}">Terms of Service</a>
                </div>
            </div>
        </div>
    </body>
    </html>
  `;
};

export default generatePlatformInvite;
