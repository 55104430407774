import React, {useState} from 'react';
import {FaConnectdevelop} from 'react-icons/fa';
import {
  RiArrowRightLine,
  RiFileTextLine,
  RiLightbulbLine,
  RiMailLine,
  RiTaskLine,
} from 'react-icons/ri';
import {v4} from 'uuid';
import {StreamAIMessageLlama} from '../../../api/ai';
import {dateToTimestamp, formatWWW_MMM_DD_YYYY_TTTT} from '../../../api/dates';
import prompts from '../../../components/ai-prompts';
import SystemStatus, { isProUser } from '../../../components/system-status/system-status';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import {generateOnboardingData} from '../data/thread-data';
import {CreateCommentHome} from '../inputs/create-comment-inputs';
import {GenericAIResponseContainer} from './AIInsightsHome';
import './AppOnboarding.css';

const ContextualTip = ({tip, position = 'bottom'}) => (
  <div className={`contextual-tip ${position}`}>
    <RiLightbulbLine className="tip-icon" />
    <p className="tip-text">{tip}</p>
  </div>
);

const documentOptions = [
  {
    id: 'executive_summary',
    title: 'Executive Summary',
    description: 'A concise overview of key points',
    icon: <RiFileTextLine />,
    promptType: 'summary',
  },
  {
    id: 'email',
    title: 'Professional Email',
    description: 'A well-structured email update',
    icon: <RiMailLine />,
    promptType: 'email',
  },
  {
    id: 'action_items',
    title: 'Action Items',
    description: 'Extract key tasks and next steps',
    icon: <RiTaskLine />,
    promptType: 'tasks',
  },
];
const getPromptTemplate = (docType, data) => {
  const {threadData, promptData, files} = data;

  const baseContext = `
Current Date/Time: ${formatWWW_MMM_DD_YYYY_TTTT()}
Thread Title: ${threadData.title}
${
  files?.length
    ? `Attached Files:\n${files
        .map(f => `File Name: ${f.name}\nFile Content: ${f.text}`)
        .join('\n\n')}`
    : ''
}

SOURCE CONTENT:
${promptData}
`;

  const templates = {
    executive_summary: `You are PublicMind, an AI Agent. Create a concise executive summary of this content.

CONTEXT:
${baseContext}

REQUIRED COMPONENTS:
1. Overview (1-2 sentences)
2. Key Points (3-5 bullet points)
3. Main Takeaways
4. Next Steps (if applicable)

OUTPUT FORMAT:
Create a clear, professional executive summary following standard business format.`,

    email: `You are PublicMind, an AI Agent. Transform this content into a professional email.

CONTEXT:
${baseContext}

REQUIRED COMPONENTS:
1. Professional greeting
2. Clear context setting
3. Main updates/points
4. Next steps or action items
5. Professional closing

OUTPUT FORMAT:
Generate the email in standard business format with proper spacing and structure.`,

    action_items: `You are PublicMind, an AI Agent. Extract and organize all action items and tasks from this content.

CONTEXT:
${baseContext}

REQUIRED COMPONENTS:
1. Immediate Actions (Next 24-48 hours)
2. Short-term Tasks (This week)
3. Long-term Items (Beyond this week)
4. Dependencies or Blockers
5. Assigned Responsibilities (if mentioned)

OUTPUT FORMAT:
Organize tasks in a clear, prioritized format with deadlines where specified.`,
  };

  return templates[docType] || templates.executive_summary;
};
const OnboardingTutorial = ({onClose}) => {
  const {
    state: {id},
  } = useAuth();

  const {
    state: {threads, thread_content},
    createThread,
  } = useThreads();

  const [step, setStep] = useState(0);
  const [threadTitle, setThreadTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [files, setFiles] = useState(null);
  const [selectedDocType, setSelectedDocType] = useState('executive_summary');
  const [generatedContent, setGeneratedContent] = useState('');

  const generateContent = async docType => {
    setLoading(true);
    let generatedContent = '';

    try {
      const {promptData, threadData: newThreadData} =
        await generateOnboardingData(threadId, files, threads, thread_content);

      const promptTemplate = getPromptTemplate(docType, {
        threadData: newThreadData,
        promptData,
        files: newThreadData?.files,
      });

      await StreamAIMessageLlama({
        user_id: id,
        action: 'onboarding',
        messages: [
          {
            role: 'user',
            content: [{type: 'text', text: promptTemplate}],
          },
        ],
        system: prompts.base_system,
        onUpdate: message => {
          generatedContent += message;
          setGeneratedContent(generatedContent);
        },
        onComplete: response => {},
      });
    } catch (error) {
      console.error('Error generating content:', error);
    } finally {
      setLoading(false);
    }
  };

  const onCreateThread = async () => {
    if (!threadTitle.trim()) return;
    setLoading(true);
    try {
      const now = dateToTimestamp();
      const thread = {
        id: v4(),
        owner_id: id,
        title: threadTitle,
        permissions: [{user_id: id, role: 'owner', created: now, updated: now}],
        media: [],
        custom_logo: null,
        tags: [],
        security_level: 'open',
        type: 'thread',
        members: [id],
        content: '',
        logo: '',
        parent: null,
        expiration: null,
        ai_assisted: false,
        status: 'draft',
        created: now,
        updated: now,
      };

      setThreadId(thread);
      const response = await createThread(thread);

      if (response) {
        setStep(2);
      }
    } catch (error) {
      console.error('Error creating thread:', error);
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    {
      title: 'Quick PublicMind Tutorial',
      description: `Transform your team's knowledge into actionable intelligence. Create secure workspaces to collaborate, preserve decisions, and generate professional documents instantly.`,

      icon: (
        <span className="flex-column align-center padding-bottom8">
          <div className={`ai-insights-brain-icon-large ${''}`}>
            <FaConnectdevelop />
          </div>
        </span>
      ),
      content: (
        <div className="tutorial-welcome">
          <ContextualTip tip="PublicMind securely handles all your important files - from Word documents and PowerPoint presentations to PDFs and images." />
        </div>
      ),
      action: (
        <button
          className="tutorial-button tutorial-button-primary"
          onClick={() => setStep(1)}>
          Begin <RiArrowRightLine />
        </button>
      ),
    },
    {
      title: "Let's Create Your First Workspace",
      description:
        'Workspaces are secure environments for your projects. Use them to organize meetings, track initiatives, or preserve important decisions - anything you want to reference and build upon.',

      content: (
        <div className="tutorial-input-section">
          <div className="tutorial-input-container pretty-form-group">
            <input
              type="text"
              placeholder="Name your first workspace..."
              value={threadTitle}
              onChange={e => setThreadTitle(e.target.value)}
              className="tutorial-input"
              onKeyDown={e => {
                if (e.key === 'Enter' && threadTitle.trim()) {
                  onCreateThread();
                }
              }}
            />
            <p className="text-secondary padding-top8">
              Examples: "Strategic Planning 2024" or "Grant Review Committee"
            </p>
          </div>
        </div>
      ),
      action: (
        <button
          className="tutorial-button tutorial-button-primary"
          onClick={onCreateThread}
          disabled={!threadTitle.trim() || loading}>
          {loading ? 'Creating...' : 'Create Workspace'} <RiArrowRightLine />
        </button>
      ),
    },
    {
      title: `Add Your First Update to "${threadTitle}"`,
      description:
        'Share documents, decisions, or updates. The more context you provide, the more your PublicMind Agent can help organize and summarize your work.',
      content: (
        <div className="tutorial-welcome">
          <ContextualTip tip="Pro tip: Start with text documents (.docx or .txt) for the best results!" />
        </div>
      ),

      action: null,
    },
    {
      title: 'Select Your Template',
      description: 'Choose what you want your Agent to create',
      content: (
        <div className="document-options">
          {documentOptions.map(option => (
            <button
              key={option.id}
              className={`document-option ${
                selectedDocType === option.id ? 'selected' : ''
              }`}
              onClick={() => setSelectedDocType(option.id)}>
              <div className="document-option-icon">{option.icon}</div>
              <div className="document-option-content">
                <h4>{option.title}</h4>
                <p>{option.description}</p>
              </div>
            </button>
          ))}
        </div>
      ),
      action: (
        <button
          className="tutorial-button tutorial-button-primary"
          onClick={() => {
            generateContent(selectedDocType);
            setStep(4);
          }}>
          Generate {documentOptions.find(x => x.id === selectedDocType)?.title}{' '}
          <RiArrowRightLine />
        </button>
      ),
    },
    {
      title: 'Watch Your Content Transform',
      description:
        'Your PublicMind Agent is organizing your information into the selected format',
      content: (
        <div className="generation-result">
          <GenericAIResponseContainer
            loading={loading}
            onRefresh={() => generateContent(selectedDocType)}
            text={generatedContent}
          />
        </div>
      ),
      action: (
        <button
          className="tutorial-button tutorial-button-primary"
          onClick={() => setStep(5)}>
          Continue <RiArrowRightLine />
        </button>
      ),
    },
    {
      title: isProUser(id) ? 'Ready to Do More?' : 'Unlock AI Features',
      description: isProUser(id)
        ? 'Explore advanced features and document types to make your work even more efficient'
        : 'Get access to our AI assistant to save hours on documentation',
      content: (
        <div className="system-status-info">
          <SystemStatus />
          {isProUser(id) ? (
            <p>
              Access the menu in the upper left corner to create professional
              documents instantly
            </p>
          ) : (
            <div>
              <p>Request access to AI features including:</p>
              <ul style={{paddingLeft: '20px', marginTop: '8px'}}>
                <li>Instant document generation</li>
                <li>Smart workspace summaries</li>
                <li>Automated report creation</li>
              </ul>
              <p style={{marginTop: '16px', color: '#4743c5'}}>
                Email <strong>help@publicmind.ai</strong> to request access
              </p>
            </div>
          )}
        </div>
      ),
      action: (
        <button
          className="tutorial-button tutorial-button-primary"
          onClick={
            isProUser(id)
              ? onClose
              : () => (window.location.href = 'mailto:help@publicmind.ai')
          }>
          {isProUser(id) ? (
            <>
              Create Your Next Workspace <RiArrowRightLine />
            </>
          ) : (
            <>
              Request Access <RiArrowRightLine />
            </>
          )}
        </button>
      ),
    },
  ];

  const currentStep = steps[step];

  return (
    <div className="tutorial-container">
      <div className="tutorial-content">
        <div className="tutorial-header">
          <div className="step-indicator">
            {steps.map((_, index) => (
              <div
                key={index}
                className={`step-dot ${index === step ? 'active' : ''} 
                ${index < step ? 'completed' : ''}`}
              />
            ))}
          </div>
        </div>

        <div className="tutorial-body">
          {currentStep.icon && (
            <div className="tutorial-icon-wrapper">{currentStep.icon}</div>
          )}
          <h2 className="tutorial-title">{currentStep.title}</h2>
          <p className="tutorial-description">{currentStep.description}</p>
          {currentStep.helpText && (
            <div className="tutorial-help-text">
              <RiLightbulbLine />
              <span>{currentStep.helpText}</span>
            </div>
          )}
          {currentStep.content}
        </div>

        {step === 2 && (
          <CreateCommentHome
            id={threadId.id}
            onSuccess={files => {
              setFiles(files);
              setStep(3);
            }}
          />
        )}

        <div className="tutorial-footer">{currentStep.action}</div>
      </div>
    </div>
  );
};

export default OnboardingTutorial;
