import {useState} from 'react';
import {
  RiArrowRightSLine,
  RiCheckboxCircleLine,
  RiFileTextLine,
} from 'react-icons/ri';
import {TEMPLATE_OPTIONS, THREAD_TYPES} from '../modals/assistant-modal';
import './TemplateSelector.css';

const TemplateSelectorControlPanel = ({threadType, value, setValue}) => {
  const [showAll, setShowAll] = useState(false);

  // Get the appropriate template options based on thread type
  const getTemplateOptions = () => {
    switch (threadType) {
      case THREAD_TYPES.COMPLETED:
        return TEMPLATE_OPTIONS.COMPLETED_PROJECT;
      case THREAD_TYPES.DRAFT:
        return TEMPLATE_OPTIONS.DRAFT_PROJECT;
      case THREAD_TYPES.LIVE:
        return TEMPLATE_OPTIONS.LIVE_PROJECT;
      default:
        return TEMPLATE_OPTIONS.DRAFT_PROJECT;
    }
  };

  const options = getTemplateOptions();
  const displayedOptions = showAll ? options : options.slice(0, 10);

  return (
    <div className="ai-template-selector">
      <div className="ai-template-grid">
        {displayedOptions.map(template => (
          <div
            key={template.value}
            className={`ai-template-card ${
              value?.value === template.value ? 'selected' : ''
            }`}
            onClick={() => setValue(template)}>
            <div className="ai-template-icon">
              {template.icon || <RiFileTextLine size={24} />}
            </div>
            <div className="ai-template-content">
              <h4>{template.label}</h4>
              <p>{template.description}</p>
            </div>
            {value?.value === template.value && (
              <div className="ai-template-selected">
                <RiCheckboxCircleLine size={20} />
              </div>
            )}
          </div>
        ))}
      </div>

      {options.length > 3 && (
        <button
          className="ai-template-expand"
          onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show Less' : 'Show More Templates'}
          <RiArrowRightSLine size={16} className={showAll ? 'rotate' : ''} />
        </button>
      )}
    </div>
  );
};

export const TemplateSelectorWizard = ({toComplete, value, setValue}) => {
  const [activeSection, setActiveSection] = useState(
    toComplete ? 'completed' : 'draft',
  );

  const sections = [
    // {
    //   id: 'draft',
    //   label: 'Project Planning Document',
    //   description: 'Templates for new project initialization',
    //   options: TEMPLATE_OPTIONS.DRAFT_PROJECT,
    // },
    // {
    //   id: 'live',
    //   label: 'Current Status Reports',
    //   description: 'Track progress and monitor active projects',
    //   options: TEMPLATE_OPTIONS.LIVE_PROJECT,
    // },
    {
      id: 'completed',
      label: 'Project Completion Reports',
      description: 'Documentation and analysis for completed projects',
      options: TEMPLATE_OPTIONS.COMPLETED_PROJECT,
    },
  ];

  const displayedOptions = sections.find(s => s.id === activeSection)?.options;

  return (
    <div className="ai-template-selector">
      <div className="ai-template-sections">
        {sections.map(section => (
          <button
            key={section.id}
            className={`ai-template-section-tab ${
              activeSection === section.id ? 'active' : ''
            }`}
            onClick={() => setActiveSection(section.id)}>
            <h4>{section.label}</h4>
            <p>{section.description}</p>
          </button>
        ))}
      </div>

      <div className="ai-template-grid">
        {displayedOptions?.map(template => (
          <div
            key={template.label}
            className={`ai-template-card ${
              value?.label === template.label ? 'selected' : ''
            }`}
            onClick={() => setValue(template)}>
            <div className="ai-template-icon">
              {template.icon || <RiFileTextLine size={24} />}
            </div>
            <div className="ai-template-content">
              <h4>{template.label}</h4>
              <p>{template.description}</p>
            </div>
            {value?.value === template.value && (
              <div className="ai-template-selected">
                <RiCheckboxCircleLine size={20} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TemplateSelectorControlPanel;
