import React, {useMemo, useState} from 'react';
import {
  RiBarChartLine,
  RiCalendarLine,
  RiInformationLine,
  RiTimeLine,
} from 'react-icons/ri';
import {dateToTimestamp} from '../../api/dates';
import {
  calculateOperationCost,
  TOKENS_PER_PAGE,
  USAGE_LIMITS,
} from '../../contexts/ai-context';
import {useAI} from '../../hooks/use-ai';
import './AIUsageScreen.css';

// Configuration for the usage screen
const USAGE_CONFIG = {
  MODEL_DISPLAY_NAMES: {
    'claude-3-5-sonnet-20241022': 'Advanced',
    'llama-v3p1-8b-instruct': 'Basic',
  },
  AGENT_DISPLAY_NAMES: {
    document_generation: 'Document Creator',
    workspace_assistant: 'Workspace Assistant',
    homepage_summary: 'Homepage Summary',
    onboarding: 'Onboarding Tutorial',
  },
};

const formatDate = timestamp => {
  const date = new Date(timestamp * 1000);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return `Today at ${date.toLocaleTimeString()}`;
  }
  if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday at ${date.toLocaleTimeString()}`;
  }
  return date.toLocaleString();
};

const UsageCard = ({title, icon, cost, limit}) => {
  // Calculate percentage of limit used, accounting for buffer
  const effectiveLimit = limit - limit * 0.1; // 10% buffer
  const percentage = (parseFloat(cost) / effectiveLimit) * 100;

  return (
    <div className="usage-stat-card">
      <div className="usage-stat-header">
        {icon}
        <h3>{title}</h3>
      </div>
      <div className="usage-stat-content">
        <div className="usage-stat-progress">
          <div
            className={`usage-stat-progress-bar ${
              percentage > 80 ? 'high' : ''
            } 
              ${percentage > 95 ? 'critical' : ''}`}
            style={{width: `${Math.min(percentage, 100)}%`}}
          />
        </div>
        <div className="usage-stat-numbers">
          <div className="usage-stat-primary">
            <span className="usage-stat-currency">$</span>
            <span className="usage-stat-value">
              {parseFloat(cost).toFixed(2)}
            </span>
          </div>
          <span className="usage-stat-total">of ${limit.toFixed(2)} limit</span>
        </div>
      </div>
    </div>
  );
};

const UsageTable = ({records, timeframe}) => {
  const startTimestamp = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (timeframe === 'daily') {
      return dateToTimestamp(new Date(today));
    }
    return dateToTimestamp(new Date(today.getFullYear(), today.getMonth(), 1));
  }, [timeframe]);

  const filteredRecords = useMemo(
    () =>
      records
        .filter(x => x.created >= startTimestamp)
        .sort((a, b) => b.created - a.created),
    [records, startTimestamp],
  );

  return (
    <div className="usage-table-content">
      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Model</th>
            <th>Type</th>
            <th>Agent</th>
            <th className="text-right">Cost</th>
          </tr>
        </thead>
        <tbody>
          {filteredRecords.map(item => {
            const cost =
              item.cost ||
              calculateOperationCost(
                item.tokens_used,
                item.model_used,
                item.token_type,
              );

            return (
              <tr key={item.id}>
                <td>{formatDate(item.created)}</td>
                <td>
                  {USAGE_CONFIG.MODEL_DISPLAY_NAMES[item.model_used] ||
                    item.model_used}
                </td>
                <td>{item.token_type === 'output' ? 'Response' : 'Request'}</td>
                <td>
                  {USAGE_CONFIG.AGENT_DISPLAY_NAMES[item?.action] ||
                    'Background'}
                </td>
                <td className="text-right">${cost.toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const AIUsageScreen = () => {
  const [timeframe, setTimeframe] = useState('daily');
  const {
    state: {usageRecords},
  } = useAI();

  // Calculate usage statistics based on direct costs
  const usageStats = useMemo(() => {
    const startTimestamp = dateToTimestamp(
      timeframe === 'daily'
        ? new Date(new Date().setHours(0, 0, 0, 0)) // Wrap in Date to ensure it's a Date object
        : new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Already a Date object
    );

    const filteredRecords = usageRecords.filter(
      record => record.created >= startTimestamp,
    );

    const costs = filteredRecords.reduce(
      (acc, record) => {
        // Use stored cost if available, otherwise calculate it
        const cost =
          record.cost ||
          calculateOperationCost(
            record.tokens_used,
            record.model_used,
            record.token_type,
          );
        const pageCount =
          record.token_type === 'output'
            ? record.tokens_used / TOKENS_PER_PAGE
            : 0;
        return {
          totalCost: acc.totalCost + cost,
          count: acc.count + 1,
          pages: acc.pages + pageCount,
        };
      },
      {totalCost: 0, count: 0, pages: 0},
    );
    return {
      totalCost: costs.totalCost,
      totalRequests: costs.count,
      averageCost: costs.count ? costs.totalCost / costs.count : 0,
      // Use actual monetary limits instead of token-based calculations
      dailyLimit: USAGE_LIMITS.DAILY.amount,
      monthlyLimit: USAGE_LIMITS.MONTHLY.amount,
      totalPages: costs.pages,
    };
  }, [usageRecords, timeframe]);

  return (
    <div className="usage-screen">
      <div className="usage-info-card">
        <RiInformationLine className="usage-info-icon" />
        <div className="usage-info-content">
          <h4>Usage & Billing</h4>
          <p>
            Monitor your Agent usage costs in real-time. Advanced operations
            cost up to $0.50 per page, while basic operations cost less than
            $0.01 per page. Daily limit of ${USAGE_LIMITS.DAILY.amount} and
            monthly limit of ${USAGE_LIMITS.MONTHLY.amount} help you manage
            costs effectively. A small buffer is reserved to prevent overages.
          </p>
        </div>
      </div>
      <div className="usage-stats-grid">
        {timeframe === 'daily' && (
          <UsageCard
            title="Today's Usage"
            icon={<RiBarChartLine />}
            cost={usageStats.totalCost}
            limit={usageStats.dailyLimit}
          />
        )}
        {timeframe === 'monthly' && (
          <UsageCard
            title="Monthly Usage"
            icon={<RiCalendarLine />}
            cost={usageStats.totalCost}
            limit={usageStats.monthlyLimit}
          />
        )}
      </div>
      <TimeSavingsStats usageRecords={usageRecords} timeframe={timeframe} />
      <div className="usage-table-card">
        <div className="usage-table-header">
          <div className="usage-table-title">
            <h3>Usage History</h3>
            <div className="usage-table-controls">
              <button
                className={`usage-table-control ${
                  timeframe === 'daily' ? 'active' : ''
                }`}
                onClick={() => setTimeframe('daily')}>
                Today
              </button>
              <button
                className={`usage-table-control ${
                  timeframe === 'monthly' ? 'active' : ''
                }`}
                onClick={() => setTimeframe('monthly')}>
                This Month
              </button>
            </div>
          </div>
        </div>
        <UsageTable records={usageRecords} timeframe={timeframe} />
      </div>
    </div>
  );
};

export default AIUsageScreen;

const TimeSavingsStats = ({usageRecords, timeframe = 'daily'}) => {
  const stats = useMemo(() => {
    const startTimestamp = dateToTimestamp(
      timeframe === 'daily'
        ? new Date(new Date().setHours(0, 0, 0, 0))
        : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    );

    const records = usageRecords.filter(
      record => record.created >= startTimestamp,
    );

    // Separate input and output pages
    const {inputPages, outputPages} = records.reduce(
      (acc, record) => {
        const pages = record.tokens_used / TOKENS_PER_PAGE;
        if (record.token_type === 'input') {
          acc.inputPages += pages;
        } else {
          acc.outputPages += pages;
        }
        return acc;
      },
      {inputPages: 0, outputPages: 0},
    );

    // Time calculations for input (reading/analysis)
    const readingTimePerPage = 5; // minutes to read and understand
    const analysisTimePerPage = 10; // minutes to analyze and extract key points
    const inputTimeSaved =
      (readingTimePerPage + analysisTimePerPage) * inputPages;

    // Time calculations for output (creation)
    const timePerPage = {
      simple: 15,
      technical: 25,
      research: 30,
    };
    const avgTimePerPage =
      (timePerPage.simple + timePerPage.technical + timePerPage.research) / 3;
    const aiTimePerPage = 1 / 6;
    const outputTimeSaved =
      outputPages * avgTimePerPage - outputPages * aiTimePerPage;

    const totalMinutesSaved = inputTimeSaved + outputTimeSaved;

    return {
      hours: Math.floor(totalMinutesSaved / 60),
      minutes: Math.round(totalMinutesSaved % 60),
      pagesReviewed: Math.round(inputPages),
      pagesCreated: Math.round(outputPages),
      documentsCreated: records.filter(
        x => x.action === 'document_generation' && x.token_type === 'output',
      ).length,
    };
  }, [usageRecords, timeframe]);

  return (
    <div className="usage-summary">
      <h3 className="usage-summary-title">
        {timeframe === 'daily' ? "Today's" : "This Month's"} Time Savings
        <p className="text-secondary text-12 padding-top8">
          Includes time saved on reading, research, writing, and editing
        </p>
      </h3>
      <div className="usage-summary-stats">
        <div className="usage-summary-item">
          <RiTimeLine className="usage-summary-icon" />
          <div className="usage-summary-content">
            <span className="usage-summary-value">
              {stats.hours}h {stats.minutes}m
            </span>
            <span className="usage-summary-label">Time Saved</span>
          </div>
        </div>

        <div className="usage-summary-item">
          <RiBarChartLine className="usage-summary-icon" />
          <div className="usage-summary-content">
            <span className="usage-summary-value">
              {stats.documentsCreated}
            </span>
            <span className="usage-summary-label">
              {stats.documentsCreated === 1 ? 'Page' : 'Pages'} Created
            </span>
          </div>
        </div>

        <div className="usage-summary-item">
          <RiBarChartLine className="usage-summary-icon" />
          <div className="usage-summary-content">
            <span className="usage-summary-value">{stats.pagesReviewed}</span>
            <span className="usage-summary-label">
              {stats.pagesReviewed === 1 ? 'Page' : 'Pages'} Reviewed
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
