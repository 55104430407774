import React, {useEffect, useState} from 'react';
import {GoGitBranch} from 'react-icons/go';
import {RiGroupLine} from 'react-icons/ri';
import {
  RxActivityLog,
  RxArrowRight,
  RxCheck,
  RxChevronRight,
  RxClock,
  RxCross1,
  RxFile,
  RxLink1,
} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {track} from '../../../api/analytics';
import {isWithinLast30Days, timeSince} from '../../../api/dates';
import {useAuth} from '../../../hooks/use-auth';
import useCollaborator from '../../../hooks/use-collaborator';
import {useThreads} from '../../../hooks/use-threads';
import {CreateCommentHome} from '../inputs/create-comment-inputs';
import {AssistantModal} from '../modals/assistant-modal';
import './WorkLogger.css';
import { MdOutlineWorkspaces } from 'react-icons/md';

const ThreadCard = ({thread, latestContent, onSelect, onCreate}) => {
  const navigate = useNavigate();
  const {threadActions} = useCollaborator();

  const {writeable, viewable} = threadActions(thread);

  const {
    state: {id: userId},
  } = useAuth();

  const getStatusDetails = () => {
    switch (thread?.status) {
      case 'draft':
        return {
          icon: <RxFile />,
          text: 'Draft',
        };
      case 'active':
        return {
          icon: <RiGroupLine />,
          text: 'Group',
        };
      case 'complete':
        return {
          icon: <RxCheck />,
          text: 'Closed',
        };
      default:
        return {
          icon: null,
          text: '',
        };
    }
  };

  const {icon, text} = getStatusDetails();

  const handleQuickUpdate = e => {
    e.stopPropagation();
    onSelect(thread);
  };

  const handleViewThread = e => {
    e.stopPropagation();
    navigate(`/threads/${thread?.id}`);
  };

  return (
    <div className="thread-select-card">
      <div className="thread-select-header">
        <div className="thread-select-icon">
          {thread?.logo ||
            (thread?.type === 'sub-thread' ? (
              <GoGitBranch />
            ) : (
              <MdOutlineWorkspaces />
            ))}
        </div>

        <h3 className="thread-select-title">{thread.title}</h3>
      </div>
      <div className="thread-select-header">
        <div className={`thread-select-status ${thread?.status}`}>
          {icon}
          <span>{text}</span>
        </div>
        {latestContent && (
          <div className="thread-select-activity">
            <RxClock />
            <span>Updated {timeSince(latestContent.created)}</span>
          </div>
        )}
      </div>
      <div className="thread-select-meta">
        <div className="thread-select-actions">
          {writeable && thread?.status !== 'complete' && (
            <button
              className="basic-button"
              onClick={handleQuickUpdate}
              aria-label="Quick update">
              <span>Post Update</span>
            </button>
          )}
          <button
            className="thread-select-action"
            onClick={handleViewThread}
            aria-label="View thread details">
            <RxChevronRight />
            <span>View</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const WorkLogger = ({unreadPosts}) => {
  const navigate = useNavigate();
  const {threadActions} = useCollaborator();
  const [selectedThread, setSelectedThread] = useState(null);
  const [activeThreads, setActiveThreads] = useState([]);
  const [composerVisible, setComposerVisible] = useState(false);
  const [assistantActive, setAssistantActive] = useState(false);

  const {
    state: {threads, thread_ids, thread_content, thread_subs},
  } = useThreads();

  const {
    state: {id: userId},
  } = useAuth();

  const latestContentMap = React.useMemo(() => {
    return Object.values(thread_content).reduce((acc, content) => {
      if (
        !acc[content?.thread_id] ||
        content.created > acc[content?.thread_id].created
      ) {
        acc[content?.thread_id] = content;
      }
      return acc;
    }, {});
  }, [thread_content]);

  useEffect(() => {
    const filteredThreads = thread_ids
      .map(id => threads[id])
      .filter(thread => {
        if (!thread) return false;
        const {status, type} = thread;
        const {writeable, viewable} = threadActions(thread);
        return type !== 'direct' && viewable;
      })
      .sort((a, b) => b.updated - a.updated);

    setActiveThreads(filteredThreads);
  }, [threads, thread_ids]);

  const handleThreadSelect = thread => {
    setSelectedThread(thread);
    setComposerVisible(true);
  };

  const handleCreateDocument = thread => {
    setSelectedThread(thread);
    setAssistantActive(true);
    track('launched_doc_generator_for_workspace', {
      workspace_id: thread?.id,
      workspace_name: thread?.title,
      workspace_type: thread?.type,
    });
  };

  const handleUpdateSuccess = () => {
    setComposerVisible(false);
    setTimeout(() => setSelectedThread(null), 300);
  };

  const recentThreads = activeThreads.slice(0, 3);
  const monthlyThreads = activeThreads.filter(
    thread =>
      isWithinLast30Days(thread.updated) && !recentThreads.includes(thread),
  );
  const EmptyState = () => (
    <div className="work-logger-empty">
      <div className="work-logger-empty-content">
        <RxActivityLog className="work-logger-empty-icon" />
        <h2 className="work-logger-empty-title">Create Your First Workspace</h2>
        <p className="work-logger-empty-subtitle">
          Start saving time on documentation by creating a personal or group
          workspace for your next project
        </p>
        <button
          className="work-logger-empty-button"
          onClick={() => navigate('/threads/create')}>
          Create Your First Workspace <RxArrowRight />
        </button>
      </div>
    </div>
  );
  return (
    <div className="work-logger">
      <div className="work-logger-content">
        {recentThreads.length === 0 && monthlyThreads.length === 0 && (
          <EmptyState />
        )}
        {recentThreads?.length > 0 && (
          <div className="work-logger-header">
            <h2>Recent Workspaces</h2>
            <p className="flex-row align-center">
              Post workspace updates by pressing Post Update below.
            </p>
          </div>
        )}
        <div className="thread-quick-select">
          {recentThreads.map(thread => (
            <ThreadCard
              key={thread.id}
              thread={thread}
              latestContent={latestContentMap[thread.id]}
              onSelect={handleThreadSelect}
              onCreate={handleCreateDocument}
            />
          ))}
        </div>

        {monthlyThreads?.length > 0 && (
          <div className="work-logger-header padding-top8">
            <h2>Other Workspaces</h2>
            <p>Last 30 Days</p>
          </div>
        )}
        <div className="thread-quick-select">
          {monthlyThreads.map(thread => (
            <ThreadCard
              key={thread.id}
              thread={thread}
              latestContent={latestContentMap[thread.id]}
              onSelect={handleThreadSelect}
              onCreate={handleCreateDocument}
            />
          ))}
        </div>
      </div>
      <Logger
        composerVisible={composerVisible}
        setComposerVisible={setComposerVisible}
        selectedThread={selectedThread}
        handleUpdateSuccess={handleUpdateSuccess}
      />

      {selectedThread && (
        <AssistantModal
          active={assistantActive}
          setActive={setAssistantActive}
          thread_data={{thread: selectedThread, toComplete: false}}
        />
      )}
    </div>
  );
};

export const Logger = ({
  composerVisible,
  setComposerVisible,
  selectedThread,
  handleUpdateSuccess,
}) => {
  const navigate = useNavigate();
  return (
    <div className={`composer-overlay ${composerVisible ? 'visible' : ''}`}>
      <div className="composer-container">
        <div className="composer-header">
          <div className="composer-thread-info">
            <p className="text-secondary">Posting To: </p>
            {selectedThread?.status === 'draft' ? (
              <RxFile />
            ) : selectedThread?.status === 'active' ? (
              <RiGroupLine />
            ) : (
              <RxCheck />
            )}
            <span className="composer-thread-icon">{selectedThread?.logo}</span>
            <span className="composer-thread-title">
              {selectedThread?.title}
            </span>
            <button
              onClick={() => navigate(`/threads/${selectedThread?.id}`)}
              className="composer-back">
              <RxChevronRight />
            </button>
          </div>
          <button
            className="composer-back"
            onClick={() => setComposerVisible(false)}>
            <RxCross1 />
          </button>
        </div>

        {selectedThread && (
          <CreateCommentHome
            id={selectedThread.id}
            onSuccess={handleUpdateSuccess}
          />
        )}
      </div>
    </div>
  );
};

export default WorkLogger;
