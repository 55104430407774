import React from 'react';

import {BiPoll} from 'react-icons/bi';
import {GoGitBranch} from 'react-icons/go';
import {HiOutlineDocumentText} from 'react-icons/hi';
import {MdLabelImportantOutline} from 'react-icons/md';
import {
  RxActivityLog,
  RxCheckbox,
  RxLayers,
  RxQuestionMarkCircled,
  RxSewingPinFilled,
} from 'react-icons/rx';
import {TooltipRight} from '../../components/tooltip/tooltip';
import useCollaborator from '../../hooks/use-collaborator';
import {useThreads} from '../../hooks/use-threads';
import './VerticalMenuBar.css';

const VerticalMenuBar = ({id, activeItem, setActiveItem, setIndex}) => {
  const {threadActions} = useCollaborator();
  const {
    state: {threads},
  } = useThreads();

  const thread = threads?.[id] ?? {};

  const {editable} = threadActions(thread);

  const menuItems = [
    {
      icon: <RxActivityLog />,
      label: 'activity',
      onClick: () => {
        setActiveItem('activity');
        setIndex('content');
      },
      message: 'All Activity',
    },
    {
      icon: <RxSewingPinFilled />,
      label: 'milestone',
      onClick: () => {
        setActiveItem('milestone');
        setIndex('content');
      },
      message: 'Milestones',
    },
    {
      icon: <MdLabelImportantOutline />,
      label: 'decision_point',
      onClick: () => {
        setActiveItem('decision_point');
        setIndex('content');
      },
      message: 'Decision Points',
    },
    {
      icon: <RxCheckbox />,
      label: 'task',
      onClick: () => {
        setActiveItem('task');
        setIndex('content');
      },
      message: 'Tasks',
    },
    {
      icon: <RxQuestionMarkCircled />,
      label: 'question',
      onClick: () => {
        setActiveItem('question');
        setIndex('content');
      },
      message: 'Questions',
    },
    {
      icon: <BiPoll />,
      label: 'poll',
      onClick: () => {
        setActiveItem('poll');
        setIndex('content');
      },
      message: 'Polls',
    },
    {
      icon: <HiOutlineDocumentText />,
      label: 'form',
      onClick: () => {
        setActiveItem('form');
        setIndex('content');
      },
      message: 'Forms',
    },
    {
      icon: <GoGitBranch />,
      label: 'break out',
      onClick: () => {
        setActiveItem('break out');
        setIndex('sub-threads');
      },
      message: 'Breakout Workspace',
    },
    // ...(editable
    //   ? [
    //       {
    //         icon: <RxLayers />,
    //         label: 'curate',
    //         onClick: () => {
    //           setActiveItem('curate');
    //           setIndex('curate-thread');
    //         },
    //         message: 'Organize Workspace',
    //       },
    //     ]
    //   : []),
  ];

  return (
    <div className="vertical-menu-bar">
      {menuItems.map((item, index) => (
        <button
          key={index}
          className={`menu-item ${activeItem === item.label ? 'active' : ''}`}
          onClick={item.onClick}>
          <TooltipRight text={item.message}>{item.icon}</TooltipRight>
        </button>
      ))}
    </div>
  );
};

export default VerticalMenuBar;
