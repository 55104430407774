import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {RxChevronRight, RxCross2, RxMagnifyingGlass} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {timeSince} from '../../../api/dates';
import constants from '../../../components/constants';
import {
  FileViewerSimple,
  ProfileImage,
} from '../../../components/inputs/uploader';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import {ContentDisplay} from '../components/content-display';
import {AgentProfile} from '../components/thread-comment';
import './ThreadSearch.css';

const ThreadMentionItem = React.memo(({thread, onClick}) => {
  const {title, logo, content} = thread;
  return (
    <div className="thread-search-grid-item" onClick={onClick}>
      <div className="thread-search-grid-item-header">
        <span className="thread-search-grid-item-logo">{logo}</span>
        <p className={`text-bold ${logo ? 'padding-left8' : ''}`}>{title}</p>
      </div>
      {/* <MagicMarkdown content={content?.slice(0, 100)} /> */}
    </div>
  );
});

const PersonMentionItem = React.memo(({person, onClick}) => {
  const {prettyName, prettyPosition} = useStringFormatter();

  return (
    <div className="thread-mention-item" onClick={onClick}>
      <div className="thread-mention-header">
        <ProfileImage
          data={person?.profile_image}
          style={{height: '40px', width: '40px'}}
        />
        <div className="mention-info">
          <p className="text-bold">{prettyName(person)}</p>
          <p className="text-secondary">{prettyPosition(person)}</p>
        </div>
      </div>
    </div>
  );
});

const ThreadFileLinkMentionItem = React.memo(({item, thread, onClick}) => {
  const {content, created, media, owner_id} = item;
  const {title: threadTitle, logo} = thread;
  const {loadProfiles} = useLoader();
  const {prettyName} = useStringFormatter();
  const {
    state: {profiles},
  } = useAuth();

  const user = profiles?.[owner_id] ?? {};
  const {profile_image} = user;

  useEffect(() => {
    if (owner_id) {
      loadProfiles([owner_id]);
    }
  }, [owner_id, loadProfiles]);

  const isFile = media && media.length > 0;

  const getPreview = useMemo(() => {
    if (isFile) {
      return <FileViewerSimple files={media} showPreview={true} />;
    }
    return null;
  }, [isFile, media]);

  return (
    <div className="mention-item">
      <div className="mention-header">
        <ProfileImage
          data={profile_image}
          style={{height: '40px', width: '40px'}}
        />
        <div className="mention-info">
          <h3 className="mention-thread-title">
            {prettyName(user)} <span className="text-secondary">from</span>{' '}
            {logo ? logo : ''} {threadTitle}
          </h3>
          <p className="mention-time">{timeSince(created)}</p>
        </div>
      </div>
      <div className="mention-content">
        <div className="mention-preview">{getPreview}</div>
      </div>
      <div className="mention-footer" onClick={onClick}>
        <span className="mention-view">View in thread</span>
        <RxChevronRight className="mention-arrow" />
      </div>
    </div>
  );
});

const SearchMentionItem = React.memo(({item, thread, onClick, onClose}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {encryptString} = useCrypto();

  const {
    state: {profiles},
  } = useAuth();

  const {owner_id, created, media} = item || {};
  const user =
    owner_id === constants.agent_user_id
      ? AgentProfile()
      : profiles?.[owner_id] || {};
  const profile_image = user?.profile_image;
  const {title: threadTitle, logo, type} = thread || {};

  useEffect(() => {
    if (owner_id) {
      loadProfiles([owner_id]);
    }
  }, [owner_id, loadProfiles]);

  const handleProfileClick = useCallback(() => {
    if (owner_id !== constants.agent_user_id) {
      const parsed = encryptString(owner_id);
      navigate(`/feed/profiles/detail/${parsed}`);
      onClose();
    }
  }, [owner_id]);

  const title = () => {
    return type === 'direct'
      ? 'Direct Message'
      : `${logo ? logo : ''} ${threadTitle}`;
  };

  return (
    <div className="mention-item">
      <div className="mention-header">
        <ProfileImage
          data={profile_image}
          style={{
            height: '40px',
            width: '40px',
          }}
          onClick={handleProfileClick}
        />
        <div className="mention-info">
          <h3 className="mention-thread-title">
            {prettyName(user)} <span className="text-secondary">from</span>{' '}
            {title()}
          </h3>
          <p className="mention-time">{timeSince(created)}</p>
        </div>
      </div>
      <div className="content-body">
        <ContentDisplay item={item} uneditable={true} />
      </div>
      {media?.length > 0 && <FileViewerSimple files={media} />}
      <div className="mention-footer">
        <span className="mention-view" onClick={onClick}>
          View {type === 'direct' ? 'message' : 'thread'}
        </span>
        <RxChevronRight className="mention-arrow" />
      </div>
    </div>
  );
});

const ThreadSearch = React.memo(
  ({
    onClose,
    searchResults,
    search,
    handleSearchChange,
    searchInputRef,
    loading,
  }) => {
    const navigate = useNavigate();
    const {encryptString} = useCrypto();

    const {
      state: {thread_content, threads},
    } = useThreads();
    const {
      state: {profiles},
    } = useAuth();

    const {contentIds, threadIds, fileLinkMentionIds, peopleIds} =
      searchResults;

    useEffect(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, []);

    const renderPersonMentions = useMemo(() => {
      return peopleIds.map(id => (
        <PersonMentionItem
          key={id}
          person={
            id === constants.agent_user_id ? AgentProfile() : profiles?.[id]
          }
          onClick={() => {
            if (id !== constants.agent_user_id) {
              const parsed = encryptString(id);
              navigate(`/feed/profiles/detail/${parsed}`);
              onClose();
            }
          }}
        />
      ));
    }, [peopleIds, profiles, navigate, onClose, encryptString]);

    const renderFileLinkMentions = useMemo(() => {
      return fileLinkMentionIds.map(id => (
        <ThreadFileLinkMentionItem
          key={id}
          item={thread_content[id]}
          thread={threads[thread_content[id].thread_id]}
          onClick={() => {
            navigate(`/threads/${thread_content[id].thread_id}/${id}`);
            onClose();
          }}
        />
      ));
    }, [fileLinkMentionIds, thread_content, threads, navigate, onClose]);

    return (
      <div className="thread-search-container overlay">
        <div className="thread-search-header">
          <div />
          <div className="search-input-container">
            <RxMagnifyingGlass className="search-icon" />
            <input
              ref={searchInputRef}
              type="text"
              className="search-input"
              placeholder="Search for workspaces, posts, files, people..."
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          <RxCross2 onClick={onClose} className="clickable" />
        </div>
        {loading ? (
          <SpinningIndicator />
        ) : (
          <div className="thread-search-results">
            {threadIds.length === 0 &&
              contentIds.length === 0 &&
              fileLinkMentionIds.length === 0 &&
              peopleIds.length === 0 && (
                <div className="thread-search-empty">
                  <RxMagnifyingGlass className="thread-search-empty-icon" />
                  <p>{search?.length > 0 ? 'No results' : 'Find anything'}</p>
                  <p>
                    You can find workspaces, people, files, or specific posts.
                  </p>
                </div>
              )}
            {peopleIds.length > 0 && (
              <h4 className="text-secondary padding-bottom8">People</h4>
            )}
            {peopleIds.length > 0 && (
              <div className="thread-search-grid padding-bottom8 ">
                {renderPersonMentions}
              </div>
            )}
            {threadIds.length > 0 && (
              <h4 className="text-secondary padding-bottom8">Workspaces</h4>
            )}
            {threadIds.length > 0 && (
              <Threads thread_ids={threadIds} onClose={onClose} />
            )}

            {contentIds.length > 0 && (
              <h4 className="text-secondary padding-top8 padding-bottom8">
                Posts & Comments
              </h4>
            )}
            <ThreadContent content_ids={contentIds} onClose={onClose} />

            {fileLinkMentionIds.length > 0 && (
              <h4 className="text-secondary padding-bottom8">Files</h4>
            )}
            {renderFileLinkMentions}
          </div>
        )}
      </div>
    );
  },
);

const Threads = ({thread_ids, onClose}) => {
  const navigate = useNavigate();
  const {
    state: {threads},
  } = useThreads();

  const [page, setPage] = useState(1);
  const page_size = 15;

  return (
    <>
      <div className="thread-search-grid padding-bottom8">
        {thread_ids.slice(0, page * page_size).map(id => (
          <ThreadMentionItem
            key={id}
            thread={threads[id]}
            onClick={() => {
              navigate(`/threads/${id}`);
              onClose();
            }}
          />
        ))}
      </div>
      {thread_ids?.length > page * page_size && (
        <button
          className="basic-button"
          onClick={() => {
            setPage(page + 1);
          }}>
          See More
        </button>
      )}
    </>
  );
};

const ThreadContent = ({content_ids, onClose}) => {
  const navigate = useNavigate();
  const {
    state: {thread_content, threads},
  } = useThreads();

  const [page, setPage] = useState(1);
  const page_size = 15;

  return (
    <>
      {content_ids.slice(0, page * page_size).map(id => (
        <SearchMentionItem
          key={id}
          item={thread_content[id]}
          thread={threads[thread_content[id].thread_id]}
          onClose={onClose}
          onClick={() => {
            let thread = threads[thread_content[id].thread_id];
            let {type} = thread;
            if (type !== 'direct') {
              navigate(`/threads/${thread.id}/${id}`);
            } else {
              navigate(`/threads?dms_active=true&dm_id=${thread.id}`);
            }
            onClose();
          }}
        />
      ))}
      {content_ids?.length > page * page_size && (
        <button
          className="basic-button"
          onClick={() => {
            setPage(page + 1);
          }}>
          See More
        </button>
      )}
    </>
  );
};

export default ThreadSearch;
