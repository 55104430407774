import {useFormik} from 'formik';
import {default as React} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {
  FaComments,
  FaConnectdevelop,
  FaLayerGroup,
  FaShieldAlt,
  FaUserPlus,
} from 'react-icons/fa';
import {GoRss} from 'react-icons/go';
import {MdSyncLock} from 'react-icons/md';
import {
  RiBrainLine,
  RiFile2Line,
  RiShieldStarLine,
  RiTeamLine,
} from 'react-icons/ri';
import {RxArrowRight, RxPerson} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import '../../App.css';
import constants from '../../components/constants';
import {
  PublicMindLogoDarkNoText,
  PublicMindLogoLightNoText,
} from '../../components/images/logos';
import {FormikTextfield} from '../../components/inputs/textfields';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';
import {CreateThreadCard} from '../threads/overview-tabs/thread-feed';
import './signin.css';
import PublicMindDifferentiators from './website-diff';
import ProjectTimeline from './website-timeline';
const Signin = () => {
  const navigate = useNavigate();
  const isMounted = useMounted();
  const {
    state: {auth, rerouting_url},
    signin,
    signOut,
  } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {email, password} = values;
        // if (auth) {
        await signOut();
        // }
        const response = await signin(email, password);
        const {success, error, isSignedIn, nextStep} = response;
        if (success && isMounted()) {
          if (isSignedIn) {
            navigate('/');
          } else {
            const {signInStep} = nextStep;
            if (signInStep && signInStep === 'CONFIRM_SIGN_UP') {
              navigate('/confirm', {state: {email, password}});
              return;
            }
            navigate('/signin');
          }
          track('sign_in', {email});
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <div className="signin-container">
      <main className="signin-main">
        <div className="signin-hero">
          <div className="signin-hero-content">
            <span className="flex justify-center">
              <PublicMindLogoDarkNoText />

              <PublicMindLogoLightNoText />
            </span>
            <h1 className="signin-hero-title">Welcome Back</h1>
            <p className="signin-hero-description">
              Sign in to access your secure workspace for government projects.
            </p>
          </div>
          <div className="signin-form-container">
            <div className="signin-form pretty-form-group">
              <h2 className="signin-form-title padding-top8">Sign In </h2>
              <p className="signin-form-description">
                Enter your credentials to access your account.
              </p>
              <form onSubmit={formik.handleSubmit}>
                <FormikTextfield
                  formik={formik}
                  header="Email"
                  placeholder="example@1.us.gov"
                  type="email"
                  name="email"
                />
                <FormikTextfield
                  formik={formik}
                  header="Password"
                  placeholder="**********"
                  name="password"
                  type="password"
                />
                {formik.errors.submit && (
                  <p className="signin-error">{formik.errors.submit}</p>
                )}
                <div className="flex justify-center padding-top8">
                  <button
                    type="submit"
                    className="basic-button"
                    disabled={formik.isSubmitting}>
                    Sign In
                  </button>
                </div>
                <button
                  type="button"
                  className="signin-forgot-password"
                  onClick={() => navigate('/forgot-password')}>
                  Forgot Password?
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const Feature = ({icon, title, description}) => (
  <div className="signin-feature">
    <div className="signin-feature-icon">{icon}</div>
    <h4 className="signin-feature-title">{title}</h4>
    {description}
  </div>
);

const StreamlineItem = ({icon, title, description}) => (
  <div className="signin-streamline-item">
    <div className="signin-streamline-icon">{icon}</div>
    <h4 className="signin-streamline-title">{title}</h4>
    {description}
  </div>
);

export const LandingPage = () => {
  const {
    state: {profiles, id: currentUserId},
  } = useAuth();
  const currentUser = profiles?.[currentUserId];
  const navigate = useNavigate();
  const version = 1.4;
  const mobile = isMobile || isTablet;

  const handleGetStarted = useCase => {
    track('get_started_use_case_click', {version, useCase});
    navigate('/threads/invite/e91ac7e1-9bd6-4125-8c6a-85de120874ea');
  };

  return (
    <div className="signin-container">
      <Header mobile={mobile} version={version} navigate={navigate} />
      <main className="signin-main">
        <HeroSection mobile={mobile} version={version} navigate={navigate} />
        <FirstCTASection />
        <ProjectTimeline />
        <UseCasesSection mobile={mobile} onGetStarted={handleGetStarted} />
        <FeaturesSection />
        {mobile && <StreamlineSection />}
        {!mobile && (
          <>
            <span style={{marginTop: '4rem'}} />
            <CreateThreadCard
              // disabled={disabled}
              onCreateThread={() => {
                track('create_thread_card_click', {version});
                window.location.href =
                  "mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance.";
              }}
              // onUpgradeLimit={handleUpgradeLimit}
              currentUser={currentUser}
            />
          </>
        )}

        <PublicMindDifferentiators />
        <FinalCTASection
          mobile={mobile}
          version={version}
          navigate={navigate}
        />
      </main>
      <PublicFooter />
    </div>
  );
};

const Header = ({mobile, version, navigate}) =>
  !mobile && (
    <header className="signin-header">
      <nav className="signin-nav">
        <div className="flex-row">
          <PublicMindLogoLightNoText />

          <h3
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 200,
              fontSize: 18,
              letterSpacing: 0.1,
              paddingTop: 16,
              paddingBottom: 16,
            }}>
            PUBLICMIND
          </h3>
        </div>
        <div className="signin-nav-links">
          <button
            className="signin-button signin-button-outline"
            onClick={() => {
              track('get_started_nav_bar_click', {version});
              window.location.href =
                "mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance.";
            }}>
            Get Started
          </button>
          <span
            className="flex-column align-center clickable"
            onClick={() => {
              track('sign_in_landing_click', {version});
              navigate('/signin');
            }}>
            <RxPerson size={'20px'} />
            <p className="text-secondary text-12">Log in</p>
          </span>
        </div>
      </nav>
    </header>
  );

const HeroSection = ({mobile, version, navigate}) => (
  <div className="signin-hero">
    <div className="signin-hero-content">
      {/* Primary headline now focuses on transformative value */}

      <h1 className="signin-hero-title">
        Safe and Secure{' '}
        <span className="signin-hero-title-key-point">Agents</span> for
        Government Projects
      </h1>

      {/* Subheadline addresses market pain point and solution */}

      <p className="signin-hero-description">
        Keep your team focused on impact, not paperwork. We'll capture the
        knowledge, handle the reporting, and make every project smarter than the
        last.
      </p>

      <div className="signin-hero-buttons">
        {!mobile && (
          <div className="button-gradient-wrapper">
            <button
              className="signin-button signin-button-primary"
              onClick={() => {
                track('try_for_free_landing_click_hero', {version});
                window.location.href =
                  "mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance.";
              }}>
              Create Your{' '}
              <strong className="landing-page-critical-color">Agent</strong>
            </button>
          </div>
        )}
        {mobile && (
          <div className="button-gradient-wrapper">
            <button
              className="signin-button signin-button-secondary"
              onClick={() => {
                track('schedule_demo_mobile_landing_click_hero', {version});
                window.location.href =
                  "mailto:help@publicmind.ai?subject=PublicMind%20Demo%20Request&body=Hello,%20I'm%20interested%20in%20seeing%20how%20PublicMind%20can%20help%20preserve%20and%20amplify%20our%20team's%20knowledge.%0A%0AWe%20are%20[brief%20description%20of%20organization]%20and%20want%20to%20learn%20more%20about%20[specific%20interest/challenge].";
              }}>
              Schedule a Demo
            </button>
          </div>
        )}
      </div>
    </div>
    <div className="signin-hero-image">
      {/* <iframe
        title="Overview Video"
        src={
          'https://www.loom.com/embed/b9fa27732fd145979368e3cad311326b?sid=27667e8b-7ffd-4a61-839f-9dd1a45fef83'
        }
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
        style={{
          borderRadius: '8px',
          width: '100%',
          height: '450px',
          maxWidth: '800px',
          aspectRatio: '16 / 9',
        }}
      /> */}
      {!isMobile && (
        <img
          src={require('../../assets/images/home-image.png')}
          alt="Intelligence at your service"
          style={{
            borderRadius: '8px',
            width: '100%',
            height: '450px',
            maxWidth: '800px',
            aspectRatio: '16 / 9',
            border: '1px solid var(--divider)',
          }}
        />
      )}
      {/* Secondary headline focuses on network effect value */}

      <h1 className="signin-hero-title-secondary">
        Your Knowledge{' '}
        <strong className="landing-page-critical-color-light">Lives</strong> and{' '}
        <strong className="landing-page-critical-color">Grows</strong>
      </h1>

      {/* Description emphasizes AI-powered automation and knowledge preservation */}
      <p className="signin-hero-description">
        Stop losing critical knowledge when key people leave. PublicMind
        automatically preserves your team's expertise, surfaces proven
        solutions, and makes every new project benefit from past successes. Your
        organization gets smarter with every project.
      </p>
    </div>

    <div className="button-gradient-wrapper">
      <button
        className="signin-button signin-button-secondary"
        onClick={() => {
          track('schedule_demo_landing_click', {version});
          window.location.href =
            "mailto:help@publicmind.ai?subject=PublicMind%20Demo%20Request&body=Hello,%20I'm%20interested%20in%20seeing%20how%20PublicMind%20can%20help%20preserve%20and%20amplify%20our%20team's%20knowledge.%0A%0AWe%20are%20[brief%20description%20of%20organization]%20and%20want%20to%20learn%20more%20about%20[specific%20interest/challenge].";
        }}>
        Schedule a <strong className="landing-page-critical-color">Demo</strong>
      </button>
    </div>
  </div>
);

const FeaturesSection = () => (
  <section className="signin-features">
    <h3 className="signin-section-title">
      <strong className="landing-page-critical-color">Enhance</strong>{' '}
      Coordination and Effectiveness in Your Projects
    </h3>
    <div className="signin-features-grid">
      <Feature
        icon={<MdSyncLock />}
        title="Bridging the Collaboration Gap"
        description={
          <p className="signin-feature-description">
            Create a{' '}
            <strong className="landing-page-critical-color">
              secure, central platform
            </strong>{' '}
            where government, academia, and industry converge. Break down silos
            and enable{' '}
            <strong className="landing-page-critical-color">
              seamless communication across organizations
            </strong>
            .
          </p>
        }
      />
      <Feature
        icon={<FaLayerGroup />}
        title="Centralized Project Management"
        description={
          <p className="signin-feature-description">
            Manage projects, share files, and coordinate efforts in{' '}
            <strong className="landing-page-critical-color">
              one unified space
            </strong>
            . Say goodbye to endless email chains and maintain{' '}
            <strong className="landing-page-critical-color">
              clear audit trails
            </strong>{' '}
            for decision-making processes.
          </p>
        }
      />
      <Feature
        icon={<FaShieldAlt />}
        title="Secure and Controlled Access"
        description={
          <p className="signin-feature-description">
            Protect sensitive data with{' '}
            <strong className="landing-page-critical-color">
              role-based access controls
            </strong>
            . Ensure that the right people have the{' '}
            <strong className="landing-page-critical-color">
              right level of access
            </strong>{' '}
            to project information.
          </p>
        }
      />
    </div>
  </section>
);

const StreamlineSection = () => (
  <section className="signin-streamline">
    <h3 className="signin-section-title">
      <strong className="landing-page-critical-color-light">Maximize</strong>{' '}
      Project Success
    </h3>
    <div className="signin-streamline-grid">
      <StreamlineItem
        icon={<GoRss className="landing-page-critical-color-light" />}
        title="Host or Discover: Projects, Events, and More"
        description={
          <p className="signin-streamline-description">
            <strong className="landing-page-critical-color-light">
              Centralize project information
            </strong>{' '}
            and updates across government, academia, and industry. Gain
            real-time insights and{' '}
            <strong className="landing-page-critical-color-light">
              eliminate information silos
            </strong>{' '}
            for seamless collaboration.
          </p>
        }
      />
      <StreamlineItem
        icon={<FaUserPlus className="landing-page-critical-color-light" />}
        title="Invite All Stakeholders"
        description={
          <p className="signin-streamline-description">
            <strong className="landing-page-critical-color-light">
              Securely onboard and manage
            </strong>{' '}
            authorized personnel with ease. Ensure data integrity and controlled
            access,{' '}
            <strong className="landing-page-critical-color-light">
              fostering trust
            </strong>{' '}
            among diverse project participants.
          </p>
        }
      />
      <StreamlineItem
        icon={<FaComments className="landing-page-critical-color-light" />}
        title="Streamlined and Auditable Communication"
        description={
          <p className="signin-streamline-description">
            <strong className="landing-page-critical-color-light">
              Replace endless email chains
            </strong>{' '}
            with organized, accessible discussions. Boost productivity and
            decision-making through clear,{' '}
            <strong className="landing-page-critical-color-light">
              centralized project communications
            </strong>
            .
          </p>
        }
      />
    </div>
  </section>
);
const FirstCTASection = ({mobile, version, navigate}) => {
  const impactStats = [
    {
      value: 'Seamless',
      label: 'Cross-Agency Connection',
      description:
        'Break through organizational barriers while maintaining security and compliance',
      icon: <RiTeamLine />,
    },
    {
      value: 'Instant',
      label: 'Knowledge Access',
      description:
        'Every insight, decision, and lesson learned available exactly when needed',
      icon: <RiBrainLine />,
    },
    {
      value: 'Automated',
      label: 'Reporting & Oversight',
      description:
        'Turn daily work into perfect documentation and comprehensive reports',
      icon: <RiFile2Line />,
    },
  ];

  return (
    <>
      {!isMobile && (
        <img
          src={require('../../assets/images/quick-capture-image.png')}
          alt="Intelligence at your service"
          style={{
            borderRadius: '8px',
            width: '100%',
            height: '450px',
            maxWidth: '800px',
            border: '1px solid var(--divider)',
            alignSelf: 'center',
          }}
        />
      )}
      <section className="final-cta-section">
        <div className="neural-network-bg"></div>

        <div className="cta-content-wrapper">
          <div className="cta-header">
            <div className="enterprise-badge">
              <RiShieldStarLine />
              <span>Built for the Public Sector</span>
            </div>

            <h2 className="cta-title">
              Your Secure Partner in{' '}
              <span className="title-highlight">Public Sector Excellence</span>
            </h2>

            <p className="cta-description">
              PublicMind understands the unique needs of public sector
              organizations. We help state agencies, educational institutions,
              and NGOs preserve expertise, reduce administrative burden, and
              deliver more impact with less overhead.
            </p>
          </div>

          <div className="impact-stats">
            {impactStats.map((stat, index) => (
              <div key={index} className="stat-card">
                <div className="stat-icon">{stat.icon}</div>
                <div className="stat-value">{stat.value}</div>
                <div className="stat-label">{stat.label}</div>
                <div className="stat-progress">
                  <div className="progress-bar"></div>
                </div>
              </div>
            ))}
          </div>
          <div className="sector-proof">
            <div className="enterprise-badge">
              <RiShieldStarLine />
            </div>
            <p className="proof-statement">
              Trusted by leading public sector organizations to preserve and
              enhance institutional knowledge while ensuring safety and
              compliance
            </p>
            {/* 
          <div className="sector-categories">
            <span className="sector-tag">State Agencies</span>
            <span className="sector-tag">Higher Education</span>
            <span className="sector-tag">K-12 Districts</span>
            <span className="sector-tag">Workforce Development</span>
          </div> */}
          </div>
          <div className="cta-actions">
            {!mobile && (
              <div className="button-gradient-wrapper">
                <button
                  className="signin-button signin-button-secondary"
                  onClick={() => {
                    track('schedule_demo_landing_click', {version});
                    window.location.href =
                      "mailto:help@publicmind.ai?subject=PublicMind%20Demo%20Request&body=Hello,%20I'm%20interested%20in%20seeing%20how%20PublicMind%20can%20help%20preserve%20and%20amplify%20our%20team's%20knowledge.%0A%0AWe%20are%20[brief%20description%20of%20organization]%20and%20want%20to%20learn%20more%20about%20[specific%20interest/challenge].";
                  }}>
                  See How We Support Your Mission
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

const FinalCTASection = ({mobile, version, navigate}) => (
  <section className="signin-final">
    <span className="flex-column align-center">
      <div className={`ai-insights-brain-icon-large ${'processing'}`}>
        <FaConnectdevelop />
      </div>
      <h3 className="signin-final-title">Less Work, More Results</h3>
    </span>
    <p className="text-secondary text-center signin-hero-description">
      A safe way to work with AI
    </p>
    <div className="signin-final-buttons">
      {!mobile && (
        <div className="button-gradient-wrapper">
          <button
            className="signin-button signin-button-primary"
            onClick={() => {
              track('launch_agent_final_cta_landing_click', {version});
              window.location.href =
                "mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance.";
            }}>
            Launch Your{' '}
            <strong className="landing-page-critical-color">Agent</strong>
          </button>
        </div>
      )}
      {mobile && (
        <div className="button-gradient-wrapper">
          <button
            className="signin-button signin-button-secondary"
            onClick={() => {
              track('schedule_demo_mobile_final_cta_landing_click', {version});
              window.location.href =
                "mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance.";
            }}>
            Schedule a{' '}
            <strong className="landing-page-critical-color">Demo</strong>
          </button>
        </div>
      )}
    </div>
  </section>
);

const PublicFooter = () => {
  return (
    <footer className="public-footer">
      <div className="public-footer-content">
        <div className="public-footer-main">
          <div className="public-footer-logo">
            <PublicMindLogoDarkNoText />
            <h3 className="public-footer-logo-text">PUBLICMIND</h3>
          </div>
          <p className="public-footer-description">
            Empowering secure collaboration for government initiatives.
          </p>
        </div>
        <div className="public-footer-links-container">
          {/* <div className="public-footer-links-column">
            <h4 className="public-footer-heading">Company</h4>
            <ul className="public-footer-links">
              <li>
                <a href="#" className="public-footer-link">
                  About
                </a>
              </li>
              <li>
                <a href="#" className="public-footer-link">
                  Features
                </a>
              </li>
              <li>
                <a
                  href="mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance."
                  className="public-footer-link">
                  Contact
                </a>
              </li>
            </ul>
          </div> */}
          <div className="public-footer-links-column">
            <h4 className="public-footer-heading">Resources</h4>
            <ul className="public-footer-links">
              <li>
                <a
                  href="mailto:help@publicmind.ai?subject=Documentation%20Access%20Request&body=Hello,%20we%20would%20like%20to%20request%20access%20to%20the%20PublicMind%20API.%0A%0AOur%20team%20is%20on%20the%20[government/academic/industry]%20side%20and%20we%20are%20looking%20to%20integrate%20PublicMind's%20capabilities%20for%20[describe%20use%20case].%0A%0AThank%20you%20for%20considering%20our%20request."
                  className="public-footer-link">
                  Documentation
                </a>
              </li>
              <li>
                <a
                  href="mailto:help@publicmind.ai?subject=API%20Access%20Request&body=Hello,%20we%20would%20like%20to%20request%20access%20to%20the%20PublicMind%20API.%0A%0AOur%20team%20is%20on%20the%20[government/academic/industry]%20side%20and%20we%20are%20looking%20to%20integrate%20PublicMind's%20capabilities%20for%20[describe%20use%20case].%0A%0AThank%20you%20for%20considering%20our%20request."
                  className="public-footer-link">
                  API
                </a>
              </li>
              <li>
                <a
                  href="mailto:help@publicmind.ai?subject=Support%20Request&body=Hello,%20I'm%20reaching%20out%20for%20support%20regarding%20an%20issue%20we%20are%20facing%20with%20PublicMind.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20need%20assistance%20with%20[describe%20the%20issue].%0A%0AThank%20you%20for%20your%20help."
                  className="public-footer-link">
                  Support
                </a>
              </li>
            </ul>
          </div>
          <div className="public-footer-links-column">
            <h4 className="public-footer-heading">Legal</h4>
            <ul className="public-footer-links">
              <li>
                <a
                  href={constants.privacy_policy}
                  className="public-footer-link">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={constants.terms_of_service}
                  className="public-footer-link">
                  Terms of Service
                </a>
              </li>
              {/* <li>
                <a href="#" className="public-footer-link">
                  Cookie Policy
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="public-footer-bottom">
        <p className="public-footer-copyright">
          © 2024 PublicMind. All rights reserved.
        </p>
        <div className="public-footer-social">
          {/* <a href="#" className="public-footer-social-link">
            <FaTwitter />
          </a>
          <a href="#" className="public-footer-social-link">
            <FaLinkedin />
          </a>
          <a href="#" className="public-footer-social-link">
            <FaGithub />
          </a> */}
        </div>
      </div>
    </footer>
  );
};

const DifferentiatorItem = ({icon, title, description}) => (
  <div className="differentiator-item">
    <div className="differentiator-icon">{icon}</div>
    <h4 className="differentiator-title">{title}</h4>
    <p className="differentiator-description">{description}</p>
  </div>
);

const UseCase = ({icon, title, description, onClick, notMobile}) => (
  <div className="use-case-card">
    <div className="use-case-icon">{icon}</div>
    <h4 className="use-case-title">{title}</h4>
    <p className="use-case-description">{description}</p>
    {notMobile && (
      <button onClick={onClick} className="use-case-button flex align-center">
        Try Use Case <RxArrowRight color="var(--primary-button)" />
      </button>
    )}
  </div>
);

const UseCasesSection = ({mobile, onGetStarted}) => {
  const useCases = [
    {
      icon: '🏢',
      title: 'Statewide Information Exchange',
      description:
        'Facilitate secure data sharing and communication between state agencies, educational institutions, and cybersecurity teams.',
    },
    {
      icon: '👥',
      title: 'Workforce Development Coordination',
      description:
        'Streamline organization of job fairs, training programs, and career events across multiple stakeholders and regions.',
    },
    {
      icon: '🎓',
      title: 'Education Sector Partnerships',
      description:
        'Foster collaboration between higher education institutions and K-12 schools for seamless educational pathways.',
    },
    {
      icon: '💼',
      title: 'Board and Council Management',
      description:
        'Simplify governance and decision-making processes for government bodies and economic support organizations.',
    },
    {
      icon: '🔗',
      title: 'Government-Academia Collaboration',
      description:
        'Create dedicated spaces for joint research projects, policy development, and knowledge exchange between government agencies and universities.',
    },
    {
      icon: '🌐',
      title: 'Regional Industry-Academia Partnerships',
      description:
        'Facilitate connections and project collaborations between local industries and academic institutions to drive innovation.',
    },
  ];

  return (
    <section className="use-cases-section">
      <h2 className="use-cases-title">
        Empowering <span className="use-cases-highlight">Collaboration</span>{' '}
        Across Sectors
      </h2>
      <p className="use-cases-description">
        Discover how PublicMind can transform inter-organizational communication
        and project management:
      </p>
      <div className="use-cases-grid">
        {useCases.map((useCase, index) => (
          <UseCase
            key={index}
            icon={useCase.icon}
            title={useCase.title}
            description={useCase.description}
            onClick={() => onGetStarted(useCase.title)}
            notMobile={!mobile}
          />
        ))}
      </div>
    </section>
  );
};
export default Signin;
