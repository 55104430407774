import React, {useMemo, useState} from 'react';

import {useNavigate} from 'react-router-dom';
import {track} from '../../../api/analytics';
import {formatWWW_MMM_DD_YYYY_TTTT} from '../../../api/dates';
import {ModalPortalAbsolute} from '../../../components/modal/modal-portal';
import {useAI} from '../../../hooks/use-ai';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import {AIInteractionManager} from '../../../manager/promptSystem';
import {templateMapping} from '../../../prompts/template-mapping';
import {
  useAIAssistantState,
  useSaveContent,
} from './ai-document-generator/ai-document-state';
import ChatControlPanel from './ai-document-generator/chat-control-panel';
import AILoadingOverlay from './ai-document-generator/loading';
import WizardView from './ai-document-generator/set-up-wizard';
import WorkspaceView from './ai-document-generator/workspace';
import './AIAssistance.css';

export const THREAD_TYPES = {
  DRAFT: 'draft',
  LIVE: 'active',
  COMPLETED: 'complete',
};

export const TEMPLATE_OPTIONS = {
  EMAIL: [
    templateMapping.short_email_summary,
    templateMapping.long_email_summary,
  ],
  SUMMARY: [
    templateMapping.short_summary,
    templateMapping.long_summary,
    templateMapping.page_summary,
    templateMapping.bullet_summary,
  ],
  PRESENTATION: [
    templateMapping.slide_presentation_1,
    templateMapping.slide_presentation_3,
    templateMapping.slide_presentation_5,
    templateMapping.slide_presentation_8,
  ],
  COMPLETED_PROJECT: [
    templateMapping.publicmind_weekly_report,
    templateMapping.knowledge_preservation,
    templateMapping.executive,
    templateMapping.concise,
    templateMapping.full,
    templateMapping.metrics,
    templateMapping.lessons,
  ],
  DRAFT_PROJECT: [
    // templateMapping.combat_planning,
    templateMapping.publicmind_weekly_planning,
    templateMapping.quick_plan,
    templateMapping.project_validation,
    templateMapping.full_plan,
    templateMapping.action_plan,
    templateMapping.resource_plan,
    templateMapping.timeline_plan,
  ],
  LIVE_PROJECT: [
    templateMapping.milestone_track,
    templateMapping.progress_report,
    templateMapping.stakeholder_brief,
    templateMapping.risk_monitor,
  ],
};

const AIAssistant = ({setActive, thread_data, selectedTemplate}) => {
  const navigate = useNavigate();
  const {
    state: {id: user_id, profile, profiles},
  } = useAuth();
  const {
    state: {threads, thread_content},
    createThread,
    createThreadContent,
    updateThread,
  } = useThreads();

  const {recordUsage} = useAI();

  const [index, setIndex] = useState(thread_data?.toComplete ? 0 : 1);
  const [showWizard, setShowWizard] = useState(true);
  const [currentTemplate, setCurrentTemplate] = useState(
    selectedTemplate ? selectedTemplate : null,
  );
  const aiManager = useMemo(
    () => new AIInteractionManager(user_id, recordUsage),
    [],
  );

  const {
    editor,
    loading,
    tokens,
    resetSystem,
    setLoading,
    streaming,
    wizardStep,
    setWizardStep,
    setStreaming,
    saving,
    setSaving,
    streamingContent,
    setStreamingContent,
    sectionConfigs,
    activeSection,
    setActiveSection,
    updateSectionConfig,
  } = useAIAssistantState({
    aiManager,
    currentTemplate,
  });

  //IF NO SECTION, duplicate prior config?

  const currentConfig = sectionConfigs[activeSection];

  const handleClose = () => setActive(false);
  const handleReload = () => {
    setCurrentTemplate(null);
    resetSystem();
  };

  const handleSendMessage = async message => {
    setLoading(true);
    setStreamingContent('');
    setShowWizard(false);
    const newMessage = {
      role: 'user',
      content: [
        {
          type: 'text',
          text: message,
        },
      ],
    };

    let newChatHistory = [...currentConfig.chatHistory, newMessage];

    updateSectionConfig('chatHistory', newChatHistory);

    if (!currentConfig?.generated && currentTemplate) {
      track('generated_initial_document', {
        config: currentConfig,
        template: currentTemplate,
      });
      await aiManager.generateInitialDocument({
        user: profile,
        currentConfig,
        currentTemplate,
        additionalConfig: {
          profiles,
          baseThreads: threads,
          baseContent: thread_content,
          currentTime: formatWWW_MMM_DD_YYYY_TTTT(),
          rootUrl: window.location.origin,
        },
        callbacks: {
          onUpdate: content => {
            setStreamingContent(prev => prev + content);
            setLoading(false);
            setStreaming(true);
          },
          onComplete: () => {
            updateSectionConfig('generated', true);
            setStreaming(false);
          },
        },
      });
    } else {
      if (currentTemplate) {
        track('generated_document_section', {
          config: currentConfig,
          template: currentTemplate,
        });
      } else {
        track('sent_message_doc_generator', {
          config: currentConfig,
        });
      }
      await aiManager.generateDocumentSectionContent({
        user: profile,
        threads: currentConfig.selectedThreads,
        files: currentConfig.files,
        currentTemplate,
        chatHistory: newChatHistory,
        editor,
        activeSection,
        additionalConfig: {
          profiles,
          baseThreads: threads,
          baseContent: thread_content,
          currentTime: formatWWW_MMM_DD_YYYY_TTTT(),
          rootUrl: window.location.origin,
        },
        callbacks: {
          onUpdate: content => {
            setStreamingContent(prev => prev + content);
            setLoading(false);
            setStreaming(true);
          },
          onComplete: response => {
            let finalChatHistory = [
              ...newChatHistory,
              {
                role: 'assistant',
                content: [
                  {
                    type: 'text',
                    text:
                      response?.fullResponse ||
                      'Something went wrong. Please try again.',
                  },
                ],
              },
            ];
            updateSectionConfig('chatHistory', finalChatHistory);
            updateSectionConfig('generated', true);
            setStreaming(false);
          },
        },
      });
    }
  };

  const handleWizardComplete = async () => {
    track('wizard_completed', {
      to_complete_thread: thread_data?.thread ? true : false,
      workspace_id: thread_data?.thread?.id,
      workspace_name: thread_data?.thread?.title,
      workspace_type: thread_data?.thread?.type,
    });
    setLoading(true);
    setIndex(1);
    updateSectionConfig('generated', true);
    let newChatHistory = [
      ...currentConfig.chatHistory,
      {
        role: 'user',
        content: [
          {
            type: 'text',
            text: currentConfig.wizard_content,
          },
        ],
      },
    ];
    await aiManager.generateInitialDocument({
      user: profile,
      currentTemplate,
      chatHistory: currentConfig.chatHistory,
      wizard_content: currentConfig.wizard_content,
      callbacks: {
        onUpdate: content => {
          setStreamingContent(prev => prev + content);
          setLoading(false);
          setStreaming(true);
        },
        onComplete: () => setStreaming(false),
      },
    });
    updateSectionConfig('chatHistory', newChatHistory);
  };

  const {saveContent, saveContentToExisting} = useSaveContent({
    editor,
    setSaving,
  });

  const handleSave = async () => {
    await saveContent({
      user_id,
      thread_data,
      createThread,
      createThreadContent,
      updateThread,

      onSuccess: id => {
        navigate(`/threads/${thread_data?.thread?.id || id}`);
        setActive(false);
      },
    });
  };

  const handleSaveToExisting = async thread_id => {
    track('saved_doc_to_current_workspace', {
      config: currentConfig,
      template: currentTemplate,
      workspace_id: thread_id,
    });
    await saveContentToExisting({
      user_id,
      thread_id,
      createThreadContent,
      updateThread,

      onSuccess: () => {
        navigate(`/threads/${thread_id}`);
        setActive(false);
      },
    });
  };

  return (
    <div className="ai-assistant-modal">
      {index === 0 && (
        <WizardView
          thread_data={thread_data}
          currentStep={wizardStep}
          config={currentConfig}
          currentTemplate={currentTemplate}
          onTemplateSelect={template => setCurrentTemplate(template)}
          onThreadSelect={thread => {
            const updatedThreads = currentConfig.selectedThreads.some(
              t => t.id === thread.id,
            )
              ? currentConfig.selectedThreads.filter(t => t.id !== thread.id)
              : [...currentConfig.selectedThreads, thread];
            updateSectionConfig('selectedThreads', updatedThreads);
          }}
          onFilesChange={files => updateSectionConfig('files', files)}
          onContentChange={content =>
            updateSectionConfig('wizard_content', content)
          }
          onStepComplete={() => {
            if (wizardStep === 2) handleWizardComplete();
            else setWizardStep(prev => prev + 1);
          }}
          onBack={() =>
            setWizardStep(prev => {
              if (prev === 1) {
                setIndex(1);
                return 1;
              }
              return prev - 1;
            })
          }
          onClose={handleClose}
        />
      )}
      {index === 1 && (
        <div className="chat-layout">
          <div className="chat-workspace">
            <WorkspaceView
              editor={editor}
              currentConfig={currentConfig}
              isStreaming={streaming}
              streamingContent={streamingContent}
              thread_data={thread_data}
              onClose={handleClose}
              onSave={handleSave}
              onSaveToExisting={handleSaveToExisting}
              isLoading={loading}
              isSaving={saving}
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              handleReload={handleReload}
            />
          </div>

          <ChatControlPanel
            onSendMessage={handleSendMessage}
            currentConfig={currentConfig}
            onThreadSelect={updatedThreads => {
              updateSectionConfig('selectedThreads', updatedThreads);
            }}
            onTemplateSelect={template => setCurrentTemplate(template)}
            setFiles={files => updateSectionConfig('files', files)}
            showWizard={showWizard}
            onLaunchWizard={() => setIndex(0)}
            currentTemplate={currentTemplate}
            isStreaming={streaming}
            isLoading={loading}
            thread_data={thread_data}
          />

          {loading && <AILoadingOverlay />}
        </div>
      )}
    </div>
  );
};

export const AssistantModal = ({
  active,
  setActive,
  thread_data,
  selectedTemplate,
}) => {
  if (!active) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        paddingTop: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}>
      <ModalPortalAbsolute>
        <AIAssistant
          setActive={setActive}
          thread_data={thread_data}
          selectedTemplate={selectedTemplate}
        />
      </ModalPortalAbsolute>
    </div>
  );
};
