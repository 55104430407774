import {filterSuggestionItems} from '@blocknote/core';
import {BlockNoteView} from '@blocknote/mantine';
import {SuggestionMenuController, useCreateBlockNote} from '@blocknote/react';
import {useFormik} from 'formik';
import React, {useState} from 'react';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import {dateToTimestamp, formatWWW_DD_MMM_TTT} from '../../api/dates';
import '../../App.css';
import {useAuth} from '../../hooks/use-auth';
import useStringFormatter from '../../hooks/use-string-formatter';
import {useThreads} from '../../hooks/use-threads';
import {TypeToggle} from '../../pages/threads/pages/create-thread';
import {setupBlocks} from '../blocks/divider';
import {blockInputTheme, schema} from '../inputs/block-input';
import {DateTimeInput} from '../inputs/date-input';
import {FormikTextfield, MultilineFormikTextfield} from '../inputs/textfields';
import {Modal} from './modal';
import './SpecialCommentModal.css';
import {EditMembersContent} from './thread-modals';

export const ActiveThreadModal = ({active, setActive, thread}) => {
  if (!active) {
    return null;
  }

  return (
    <ActiveThreadModalContent
      active={active}
      setActive={setActive}
      thread={thread}
    />
  );
};

const ActiveThreadModalContent = ({active, setActive, thread}) => {
  const {
    state: {id: user_id},
  } = useAuth();
  const {updateThread} = useThreads();

  const formik = useFormik({
    initialValues: {
      id: thread.id,
      title: thread.title,
      content: thread.content,
      security_level: thread.security_level,
      permissions: thread.permissions,
      status: 'active',
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values, helpers) => {
      try {
        const {permissions} = values;
        const members = permissions.map(permission => permission.user_id);
        const update = {
          ...values,
          members,
        };
        console.log(update);
        const {success, error} = await updateThread(update);

        if (success) {
          track('thread_updated', {update, user_id});
          helpers.resetForm();
          setActive(false);
        }
        if (error) {
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <h3 className="padding-bottom8">Change to Group Workspace</h3>
      <div className="pretty-form-group">
        <TypeToggle formik={formik} />
        <FormikTextfield formik={formik} name="title" header="Title" />
        <MultilineFormikTextfield
          formik={formik}
          name="content"
          header="Description"
        />
        <br />
        <EditMembersContent formik={formik} thread={thread} />
        <br />
        <div className="flex justify-center">
          <button
            className="basic-button"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            Set Active
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const CompleteThreadModal = ({
  active,
  setActive,
  thread,
  setConfetti,
}) => {
  if (!active) {
    return null;
  }

  return (
    <CompleteThreadModalContent
      active={active}
      setActive={setActive}
      thread={thread}
      setConfetti={setConfetti}
    />
  );
};

const CompleteThreadModalContent = ({
  active,
  setActive,
  thread,
  setConfetti,
}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {id: user_id, profiles},
  } = useAuth();
  const {updateThread, createThreadContent} = useThreads();

  const {members} = thread;
  const [chosenDate, setDate] = useState(null);

  const editor = useCreateBlockNote(
    setupBlocks({
      schema: schema,
      trailingBlock: false,
    }),
  );

  const getCurrentTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? blockInputTheme.dark : blockInputTheme.light;
  };

  const users = members?.map(email => {
    const pretty = prettyName(profiles?.[email] ?? {email: email, id: email});
    return {name: pretty, email};
  });

  const getMentionMenuItems = editor => {
    return users.map(user => ({
      title: user.name,
      onItemClick: () => {
        editor.insertInlineContent([
          {
            type: 'mention',
            props: {
              user: user.name,
              email: user.email,
            },
          },
          ' ',
        ]);
      },
    }));
  };

  const formik = useFormik({
    initialValues: {
      owner_id: 'assistant',
      thread_id: thread.id,
      type: 'comment',
      status: 'active',
      priority: false,
      content: '',
      content_html: '',
      version: 'block',
      media: [],
      mentions: [],
      responses: [],
      parent: null,
      scheduled: null,
      expiration: null,
      dates: [],
      metrics: [],
      tasks: [],
      sub_type: null,
    },
    validationSchema: Yup.object({}),

    onSubmit: async (values, helpers) => {
      try {
        const now = dateToTimestamp();
        const content = {
          id: v4(),
          ...values,
          created: now,
          updated: now,
        };

        if (chosenDate) {
          const last = editor.document[editor.document?.length - 1];
          const pretty_date = formatWWW_DD_MMM_TTT(chosenDate);

          editor.insertBlocks(
            [{type: 'paragraph', content: `Completed on ${pretty_date}`}],
            last,
            'after',
          );
          const stringy = JSON.stringify(editor.document);
          const content_html = await editor.blocksToHTMLLossy();
          content.content = stringy;
          content.content_html = content_html;
        }

        const {success, error} = await createThreadContent(content);

        if (success) {
          const update = {id: thread?.id, status: 'complete'};
          await updateThread(update);
          setConfetti(true);
          track('thread_updated', {update, user_id});
          helpers.resetForm();
          setActive(false);
        }
        if (error) {
        }
      } catch (err) {
        console.log(err);
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Modal active={active} setActive={setActive}>
      <h3>Mark Your Thread Complete</h3>
      <label>
        Concisely summarize the key lessons learned, outcomes, and project
        overview. This comprehensive summary will serve as a valuable resource
        for future reference and decision-making processes. This can be undone.
      </label>
      <div className="pretty-form-group">
        <div
          style={{
            maxHeight: '300px',
            minHeight: '120px',
            overflowY: 'scroll',
            transition: 'height 0.3s ease',
            border: '1px solid var(--divider)',
            borderRadius: '4px',
            marginTop: '8px',
            marginBottom: '8px',
          }}>
          <BlockNoteView
            editor={editor}
            theme={getCurrentTheme()}
            onChange={async () => {
              const stringy = JSON.stringify(editor.document);
              const content_html = await editor.blocksToHTMLLossy();
              const mentions = [];
              editor.forEachBlock(block => {
                const {content} = block;
                if (!Array.isArray(content)) {
                  return;
                }

                content.forEach(item => {
                  const {type, props} = item;
                  if (
                    item.type === 'mention' &&
                    !mentions?.includes(props.email)
                  )
                    mentions.push(props.email);
                });
              });
              formik.setFieldValue('mentions', mentions);
              formik.setFieldValue('content_html', content_html);
              formik.setFieldValue('content', stringy);
            }}>
            <SuggestionMenuController
              triggerCharacter={'@'}
              getItems={async query =>
                filterSuggestionItems(getMentionMenuItems(editor), query)
              }
            />
          </BlockNoteView>
        </div>
        <DateTimeInput
          date={chosenDate}
          setDate={setDate}
          label={'When was this project completed? '}
        />
      </div>
      <br />
      <div className="flex-row align-center justify-between">
        <button
          onClick={async () => {
            const update = {id: thread?.id, status: 'complete'};
            await updateThread(update);
            setActive(false);
          }}>
          Skip and Mark Complete
        </button>
        <button
          className="basic-button"
          onClick={formik.handleSubmit}
          disabled={
            !editor?.document?.[0].content?.length || formik.isSubmitting
          }>
          Post and Mark Complete
        </button>
      </div>
    </Modal>
  );
};
