import {useCreateBlockNote} from '@blocknote/react';
import {useEffect, useState} from 'react';
import {v4} from 'uuid';
import {track} from '../../../../api/analytics';
import {
  dateToTimestamp,
  formatWWW_MMM_DD_YYYY_TTTT,
} from '../../../../api/dates';
import {setupBlocks} from '../../../../components/blocks/divider';
import {schema} from '../../../../components/inputs/block-input';
import {useAuth} from '../../../../hooks/use-auth';
import {useThreads} from '../../../../hooks/use-threads';
import {countTokens} from '../../../../utils/utils';

export const getDocumentSectionCount = editor => {
  let sectionCount = 1; // Start with 1 as there's always at least one section
  editor.forEachBlock(block => {
    if (block.type === 'divider') {
      sectionCount++;
    }
  });
  return sectionCount;
};

export const createSectionConfig = baseConfig => ({
  selectedThreads: baseConfig.selectedThreads || [],
  files: Array.isArray(baseConfig.files) ? baseConfig.files : [],
  chatHistory: Array.isArray(baseConfig.chatHistory)
    ? baseConfig.chatHistory
    : [],
  generated: baseConfig.generated || false,
  wizard_content: '',
  tokens: baseConfig.tokens || 0,
});

export const useAIAssistantState = ({aiManager, currentTemplate}) => {
  const {
    state: {profiles, profile},
  } = useAuth();
  const {
    state: {threads, thread_content},
  } = useThreads();
  // Core states
  const [loading, setLoading] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [saving, setSaving] = useState(false);
  const [streamingContent, setStreamingContent] = useState('');
  const [threadAIData, setThreadAIData] = useState(null);

  // Editor setup
  const editor = useCreateBlockNote(
    setupBlocks({
      schema: schema,
      trailingBlock: false,
    }),
  );

  // Wizard states
  const [wizardStep, setWizardStep] = useState(1);

  // Section states
  const [activeSection, setActiveSection] = useState(0);

  //EDIT BASED ON NEW CONFIG
  const [sectionConfigs, setSectionConfigs] = useState({
    0: createSectionConfig({
      selectedThreads: [],
      files: [],
      chatHistory: [
        {
          role: 'user',
          content: [
            {
              type: 'text',
              text: `No context, files, project threads, and existing thread information provided by user`,
            },
          ],
        },
      ],
      generated: false,
      wizard_content: '',
      tokens: 0,
    }),
  });

  const {
    chatHistory = [],
    selectedThreads,
    files,
    tokens,
  } = sectionConfigs[activeSection] || {};

  useEffect(() => {
    const initializeContext = async () => {
      if (!sectionConfigs[activeSection]) return;

      try {
        const context = await aiManager.getContext({
          user: profile,
          threads: selectedThreads,
          files: files,
          additionalConfig: {
            profiles,
            baseThreads: threads,
            baseContent: thread_content,
            currentTime: formatWWW_MMM_DD_YYYY_TTTT(),
            rootUrl: window.location.origin,
          },
          outputTemplate: currentTemplate,
        });

        const initialMessage = {
          role: 'user',
          content: [{type: 'text', text: context}],
        };

        chatHistory[0] = initialMessage;
        updateSectionConfig('chatHistory', chatHistory);
        updateContextCounts(chatHistory, activeSection);
      } catch (error) {
        console.error('Error initializing context:', error);
      }
    };

    initializeContext();
  }, [activeSection, selectedThreads, files, currentTemplate]);

  const updateContextCounts = (history, activeSec) => {
    if (history && activeSec === activeSection) {
      let sum = 0;

      history.forEach(message => {
        const {role, content} = message;
        content?.forEach(data => {
          const {type, text} = data;
          if (type === 'text') {
            const count = countTokens(text);
            sum += count;
          }
        });
      });
      updateSectionConfig('tokens', sum);
    }
  };

  const updateSectionConfig = (key, value) => {
    track('updated_config_doc_generator', {
      item: key,
      update: value,
    });
    setSectionConfigs(prev => ({
      ...prev,
      [activeSection]: {
        ...prev[activeSection],
        [key]: value,
      },
    }));
  };

  const addSectionConfig = insertAfterIndex => {
    setSectionConfigs(prev => {
      // Get the configuration to duplicate (either the previous section or the last section)
      const configToDuplicate =
        prev[insertAfterIndex] || prev[Object.keys(prev).length - 1];

      // Create new config based on the previous one
      const newConfig = createSectionConfig({
        selectedThreads: [...configToDuplicate.selectedThreads],
        files: [...configToDuplicate.files],
        chatHistory: [configToDuplicate.chatHistory[0]], // Only keep the initial context message
        generated: configToDuplicate.generated,
        wizard_content: configToDuplicate.wizard_content,
      });

      // Shift all section indices after the insertion point
      const updatedConfigs = {};
      Object.entries(prev).forEach(([index, config]) => {
        const numIndex = Number(index);
        if (numIndex <= insertAfterIndex) {
          updatedConfigs[numIndex] = config;
        } else {
          updatedConfigs[numIndex + 1] = config;
        }
      });

      // Insert new config
      updatedConfigs[insertAfterIndex + 1] = newConfig;

      return updatedConfigs;
    });
  };

  const removeSectionConfig = removeIndex => {
    setSectionConfigs(prev => {
      // Can't remove if it's the only section
      if (Object.keys(prev).length <= 1) return prev;

      const updatedConfigs = {};
      Object.entries(prev).forEach(([index, config]) => {
        const numIndex = Number(index);
        if (numIndex < removeIndex) {
          updatedConfigs[numIndex] = config;
        } else if (numIndex > removeIndex) {
          updatedConfigs[numIndex - 1] = config;
        }
      });

      return updatedConfigs;
    });

    // Update activeSection if needed
    setActiveSection(current => {
      if (current === removeIndex) {
        return Math.max(0, current - 1);
      }
      if (current > removeIndex) {
        return current - 1;
      }
      return current;
    });
  };
  // Function to sync section configs with document structure
  const syncSectionConfigs = currentConfigs => {
    const documentSectionCount = getDocumentSectionCount(editor);
    const configSectionCount = Object.keys(currentConfigs).length;

    if (documentSectionCount > configSectionCount) {
      // Add missing configs
      for (let i = configSectionCount; i < documentSectionCount; i++) {
        addSectionConfig(i - 1);
      }
    } else if (documentSectionCount < configSectionCount) {
      // Remove extra configs
      for (let i = configSectionCount - 1; i >= documentSectionCount; i--) {
        removeSectionConfig(i);
      }
    }
  };

  const resetSystem = () => {
    // Reset core states
    setLoading(false);
    setStreaming(false);
    setSaving(false);
    setStreamingContent('');
    setThreadAIData(null);

    // Reset wizard state
    setWizardStep(1);

    // Reset active section
    setActiveSection(0);

    // Reset section configs to initial state
    setSectionConfigs({
      0: createSectionConfig({
        selectedThreads: [],
        files: [],
        chatHistory: [
          {
            role: 'user',
            content: [
              {
                type: 'text',
                text: `No context, files, project threads, and existing thread information provided by user`,
              },
            ],
          },
        ],
        generated: false,
        wizard_content: '',
        tokens: 0,
      }),
    });
    let allBlocks = editor.document.flatMap(x => x.id);
    // Reset the editor state
    editor.removeBlocks(allBlocks);
  };

  useEffect(() => {
    if (!editor || streaming) return;

    const syncSectionsWithCurrentConfig = () => {
      syncSectionConfigs(sectionConfigs);
    };

    const unsubscribe = editor.onChange(syncSectionsWithCurrentConfig);
    return () => unsubscribe();
  }, [editor, streaming, sectionConfigs]);

  return {
    editor,
    loading,
    resetSystem,
    setLoading,
    saving,
    streaming,
    setStreaming,
    setSaving,
    streamingContent,
    setStreamingContent,
    wizardStep,
    setWizardStep,
    sectionConfigs,
    setSectionConfigs,
    threadAIData,
    activeSection,
    setActiveSection,
    updateSectionConfig,
    syncSectionConfigs,
  };
};

export const useSaveContent = ({editor, setSaving}) => {
  const saveContent = async ({
    user_id,
    thread_data,
    createThread,
    createThreadContent,
    updateThread,
    onSuccess,
  }) => {
    if (!editor) return;
    const {thread, toComplete} = thread_data || {};
    setSaving(true);
    try {
      const whereToSave = toComplete ? thread?.id : v4();
      const now = dateToTimestamp();
      const content = JSON.stringify(editor.document);
      const content_html = await editor.blocksToHTMLLossy();

      // Extract mentions
      const mentions = [];
      editor.forEachBlock(block => {
        const blockContent = block.content;
        if (!Array.isArray(blockContent)) return;

        blockContent.forEach(item => {
          const {type, props} = item;
          if (type === 'mention' && !mentions.includes(props.email)) {
            mentions.push(props.email);
          }
        });
      });

      if (!toComplete) {
        let title = '';

        editor.forEachBlock(block => {
          if (!title && block.type === 'heading') {
            title = block.content?.[0].text;
            return;
          }
        });
        if (!title) {
          title = await editor.blocksToMarkdownLossy(editor.document);
          title = title.split('\n')[0];
        }

        const thread = {
          id: whereToSave,
          owner_id: user_id,
          title,
          status: 'draft',
          type: 'thread',
          security_level: 'private',
          permissions: [{user_id, role: 'owner', created: now, updated: now}],
          members: [user_id],
          created: now,
          updated: now,
        };

        const {success} = await createThread(thread);

        if (success) {
          await createThreadContent({
            id: v4(),
            owner_id: 'assistant',
            thread_id: whereToSave,
            type: 'comment',
            status: 'active',
            priority: false,
            content,
            content_html,
            version: 'block',
            created: now,
            updated: now,
          });
          onSuccess?.(whereToSave);
        }
      } else {
        const {success} = await updateThread({
          id: whereToSave,
          status: 'complete',
        });

        if (success) {
          await createThreadContent({
            id: v4(),
            owner_id: 'assistant',
            thread_id: whereToSave,
            type: 'comment',
            status: 'active',
            priority: false,
            content,
            content_html,
            version: 'block',
            created: now,
            updated: now,
          });
        }
        onSuccess?.(null);
      }
    } catch (error) {
      console.error('Error saving thread:', error);
      throw error;
    } finally {
      if (thread_data?.toComplete) {
        track('completed_thread_via_doc_generator', {
          workspace_id: thread_data?.thread?.id,
          workspace_name: thread_data?.thread?.title,
        });
      } else {
        track('saved_doc_to_new_workspace', {});
      }
      setSaving(false);
    }
  };
  const saveContentToExisting = async ({
    thread_id,
    createThreadContent,
    onSuccess,
  }) => {
    if (!editor) return;

    setSaving(true);
    try {
      const now = dateToTimestamp();
      const content = JSON.stringify(editor.document);
      const content_html = await editor.blocksToHTMLLossy();

      // Extract mentions
      const mentions = [];
      editor.forEachBlock(block => {
        const blockContent = block.content;
        if (!Array.isArray(blockContent)) return;

        blockContent.forEach(item => {
          const {type, props} = item;
          if (type === 'mention' && !mentions.includes(props.email)) {
            mentions.push(props.email);
          }
        });
      });

      await createThreadContent({
        id: v4(),
        owner_id: 'assistant',
        thread_id: thread_id,
        type: 'comment',
        status: 'active',
        priority: false,
        content,
        content_html,
        version: 'block',
        created: now,
        updated: now,
      });

      onSuccess?.();
    } catch (error) {
      console.error('Error saving thread:', error);
      throw error;
    } finally {
      setSaving(false);
    }
  };
  return {saveContent, saveContentToExisting};
};
