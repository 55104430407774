import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {RxCross2, RxReload} from 'react-icons/rx';
import ReactMarkdown from 'react-markdown';
import {track} from '../../../api/analytics';
import {
  formatWWW_MMM_DD_YYYY_TTTT,
  readableDateandTime,
} from '../../../api/dates';
import {isProUser} from '../../../components/system-status/system-status';
import {estimateOperationCost} from '../../../contexts/ai-context';
import {useAI} from '../../../hooks/use-ai';
import {useAuth} from '../../../hooks/use-auth';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import {
  AI_INTERACTION_TYPES,
  AIInteractionManager,
} from '../../../manager/promptSystem';
import {countTokens} from '../../../utils/utils';
import './AIFloatingChat.css';

const TypingIndicator = () => (
  <div className="chat-message assistant-message">
    <div className="typing-indicator">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
);

// Helper component to show cost-related warnings
const CostWarning = ({isOverLimit, estimatedCost, availableSpending}) => {
  if (!isOverLimit || availableSpending <= 0) return null;

  return (
    <div className="padding-bottom8 padding-left8">
      {availableSpending <= 0 ? (
        <p className="text-10">
          Daily spending limit reached. Please try again tomorrow or contact
          support to increase your limit.
        </p>
      ) : (
        <p className="text-10">
          This message would exceed your remaining budget of $
          {availableSpending.toFixed(2)}. Estimated cost: $
          {estimatedCost.toFixed(2)}
        </p>
      )}
    </div>
  );
};

const AIChatAssistant = ({sub, thread, onClose}) => {
  const {prettyName} = useStringFormatter();
  const {
    state: {id, profile, profiles},
  } = useAuth();

  const {
    state: {threads, thread_content},
  } = useThreads();

  const {state, getUsageStats, recordUsage, canPerformOperation} = useAI();

  const aiManager = React.useMemo(
    () => new AIInteractionManager(id, recordUsage),
    [],
  );

  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [costStatus, setCostStatus] = useState({
    isOverLimit: false,
    estimatedCost: 0,
    availableSpending: 0,
  });

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const hasLoadedInitialSummary = useRef(false);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const checkOperationViability = useCallback(
    async message => {
      // Estimate tokens for the message
      const estimatedInputTokens = countTokens(message);
      const estimatedOutputTokens = estimatedInputTokens * 2; // Assume response is roughly twice as long

      // Estimate costs for both input and output
      const inputCost = estimateOperationCost(
        estimatedInputTokens,
        AI_INTERACTION_TYPES.WORKSPACE_ASSISTANT.model,
        'input',
      );
      const outputCost = estimateOperationCost(
        estimatedOutputTokens,
        AI_INTERACTION_TYPES.WORKSPACE_ASSISTANT.model,
        'output',
      );
      const totalEstimatedCost = inputCost + outputCost;

      // Check if operation is viable
      const operationCheck = canPerformOperation(
        id,
        estimatedInputTokens + estimatedOutputTokens,
        AI_INTERACTION_TYPES.WORKSPACE_ASSISTANT.model,
        'output',
      )(state);

      // Get current spending stats
      const {daily} = getUsageStats(id)(state);

      setCostStatus({
        isOverLimit: !operationCheck.viable,
        estimatedCost: totalEstimatedCost,
        availableSpending: daily.remaining,
      });

      return operationCheck.viable;
    },
    [id, state, canPerformOperation, getUsageStats],
  );

  const generateInitialSummary = useCallback(async () => {
    if (hasLoadedInitialSummary.current || !thread) return;
    hasLoadedInitialSummary.current = true;
    setIsLoading(true);

    const isViable = await checkOperationViability('Generate initial summary');
    if (!isViable) {
      setMessages([
        {
          type: 'assistant',
          content:
            'I apologize, but you have reached your daily spending limit. Please try again tomorrow or [contact support](mailto:help@publicmind.ai) to increase your limit.',
        },
      ]);
      setIsLoading(false);
      return;
    }

    try {
      const userContext = {
        name: prettyName(profile),
        position: profile?.position,
        bio: profile?.bio,
        accessLevel: isProUser(id) ? 'Pro User' : 'Standard User',
      };

      await aiManager.generateContent({
        interactionType: AI_INTERACTION_TYPES.WORKSPACE_ASSISTANT,
        user: userContext,
        threads: [thread],
        action: 'workspace_assistant',
        additionalConfig: {
          profiles,
          baseContent: thread_content,
          baseThreads: threads,
          currentTime: formatWWW_MMM_DD_YYYY_TTTT(),
          rootUrl: window.location.origin,
          customContext: `Please provide a brief summary of the thread project data in 2-3 sentences since I last updated this thread ${readableDateandTime(
            sub?.last_read,
          )}`,
        },
        callbacks: {
          onUpdate: newContent => {
            setMessages(prev => [
              {
                type: 'assistant',
                content: (prev[0]?.content || '') + newContent,
              },
            ]);
          },
          onComplete: response => {},
          onError: error => {
            console.error('Error generating insights:', error);
            setMessages([
              {
                type: 'assistant',
                content:
                  '# Workspace Update Temporarily Unavailable\n\nI apologize for the interruption. Please try refreshing in a moment.',
              },
            ]);
          },
        },
      });
    } catch (error) {
      console.error('Error loading insights:', error);
    } finally {
      track('launched_workspace_assistant', {
        workspace_id: thread?.id,
        workspace_name: thread?.title,
        workspace_type: thread?.type,
      });
      setIsLoading(false);
    }
  }, [thread, profile]);

  useEffect(() => {
    generateInitialSummary();
  }, [generateInitialSummary]);

  // Check costs whenever input message changes
  useEffect(() => {
    if (inputMessage.trim()) {
      checkOperationViability(inputMessage);
    }
  }, [inputMessage]);

  const handleSendMessage = useCallback(
    async message => {
      const trimmedMessage = inputMessage.trim() || message.trim();
      if (!trimmedMessage) return;

      const isViable = await checkOperationViability(trimmedMessage);
      if (!isViable) return;

      setMessages(prev => [...prev, {type: 'user', content: trimmedMessage}]);
      setInputMessage('');
      setIsLoading(true);

      try {
        const userContext = {
          name: prettyName(profile),
          position: profile?.position,
          bio: profile?.bio,
          accessLevel: isProUser(id) ? 'Pro User' : 'Standard User',
        };

        const chatHistory = messages.map(msg => ({
          role: msg.type === 'user' ? 'user' : 'assistant',
          content: [{type: 'text', text: msg.content}],
        }));

        chatHistory.push({
          role: 'user',
          content: [{type: 'text', text: trimmedMessage}],
        });

        await aiManager.generateContent({
          interactionType: AI_INTERACTION_TYPES.WORKSPACE_ASSISTANT,
          user: userContext,
          threads: [thread],
          chatHistory,
          additionalConfig: {
            profiles,
            baseContent: thread_content,
            baseThreads: threads,
            currentTime: formatWWW_MMM_DD_YYYY_TTTT(),
            rootUrl: window.location.origin,
          },
          callbacks: {
            onUpdate: newContent => {
              setMessages(prev => {
                const newMessages = [...prev];
                const lastMessage = newMessages[newMessages.length - 1];

                if (lastMessage?.type === 'assistant') {
                  lastMessage.content = lastMessage.content + newContent;
                  return [...newMessages];
                }
                return [
                  ...newMessages,
                  {type: 'assistant', content: newContent},
                ];
              });
            },
            onComplete: response => {
              track('sent_workspace_assistant_message', {
                workspace_id: thread?.id,
                workspace_name: thread?.title,
                workspace_type: thread?.type,
                message: messages?.[messages?.length - 1],
              });
              setIsLoading(false);
            },
            onError: error => {
              console.error('Error getting AI response:', error);
              setMessages(prev => [
                ...prev,
                {
                  type: 'assistant',
                  content:
                    'I apologize, but I encountered an error. Please try again later.',
                },
              ]);
              setIsLoading(false);
            },
          },
        });
      } catch (error) {
        console.error('Error getting AI response:', error);
        setMessages(prev => [
          ...prev,
          {
            type: 'assistant',
            content:
              'I apologize, but I encountered an error. Please try again later.',
          },
        ]);
        setIsLoading(false);
      }
    },
    [
      inputMessage,
      messages,
      aiManager,
      thread,
      profile,
      prettyName,
      id,
      profiles,
      thread_content,
      threads,
      checkOperationViability,
    ],
  );

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter' && !e.shiftKey && !costStatus.isOverLimit) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage, costStatus.isOverLimit],
  );

  const handleReset = useCallback(() => {
    hasLoadedInitialSummary.current = false;
    setMessages([]);
    generateInitialSummary();
  }, [generateInitialSummary]);

  const messageElements = useMemo(() => {
    if (messages.length === 1) {
      return (
        <div className="chat-message assistant-message">
          <div className="chat-message-content">
            <ReactMarkdown>{messages[0].content}</ReactMarkdown>
          </div>
        </div>
      );
    }
    return messages.map((message, index) => (
      <div key={index} className={`chat-message ${message.type}-message`}>
        <div className="chat-message-content">
          <ReactMarkdown>{message.content}</ReactMarkdown>
        </div>
      </div>
    ));
  }, [messages]);

  return (
    <div className="chat-assistant">
      <div className="chat-assistant-header">
        <span className="flex-column">
          <h3>
            {threads?.[sub?.thread_id]?.logo} {threads?.[sub?.thread_id]?.title}
          </h3>
          <p className="text-12 text-secondary">
            Below, PublicMind Agent is restricted to this workspace.
          </p>
        </span>
        <button
          className="chat-assistant-close"
          onClick={onClose}
          aria-label="Close chat">
          <RxCross2 />
        </button>
      </div>

      <div className="chat-assistant-messages">
        {messageElements}
        {loading && <TypingIndicator />}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-assistant-input">
        <CostWarning
          isOverLimit={costStatus.isOverLimit}
          estimatedCost={costStatus.estimatedCost}
          availableSpending={costStatus.availableSpending}
        />
        <textarea
          ref={inputRef}
          value={inputMessage}
          onChange={e => setInputMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message..."
          rows={1}
        />
        <div className="chat-assistant-actions">
          <button
            className="chat-action-button"
            onClick={handleReset}
            disabled={costStatus.isOverLimit}
            title="Reset conversation">
            <RxReload />
          </button>
          <button
            className="chat-send-button"
            onClick={() => handleSendMessage()}
            disabled={
              !inputMessage.trim() || loading || costStatus.isOverLimit
            }>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AIChatAssistant);
