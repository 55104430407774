// ThreadButtons.js
import React, {useEffect, useRef, useState} from 'react';
import {HiOutlineTemplate} from 'react-icons/hi';
import {MdOutlineWorkspaces} from 'react-icons/md';
import {
  RxActivityLog,
  RxChatBubble,
  RxCheckbox,
  RxGear,
  RxHamburgerMenu,
  RxHome,
  RxPlusCircled,
} from 'react-icons/rx';
import {VscMention} from 'react-icons/vsc';
import {useNavigate, useParams} from 'react-router-dom';
import {dateToTimestamp} from '../../../api/dates';
import {ProfileImage} from '../../../components/inputs/uploader';
import {useAuth} from '../../../hooks/use-auth';
import './ThreadButtons.css';

export const ThreadButtons = ({
  darkMode,
  setDMActive,
  feedCount = 0,
  activityCount = 0,
  tasksCount = 0,
  mentionsCount = 0,
  draftsCount = 0,
}) => {
  const {tab} = useParams();
  const [cur, setCur] = useState(tab);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({top: 0, left: 0});
  const menuContainerRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (tab) setCur(tab);
    if (!tab) setCur('thread-detail');
  }, [tab]);

  useEffect(() => {
    if (isMenuOpen && menuContainerRef.current && menuRef.current) {
      const containerRect = menuContainerRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let top = containerRect.bottom;
      let left = containerRect.left;

      if (left + menuRect.width > viewportWidth) {
        left = viewportWidth - menuRect.width - 10;
      }

      if (top + menuRect.height > viewportHeight) {
        top = containerRect.top - menuRect.height;
      }

      setMenuPosition({top, left});
    }
  }, [isMenuOpen]);

  const renderButton = (icon, title, indexName, count) => (
    <button
      key={title}
      className={`sidebar-action-button ${cur === indexName ? 'active' : ''}`}
      onClick={() => {
        if (indexName === 'direct-messages') {
          setDMActive(true);
        } else if (indexName === 'thread') {
          navigate('/threads/create');
        } else if (indexName === 'workspaces') {
          navigate(`/threads`, {
            state: {showAll: true, timestamp: dateToTimestamp()},
          });
        } else {
          navigate(`/threads`, {
            state: {tab: indexName, timestamp: dateToTimestamp()},
          });
        }
        setIsMenuOpen(false);
      }}
      aria-label={title}>
      <div className="button-content">
        {icon}

        <span className="button-title">
          <span className={`${indexName === 'thread' ? '' : ''}`}>{title}</span>
        </span>

        {count > 0 && <span className="notification-count">{count}</span>}
      </div>
    </button>
  );

  const menuItems = [
    {
      icon: <MdOutlineWorkspaces />,
      title: 'Workspaces',
      indexName: 'workspaces',
      count: activityCount,
    },
    {
      icon: <RxActivityLog />,
      title: 'Posts',
      indexName: 'activity',
      count: activityCount,
    },
    {
      icon: <VscMention />,
      title: 'Mentions',
      indexName: 'mentions',
      count: mentionsCount,
    },

    {
      icon: <RxCheckbox />,
      title: 'Tasks',
      indexName: 'tasks',
      count: tasksCount,
    },

    {
      icon: <HiOutlineTemplate />,
      title: 'Templates',
      indexName: 'templates',
      count: 0,
    },
    {
      icon: <RxChatBubble />,
      title: 'Messages',
      indexName: 'direct-messages',
      count: 0,
    },
  ];

  return (
    <div className="sidebar-actions">
      <div
        className="menu-container"
        ref={menuContainerRef}
        onClick={() => setIsMenuOpen(!isMenuOpen)}

        // onMouseLeave={() => setIsMenuOpen(false)}
      >
        <button
          className={`sidebar-action-button ${isMenuOpen ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Menu">
          <div className="button-content">
            <RxHamburgerMenu />
          </div>
        </button>
        {isMenuOpen && (
          <div
            className="grid-submenu"
            ref={menuRef}
            style={{
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              position: 'fixed',
            }}>
            {menuItems.map(item =>
              renderButton(item.icon, item.title, item.indexName, item.count),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export const ThreadButtonsMain = ({}) => {
  const {
    state: {profile},
  } = useAuth();
  const {tab} = useParams();
  const [cur, setCur] = useState(tab);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({top: 0, left: 0});
  const menuContainerRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (tab) setCur(tab);
    if (!tab) setCur('thread-detail');
  }, [tab]);

  useEffect(() => {
    if (isMenuOpen && menuContainerRef.current && menuRef.current) {
      const containerRect = menuContainerRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let top = containerRect.bottom;
      let left = containerRect.left;

      if (left + menuRect.width > viewportWidth) {
        left = viewportWidth - menuRect.width - 10;
      }

      if (top + menuRect.height > viewportHeight) {
        top = containerRect.top - menuRect.height;
      }

      setMenuPosition({top, left});
    }
  }, [isMenuOpen]);

  const renderButton = (icon, title, indexName, count) => (
    <button
      key={title}
      className={`sidebar-action-button ${cur === indexName ? 'active' : ''}`}
      onClick={() => {
        if (indexName === 'settings') {
          navigate('/settings/profile');
        } else if (indexName === 'thread') {
          navigate('/threads/create');
        } else {
          navigate(`/threads`, {
            state: {tab: indexName, timestamp: dateToTimestamp()},
          });
        }
        setIsMenuOpen(false);
      }}
      aria-label={title}>
      <div className="button-content">
        {icon}

        <span className="button-title">
          <span className={`${indexName === 'thread' ? '' : ''}`}>{title}</span>
        </span>

        {count > 0 && <span className="notification-count">{count}</span>}
      </div>
    </button>
  );

  const menuItems = [
    {
      icon: <RxHome />,
      title: 'Home',
      indexName: 'home',
      count: 0,
    },
    {
      icon: <RxPlusCircled />,
      title: 'New Workspace',
      indexName: 'thread',
      count: 0,
    },

    {
      icon: <RxGear />,
      title: 'Settings',
      indexName: 'settings',
      count: 0,
    },
  ];

  return (
    <div className="sidebar-actions">
      <div
        className="menu-container"
        ref={menuContainerRef}
        onClick={() => setIsMenuOpen(!isMenuOpen)}

        // onMouseLeave={() => setIsMenuOpen(false)}
      >
        <ProfileImage
          data={profile?.profile_image}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          style={{height: '28px', width: '28px'}}
        />

        {isMenuOpen && (
          <div
            className="grid-submenu"
            ref={menuRef}
            style={{
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              position: 'fixed',
            }}>
            {menuItems.map(item =>
              renderButton(item.icon, item.title, item.indexName, item.count),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
